.need-help-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 18px;
  padding-top: 25px;
  padding-bottom: 15px;
  background: white;
  color: black;
}
.need-help-linear-line {
  width: 100%;
  border-top: 2px solid var(--border-color);
  border-bottom: 2px solid var(--border-color);
  padding: 10px 0;
}
.need-help-linear-line-span {
  margin-left: 12px;
}
.need-help-chat-now {
  color: var(--color-red);
  cursor: pointer;
}
.need-help-email {
  color: var(--color-red);
  cursor: pointer;
}
.need-help-faq-container {
  margin-left: 12px;
}
.css-ahj2mt-MuiTypography-root {
  font-family: Space Grotesk, sans-serif !important;
}
.accordion-width {
  width: 40%;
}

@media screen and (min-width: 501px) and (max-width: 900px) {
  .accordion-width {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .need-help-container {
    display: none;
  }
}
