.nearby-store-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  border-radius: 10px;
}
#nearby-store-cross-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.nearby-store-top-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.nearby-store-heading {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 700;
}
.nearby-store-input-container {
  width: 100%;
  height: auto;
}
.nearby-store-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  padding-bottom: 6px;
}
.nearby-store-form-label {
  font-size: 13px;
  font-weight: 600;
  margin: 8px 0 0 0;
  padding: 0;
}
.nearby-store-input-btn-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
  margin: 5px 0 0 0;
}
#nearby-store-form-input {
  width: 240px;
  padding: 2px;
  outline: none;
  border: none;
}

.nearby-store-btn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.nearby-store-btn svg {
  font-size: 15px;
}
.nearby-store-current-location {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  font-size: 12px;
  color: var(--color-peach);
  cursor: pointer;
}
.nearby-store-current-location svg {
  font-size: 14px;
  margin-right: 3px;
}

.nearby-store-bottom-container {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.nearby-store-all-store-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow-y: scroll;
}
.nearby-store-each-store {
  width: 310px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
.nearby-store-each-store:nth-child(3n) {
  margin-right: 0;
}
.nearby-store-website {
  height: 25px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.9);
}
.nearby-store-address {
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}
.nearby-store-contact {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nearby-store-phone {
  font-size: 11px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
}
.nearby-store-phone svg {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 3px;
}
#nearby-store-contact-divider {
  width: 15px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  transform: rotate(90deg);
  margin: 0;
  padding: 0;
}
.nearby-store-direction {
  height: 25px;
  font-size: 11px;
  font-weight: 600;
  color: var(--color-peach);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nearby-store-direction svg {
  color: var(--color-peach);
  font-size: 16px;
  margin-right: 4px;
  border: 1px solid var(--color-peach);
  border-radius: 50%;
  padding: 2px;
}
.nearby-store-no-dealer-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nearby-store-no-dealer-img {
  width: 100px;
  height: auto;
}
.nearby-store-no-dealers {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 10px;
}

.nearby-store-total-count {
  font-size: 15px;
  font-weight: 700;
    letter-spacing: 0.5px;
}

@media screen and (min-width: 501px) and (max-width: 1100px) {
  .nearby-store-container {
    padding: 15px;
  }
  #nearby-store-cross-btn {
    top: 15px;
    right: 15px;
    font-size: 15px;
  }

  .nearby-store-input-container {
    width: 100%;
    height: auto;
  }
  .nearby-store-form-container {
    margin-top: 10px;
    padding-bottom: 5px;
  }
  .nearby-store-form-label {
    font-size: 12px;
  }
  #nearby-store-form-input {
    width: 220px;
  }

  .nearby-store-btn svg {
    font-size: 14px;
  }

  .nearby-store-current-location svg {
    font-size: 14px;
    margin-right: 3px;
  }

  .nearby-store-bottom-container {
    margin-top: 25px;
  }
  .nearby-store-all-store-container {
    margin-top: 20px;
  }
  .nearby-store-each-store {
    width: 325px;
    height: 120px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px;
  }
  .nearby-store-each-store:nth-child(3n) {
    margin-right: 0;
  }
  .nearby-store-website {
    height: 15px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .nearby-store-address {
    height: 25px;
    font-size: 10px;
  }
  .nearby-store-contact {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .nearby-store-phone {
    font-size: 10px;
  }
  .nearby-store-phone svg {
    font-size: 14px;
  }
  #nearby-store-contact-divider {
    width: 15px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    transform: rotate(90deg);
    margin: 0;
    padding: 0;
  }
  .nearby-store-direction {
    height: 25px;
    font-size: 11px;
    font-weight: 600;
    color: var(--color-peach);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .nearby-store-total-count {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .nearby-store-direction svg {
    color: var(--color-peach);
    font-size: 15px;
    margin-right: 4px;
    border: 1px solid var(--color-peach);
    border-radius: 50%;
    padding: 2px;
  }
  .nearby-store-no-dealer-container {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .nearby-store-no-dealer-img {
    width: 100px;
    height: auto;
  }
  .nearby-store-no-dealers {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .nearby-store-container {
    padding: 15px;
    border-radius: 0;
  }
  #nearby-store-cross-btn {
    top: 15px;
    right: 15px;
    font-size: 16px;
  }
  .nearby-store-form-label {
    font-size: 14px;
    font-weight: 600;
    margin: 8px 0 0 0;
    padding: 0;
  }
  .nearby-store-input-btn-container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    margin: 5px 0 0 0;
    position: relative;
  }
  #nearby-store-form-input {
    width: 140px;
    padding: 2px;
    outline: none;
    border: none;
    font-size: 16px;
  }

  .nearby-store-btn {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  .nearby-store-btn svg {
    font-size: 15px;
  }
  .nearby-store-current-location {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    font-size: 12px;
    color: var(--color-peach);
    cursor: pointer;
  }
  .nearby-store-current-location svg {
    font-size: 14px;
    margin-right: 3px;
  }

  .nearby-store-bottom-container {
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .nearby-store-all-store-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    overflow-y: scroll;
  }
  .nearby-store-each-store {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    margin-right: 0;
    margin-bottom: 15px;
    padding: 10px;
  }
  .nearby-store-each-store:nth-child(3n) {
    margin-right: 0;
  }
  .nearby-store-website {
    height: 25px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .nearby-store-address {
    height: 35px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  .nearby-store-contact {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .nearby-store-phone {
    font-size: 12px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
  }
  .nearby-store-phone svg {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    margin-right: 3px;
  }
  #nearby-store-contact-divider {
    width: 15px;
    border: 1px solid rgba(0, 0, 0, 0.8);
    transform: rotate(90deg);
    margin: 0;
    padding: 0;
  }
  .nearby-store-direction {
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-peach);
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .nearby-store-direction svg {
    color: var(--color-peach);
    font-size: 22px;
    margin-right: 4px;
    border: 1px solid var(--color-peach);
    border-radius: 50%;
    padding: 2px;
  }
  .nearby-store-no-dealer-container {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .nearby-store-no-dealer-img {
    width: 150px;
    height: auto;
  }
  .nearby-store-no-dealers {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 10px;
  }

  .nearby-store-total-count {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
