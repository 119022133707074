.cratoni-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 10px;
    margin-top: 10px;
  }
  
  .history,
  .product-development,
  .distribution {
    margin-top: 10px;
  }
  
  .cratoni-container h2 {
    color: rgba(0,0,0,0.8);
    font-size: 16px;
    margin: 0;
  }
  
  .cratoni-container h3 {
    color: #555;
    font-size: 16px;
    margin: 0;
  }
  
  .cratoni-container p {
    color: #777;
    line-height: 1.5;
    font-size: 13.8px;
    margin: 10px 0;
    margin-left: 8px;
  }
  
  