.mobile-details-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
}
.mobile-details-breadcrum-container {
  width: 100%;
  height: auto;
}
.mobile-details-img-slider-container {
  width: 100%;
  height: auto;
}
.mobile-details-product-basic-detail-container {
  width: 100%;
  height: auto;
}
.mobile-details-product-price-container {
  width: 100%;
  height: auto;
}
.mobile-details-product-advantage-container {
  width: 100%;
  height: auto;
}
.mobile-details-product-varient-selection-container {
  width: 100%;
  height: auto;
}
.mobile-details-product-price-break-down-container {
  width: 100%;
  height: auto;
}
.mobile-details-promises-container {
  width: 100%;
  height: auto;
}
.mobile-details-product-explore-tab-container {
  width: 100%;
  height: auto;
}
.mobile-details-about-brands-container {
  width: 100%;
  height: auto;
}
