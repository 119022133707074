.mobile-desc-breadcrum-container{
    width: 100vw;
    height: auto;
}
.mobile-custom-dropdown {
    width: 100%;
    padding: 0.3vw 1.1vw 0.55vw 1.6vw;
    background: white;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
  }
  .mobile-custom-dropdown svg {
    color: #595757;
  }
  
  .mobile-custom-dropdown a {
    text-transform: none;
    color: rgba(0,0,0,1);
    margin: 0 0.3vw;
    transition: 0.3s;
    font-weight: 600;
    font-size: 0.85vw;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
  }
  
  .mobile-total-product-length{
    font-size: 0.85vw;
    color: rgba(0,0,0,0.8);
    font-weight: 500;
  }


  
  @media screen and (max-width: 500px) {
    .mobile-custom-dropdown {
      /* display: none; */
      padding: 1vh 0;
      padding-top: 1vh;
      padding-left: 4vw;
      overflow-x: scroll;
    }
    .mobile-custom-dropdown svg {
      font-size: 4vw;
    }
  
    .mobile-custom-dropdown a {
      font-size: 2.75vw;
      margin: 0px;
      font-weight: 600;
    }
  
    .mobile-product-name-underline {
        text-transform: none;
    padding-bottom: 0px;
    font-size: 0.85vw;
    border-bottom: 1px solid var(--color-blue);
      font-size: 3vw;
    }
  }
  