.rectangle {
  position: absolute;
  bottom: -70px;
  right: 2vw;
  background-color: #ffffff;
  height: 55px;
  width: 180px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  box-shadow: 1.5px 1.5px 5px 1px rgba(0, 0, 0, 0.1);
}

.rectangle .frame {
  width: 100%;
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.rectangle .social {
  height: 40px;
  position: relative;
  width: 40px;
  cursor: pointer;
}

.rectangle .nimbus-whatsapp-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #43d662;
  border-radius: 26px;
  height: 40px;
  position: relative;
  width: 40px;
  display: flex;
}

.rectangle .img {
  height: 22px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 22px;
}

.rectangle .bxl-telegram-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #0088cc;
  border-radius: 26px;
  height: 40px;
  position: relative;
  width: 40px;
}

.rectangle .fluent-mail-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e3aa03;
  border-radius: 26px;
  height: 40px;
  position: relative;
  width: 40px;
}
.rectangle .fluent-copy-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #686668;
  border-radius: 26px;
  height: 40px;
  position: relative;
  width: 40px;
}

@media screen and (max-width: 500px) {
  .rectangle {
    right: 0;
    left: 0;
    width: 150px;
    height: 45px;
  }

  .rectangle .social {
    width: 32px;
    height: 32px;
  }

  .rectangle .nimbus-whatsapp-wrapper {
    width: 32px;
    height: 32px;
  }

  .rectangle .img {
    width: 20px;
    height: 20px;
    left: 5px;
    top: 5px;
  }

  .rectangle .bxl-telegram-wrapper {
    width: 32px;
    height: 32px;
  }

  .rectangle .fluent-mail-wrapper {
    width: 32px;
    height: 32px;
  }
  .rectangle .fluent-copy-wrapper {
    width: 32px;
    height: 32px;
  }
}
