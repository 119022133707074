.products-type-select-container {
  width: 43vw;
  height: auto;
  background: #f5f8fe;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
}
.products-select-tabs-container {
  width: 36%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5vh;
  background: white;
  border-radius: 25px;
  padding: 6px;
}
.products-select-each-tabs {
  font-size: 12px;
  color: var(--color-blue);
  font-weight: 600;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 25px;
  cursor: pointer;
  white-space: nowrap;
}

.products-selected-container {
  width: auto;
  height: auto;
  padding: 0 10px;
}

.products-variant-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.products-size-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
}
.products-size-heading {
  font-size: 15px;
  font-weight: 700;
  color: var(--color-blue);
}
.products-size-select-container {
  width: 100%;
  margin: 0 10px;
  padding: 0.5vh 0.55vw;
  outline: none;
  background: white;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  border: 2px solid #595757;
  outline: none;
  margin-top: 10px;
  font-family: "Raleway", sans-serif;
}
.products-size-select-container option {
  padding: 5px 6px;
  font-weight: 500;
  font-size: 13px;
  font-family: "Raleway", sans-serif;
}
.products-color-container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.products-color-heading {
  font-size: 15px;
  font-weight: 700;
  color: var(--color-blue);
  margin-bottom: 15px;
}
.products-color-list-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.products-color-list-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1vw;
}
.products-color-name {
  font-size: 10px;
  color: var(--color-blue);
  font-weight: 500;
  margin-top: 0.4vh;
}
.color-name-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;
}

.products-selected-variant-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.products-selected-size-container {
  max-width: 95%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-right: 6px;
}
.products-selected-size-container svg {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.products-selected-size {
  font-size: 14px;
  margin-right: 0.4vw;
  color: rgba(0, 0, 0, 0.8);
}

.products-select-tabs {
  background: var(--color-blue);
  color: white;
}
.products-select-tabs-price {
  background: var(--color-green);
  color: white;
}

.product-estimated-date-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-estimated-date-container svg {
  font-size: 16px;
  color: var(--color-green);
  margin-right: 0.3vw;
}
.estimated-data-span {
  font-size: 12px;
  font-weight: 600;
  color: var(--color-green);
}
.products-carts-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
}
.products-carts-instock-container {
  width: auto;
}
.products-carts-quantity-price-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.products-totals-price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 10vw;
  font-size: 18px;
  color: black;
  font-weight: 700;
  white-space: nowrap;
}

.products-carts-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-cart {
  background: var(--color-green);
  color: #eee;
  width: 45%;
  height: auto;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 8px 10px;
  cursor: pointer;
  /* box-shadow: 1px 1px 8px 0px rgb(100, 100, 100); */
}
.add-cart:hover {
  background: #249841;
  /* box-shadow: 1px 1px 8px 0px rgb(100, 100, 100); */
}

.buy-now-btn {
  background: var(--color-blue);
  color: #eee;
  width: 45%;
  height: auto;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 8px 10px;
  opacity: 0.96;
  cursor: pointer;
  /* box-shadow: 1px 1px 8px 0px rgb(100, 100, 100); */
}
.buy-now-btn:hover {
  background: var(--color-blue);
  /* box-shadow: 1px 1px 8px 0px rgb(100, 100, 100); */
}

.product-carts-counter-div {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  /* margin: auto; */
  text-transform: none;
}

.product-carts-counter-div p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 0;
}
.product-counter-invertory {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.product-carts-counter-div .product-counter-invertory .product-counter {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 5px 5px 0 5px;
  margin-left: 10px;
  align-items: center;
}

.product-carts-counter-div .product-counter input {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 50px;
  outline: none;
  height: auto;
  /* border: 2px solid darkgray; */
  border: none;
  text-align: center;
  /* border-radius: 50px; */
  padding: 2px 0;
  font-size: 1.2rem;
  margin: 0 0.5vw;
}

.pdp-show-message-for-variant-part{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-peach);
  font-size: 12.5px;
  margin-left: 10px;
  white-space: nowrap;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .products-select-tabs-container {
    width: 180px;
  }

  .products-size-select-container {
    font-size: 10px;
  }

  .products-selected-size {
    font-size: 12px;
  }
  .pdp-show-message-for-variant-part{
    font-size: 6px;
    margin-left: 2px;
    display: none;
  }
  
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .products-totals-price {
    font-size: 15px;
    white-space: inherit;
    text-align: end;
  }
  .pdp-show-message-for-variant-part{
    font-size: 6px;
    margin-left: 2px;
    display: none;
  }
}
