.mobile-productPrice-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mobile-productPrice-left-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-productPrice-mrp-price-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family:Space Grotesk, sans-serif;
}
.mobile-productPrice-mrp-price-heading {
  width: 12.5vw;
  font-size: 3.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  margin-top: 0.5vh;
}
.mobile-productPrice-mrp-price-main-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 1vw;
}
.mobile-productPrice-offer-price-span {
  font-size: 3.8vw;
  color: var(--color-green);
  margin-left: 1vw;
  
}

.mobile-productPrice-mrp-price-span {
  font-size: 4vw;
  color: var(--color-blue);
  font-weight: 700;
}
.mobile-productPrice-mrp-price-inclusive {
  font-size: 2.8vw;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  margin-top: 0.4vh;
}
.mobile-productPrice-ndp-price-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5vh;
  font-family:Space Grotesk, sans-serif;
}
.mobile-productPrice-ndp-price-heading {
  width: auto;
  font-size: 4vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
.mobile-productPrice-ndp-price-span {
  font-size: 3.5vw;
  color: black;
  font-weight: 600;
  margin-left: 2vw;
}
.mobile-productPrice-divider-container {
  width: auto;
  height: auto;
}
.mobile-productPrice-divider-img {
  width: 8vw;
  height: auto;
  margin-left: -1vw;
}
.mobile-productPrice-right-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1vw;
}
.mobile-productPrice-right-margin-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-green);
  font-size: 3vw;
}
.mobile-productPrice-right-margin-logo {
  width: 4vw;
  height: 4vw;
  margin-right: 0.5vw;
}
.mobile-productPrice-right-margin-span {
  font-size: 3vw;
  color: var(--color-green);
  font-weight: 700;
  font-family:Space Grotesk, sans-serif;
}
.mobile-productPrice-right-offer-span {
  font-size: 3.2vw;
  color: var(--color-green);
  font-weight: 700;
  margin-left: 1vw;
  
}
.mobile-product-price-login-to-see-price {
  background: var(--color-green);
  padding: 0.5vh 1.2vw;
  border-radius: 3px;
  color: white;
  font-size: 3vw;
  letter-spacing: 0.25px;
  margin-bottom: 0.5vh;
  cursor: pointer;
}
.mobile-product-price-not-visible-container svg {
  font-size: 4vw;
  cursor: pointer;
  margin-top: 0.6vh;
  margin-right: 0.3vw;
}
