.basic-detail-main-container {
  width: 100%;
  height: 100%;
  color: black;
}

.detail-page-name {
  font-size: 1.15rem;
  font-weight: 700;
  margin: 0 0 1vh 0;
}
.business-address-heading {
  font-size: 1.15rem;
  font-weight: 700;
  margin: 0 0 1vh 0;
}
.save-address-as-heading {
  font-size: 1.15rem;
  font-weight: 700;
  margin: 0 0 1vh 0;
}
.verify-syl {
  color: var(--color-peach);
  margin-right: 0.1vw;
}
.pincode-not-available {
  font-size: 0.6vw !important;
  color: var(--color-peach);
  margin-left: 2vw;
  border: none !important;
}
.detail-page-text {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
}

.basic-detail-form-container {
  width: auto;
  height: auto;
}

form {
  width: 100%;
  padding-bottom: 20px;
  position: relative;
}
.basic-details-form-container-main-half-div {
  width: 100%;
  position: relative;
  padding: 2vw;
}

.verification-sign-up-main-container {
  width: 100%;
  height: 220px;
}
.basic-detail-phone-email-verfication {
  width: 100%;
  height: 200px;
  border-radius: 7px;
  /* margin-bottom: 25px; */
  padding: 0;
}
.basic-detial-phone-em-verification-main-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.basic-detail-phone-email-verfication legend {
  font-size: 16px;
  color: var(--color-blue);
  font-weight: 600;
  margin-left: 10px;
}
.basic-detail-phone-email-verfication span {
  font-size: 0.8rem;
}

.basic-detail-phone-email-verfication-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}
.basic-detail-phone-email-verfication-main-div span {
  padding: 10px;
  font-size: 1rem;
}
.basic-detail-phone-email-verification-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 15%;
}
.basic-detail-phone-email-verification-btn a {
  width: 140px;
  padding: 8px 15px;
  background: #9e3dcc;
  color: white;
  border-radius: 15px;
  font-size: 0.9rem;
  text-align: center;
}
.basic-detail-verification-phone-email-verified {
  background: rgba(58, 153, 120, 0.15);
  width: 100%;
  height: calc(100% + 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  top: -10px;
}
.basic-detail-verification-phone-email-verified svg {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  color: var(--color-green);
}
/* DETAIL FORM 2*/
.basic-detail-basic-detail-form-2 {
  width: 60%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.type-radio-each-btn {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}
.type-radio-span {
  margin-left: 0.5vw;
}
.basic-detail-basic-detail-form-3 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.save-address-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.save-detail-default-address-div {
  width: auto;
  display: flex;
  align-items: center;
  margin: 0.5vh 0;
}

/* .basic-detail-basic-detail-form-2 fieldset {
  width: calc(50% - 20px); 
  border-radius: 7px;
  padding: 0px 10px;
  margin-bottom: 25px;
  position: relative;
} */
/* 
.basic-detail-basic-detail-form-2 fieldset legend {
  font-size: 16px;
  color: var(--color-blue);
  font-weight: 600;
  margin-left: 10px;
} */

/* .basic-detail-basic-detail-form-2 fieldset input {
  width: 100%; 
  height: 20px; 
  border: none;
  font-weight: 600;
  outline: none;
  color: black;
  margin-top: 5px; 
} */
/* .basic-detail-basic-detail-form-2 fieldset input::placeholder {
  color: darkgray;
  font-size: 13px;
  font-weight: 500;
} */
.basic-detail-form-2-gender {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.basic-detail-form-2-gender fieldset {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  padding-right: 50px;
  padding-top: 10px;
}

.basic-detail-form-2-gender fieldset input {
  border: 1px solid black;
  width: 18px;
  height: 18px;
  margin-left: 0;
  cursor: pointer;
  margin-right: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}
.password-instruction-sign-up {
  width: 100%;
  position: relative;
}

.basic-detail-form-instruction {
  width: 300px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  left: 50%;
  bottom: 45%;
}
.basic-detail-form-instruction svg {
  font-size: 1.2rem;
  color: #9e3dcc;
}
.basic-detail-form-instruction span {
  font-size: 0.8rem;
  margin-left: 5px;
  color: #9e3dcc;
  font-weight: 500;
}
.basic-details-save-continue-btn-container {
  width: 100%;
  position: relative;
  margin-top: 40px;
}
.basic-details-save-continue-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 150px;
  padding: 8px 15px;
  background: var(--green-color);
  color: white;
  border-radius: 15px;
  font-size: 0.9rem;
  text-align: center;
  margin: 0 auto;
}

.make-address-default-container {
  margin: 2vh 0;
}
.make-address-default-heading {
  font-size: 1vw;
  color: var(--color-blue);
  font-weight: 600;
}
.make-detail-default-address-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1vh 0;
}
.basic-details-save-cancel-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-details-cancel-btn {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 0.5vh 0.8vw;
  margin: 0 1vw;
}
.basic-details-save-btn {
  background: var(--color-green);
  color: white;
  padding: 0.5vh 1.2vw;
  border-radius: 25px;
  margin: 0 1vw;
}
.basic-detail-photo-upload-container {
  width: 16vw;
  height: 16vw;
  border-radius: 10px;
  box-shadow: 1px 1px 18px 1px rgb(168, 167, 167);
  padding: 1vw;
  position: absolute;
  top: 8vh;
  right: 3vw;
  display: none;
}
.upload-browse {
  border-radius: 25px;
  font-size: 0.95vw;
  padding: 0.8vh 1vw;
  color: white;
  background: var(--color-blue);
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
}
.basic-detail-photo-upload-bordered-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px dashed var(--color-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1vw;
}
.basic-detail-photo-upload-bordered-container p {
  color: var(--color-blue);
  font-size: 1.1vw;
  margin: 0;
  cursor: pointer;
}
.basic-detail-photo-upload-bordered-container a {
  width: 100px;
  padding: 0.5vh 1vw;
  background: #003765;
  color: white;
  border-radius: 25px;
  font-size: 1vw;
  text-align: center;
  margin: 0 auto;
}

/* UPLOAD IMAGE FIX*/
.uploaded-image-fix {
  width: 100%;
  height: 80%;
  border-radius: 20px;
}
.uploaded-image-inspect-remove-container {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded-image-inspect-div-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a9978;
}
.uploaded-image-inspect-div-container span {
  margin-left: 10px;
  cursor: pointer;
}
.uploaded-image-inspect-div-container img {
  cursor: pointer;
}
.uploaded-image-remove-div-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bd001c;
}
.uploaded-image-remove-div-container span {
  margin-left: 10px;
  cursor: pointer;
}
.uploaded-image-remove-div-container img {
  cursor: pointer;
}

/* IMAGE INSPECT ZOOM COMPONENT*/
.user-uploaded-image-zoom-inspect-slider-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.user-uploaded-image-zoom-img-container {
  width: 100%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #a5a5a5;
}
.user-uploaded-image-zoom-img-container img {
  width: 170px;
  height: 100%;
  border-radius: 150px;
}
.user-upload-image-zoom-img-slider-container {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}
.user-upload-image-zoom-img-slider-container input {
  width: 90%;
  accent-color: #003765;
}

.user-uploaded-image-zoom-img-container img {
  transition: transform 0.5s ease-in-out;
}
.user-uploaded-image-zoom-img-save-cancel-container {
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.zoom-img-save-btn {
  color: #3a9978;
  cursor: pointer;
}
.zoom-img-cancel-btn {
  color: #bd001c;
  cursor: pointer;
}

/* CROPEASY*/
.basic-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent gray background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the overlay is above other content */
}

.basic-details-crop-easy-main-container {
  /* Add your attractive styling for the crop container */
  width: 50%;
  background: #fff; /* White background */
  padding: 20px;
  margin-top: 60px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
/* .cropEasy-container{
  width: 500px;
  position: fixed;
  top: 10%;
  left: 20%;
  z-index: 999;
} */

.dealership-detail-form-2-please-specify-default-div {
  width: 15vw;
  display: flex;
  align-items: center;
  justify-self: flex-start;
}
.please-specify-default-input {
  width: 76%;
  height: 4vh;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  padding: 0.2vw;
  /* margin: 0.5vw 0; */
  padding-left: 0.55vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}
.dealership-detail-specify-default-btn {
  width: 22%;
  background: var(--color-green);
  border: none !important;
  color: white;
  border-radius: 6px !important;
  height: 4vh;
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.basic-detail-from-container-half-width .error-message {
  position: absolute;
  bottom: -15px;
  left: 0;
  font-size: 0.65rem;
}
.specify-error {
  border: 2px solid red;
}
.error {
  border-color: red;
}
.valid {
  border-color: rgb(82, 189, 109);
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .business-address-heading {
    width: 50vw;
    font-size: 15px;
  }
  .save-address-as-heading {
    font-size: 16px;
    margin: 0 0 10px 0;
  }

  .pincode-not-available {
    font-size: 10px !important;
    margin-left: 15px;
  }
  .detail-page-text {
    font-size: 13px;
  }

  .basic-details-form-container-main-half-div {
    padding: 15px;
  }

  .basic-detail-basic-detail-form-2 {
    width: 100%;
  }
  .basic-detail-form-2-gender {
    width: 80%;
  }

  .basic-detail-form-2-gender fieldset {
    padding-right: 0;
  }

  .make-address-default-heading {
    font-size: 14px;
  }

  .basic-details-crop-easy-main-container {
    width: 80%;
  }
  /* .cropEasy-container{
    width: 500px;
    position: fixed;
    top: 10%;
    left: 20%;
    z-index: 999;
  } */
}
@media screen and (min-width: 501px) and (max-width: 900px) {
  .dealership-detail-form-2-please-specify-default-div {
    width: 160px;
  }
  .please-specify-default-input {
    height: 35px;
    padding-left: 6px;
  }
  .dealership-detail-specify-default-btn {
    height: 35px;
    width: 50px;
  }
}
@media screen and (max-width: 500px) {
  /* CROPEASY*/
  .basic-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent gray background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure the overlay is above other content */
  }

  .basic-details-crop-easy-main-container {
    /* Add your attractive styling for the crop container */
    width: 90%;
    background: #fff; /* White background */
    padding: 20px;
    margin-top: 60px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  /* .cropEasy-container{
  width: 500px;
  position: fixed;
  top: 10%;
  left: 20%;
  z-index: 999;
} */
}
