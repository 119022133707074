.no-cart-item-container {
    width: 100%;
    height: 100%;
  }
  .no-cart-item-div {
    width: auto;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.1vw;
    /* margin-left: 40%; */
  }
  .no-cart-item-div img {
    width: 330px;
    height: auto;
    border-radius: 50%;
  }
  .no-cart-item-div h3 {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: black;
  }
  .sorry-mate {
    color: rgb(82, 81, 81);
    font-size: 14px;
    font-weight: 600;
  }
  .add-items-to-cart-btn {
    width: 200px;
    height: 40px;
    border-radius: 10px;
    color: white;
    background: var(--color-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.1vw 0;
    cursor: pointer;
  }

  @media screen and (min-width: 501px) and (max-width: 900px) {
    .no-cart-item-div img {
      width: 200px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .no-cart-item-div img {
      width: 100px;
    }
    .no-cart-item-div h3 {
      font-size: 1rem;
    }
    .sorry-mate {
      font-size: 0.85rem;
    }
    .add-items-to-cart-btn {
      width: 150px;
    }
  }
  