.mobile-insight-container {
  width: 100vw;
  padding: 1vh 3vw;
  color: var(--color-blue) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobile-insight-container h2 {
  font-size: 4.2vw;
  font-weight: 700;
  color: var(--color-blue);
  margin-bottom: 2vh;
}
.mobile-insight-product-ranking-container {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.mobile-insight-ranking-in-categories {
  font-size: 3.5vw;
  font-weight: 500;
  color: var(--color-blue);
  margin: 0.5vh 1.5vw;
  white-space: nowrap;
}

.mobile-insight-ranking-in-categories b {
  margin-left: 0.2vw;
  color: var(--color-peach);
}
.chart-container {
  margin-top: 1vh;
}
.mobile-insight-graph-container {
  width: 100vw;
  padding: 0;
  height: auto;
}
