.mobile-product-page-img {
    width: 100vw;
    /* padding: 5px; */
    overflow: hidden;
  }
  
  .mobile-product-page-img .mobile-big-images {
    width: 100%;
    height: 35vh;
    overflow: hidden;
    position: relative;
    /* border: 2px solid var(--border-color); */
  }
  
  .mobile-product-page-img .prev,
  .mobile-product-page-img .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    padding-bottom: 0.25vw;
    padding-right: 0.25vw;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--color-blue);
    user-select: none;
    transition: all ease 0.3s; /* Added transition here */
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.1rem;
    z-index: 9;
  }
  
  .mobile-product-page-img .next {
    right: 0;
  }
  
  .mobile-product-page-img .prev:hover,
  .mobile-product-page-img .next:hover {
    background: rgb(1, 47, 85);
    /* width: 3.1rem;
    height: 3.1rem;
    padding-right: 3.2px;
    padding-bottom: 3.2px; */
    zoom: 1.05;
    transition: all ease 0.3s;
  }
  
  .mobile-product-page-img .mobile-slider-img {
    width: 100vw;
    height: auto;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    margin-top: 1.5vh;
    padding: 0 3vw;
  }
  .mobile-product-page-img .mobile-slider-img::-webkit-scrollbar {
    display: none;
  }
  .mobile-product-page-img .mobile-slider-img .mobile-slider-box {
    width: 25vw;
    height: 20vw;
    cursor: pointer;
    /* overflow: hidden; */
    text-align: center;
    margin-right: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    /* border-radius: 12px; */
    padding: 0.3vh;
    opacity: 0.5;
  }
  .mobile-product-page-img .mobile-slider-img .mobile-slider-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: inline-block;
  }
  
  .mobile-product-page-img .mobile-slider-img .mobile-slider-box.active {
    border: 2px solid #003765;
    opacity: 1;
  }
  .mobile-product-page-img .mobile-slider-img .mobile-slider-box:hover {
    border: 2px solid #003765;
    opacity: 1;
  }
.mobile-mySlider-div {
    width: 100%;
    height: 100%;
    position: relative;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .mobile-mySlides {
    width: 80%;
    height: 100%;
    margin: auto;
    position: relative;
  }
  .mobile-mySlides {
    display: none;
  }
  .mobile-big-image {
    display: none;
  }
  
  .largeImageContainer {
    position: fixed !important;
    width: 50% !important;
    height: 440px !important;
    top: 22vh !important;
    left: 55% !important;
    right: 0 !important;
    /* transform: translate(-100%, -0%) !important; */
    z-index: 0 !important;
    overflow: auto !important;
    background: transparent !important;
    object-fit: contain !important;
    margin-left: 0 !important;
    z-index: 2 !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px !important;
    border: 1px solid rgb(148, 148, 148) !important;
    @media screen and (min-width: 501px) and (max-width: 800px) {
      top: 150px !important;
      height: 200px !important;
    }
  }
  
  .largeImageStyle {
    width: 100% !important;
    height: 100% !important;
    /* z-index: 1 !important; */
  }
  .largeImageImg {
    /* width: 1200px !important;
      height: 1800px !important;  */
    /* object-fit: contain !important; */
    background-size: contain;
    background-position: center;
    object-fit: contain;
    z-index: 2 !important;
  }
  .smallImageImg {
    width: 100% !important;
    height: 100% !important;
    /* object-fit: contain !important; */
    background-size: contain;
    object-fit: contain;
    background-position: center;
    /* transform: scale(1.1); */
  }
  
  @media screen and (max-width: 500px) {
    .mobile-mySlides {
      display: none;
    }
    .mobile-mySlides {
      display: flex;
      width: auto;
      height: 100%;
      object-fit: contain;
    }
    .mobile-big-image {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  
    .largeImageContainer {
      display: none;
    }
  
    .largeImageImg {
      display: none;
    }
    .product-slider-share-wishlist-container{
      position: absolute;
      top: 5vh;
      right: 5vw;
      z-index: 99;
    }
  }
  