.mobile-my-order-container {
  width: 100vw;
  min-height: 60vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 6vh;
}
.mobile-my-order-header-container {
  width: 100%;
  background: white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}
.mobile-my-order-header-left-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mobile-my-order-header-left-container svg {
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 0vw;
}
.mobile-my-order-header-left-span {
  font-size: 4vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  display: flex;
  align-items: center;
}
.mobile-my-order-header-left-span b {
  background: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 2vw;
  padding: 0.25vh 0.8vh 0.6vh 0.8vh;
  border-radius: 20px;
  font-size: 3.5vw;
}
.mobile-my-order-header-right-container {
  height: 100%;
}
.mobile-my-order-header-right-container svg {
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.8);
}
.mobile-my-order-search-container {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  background: white;
  margin: 1.5vh 0 0.5vh 0;
  padding: 0.5vh 3vw;
}
.mobile-my-order-search-container svg {
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.5);
  margin-right: 1.5vw;
}
.mobile-my-order-search-container input {
  width: 90%;
  outline: none;
  border: none;
  font-size: 5vw;
  font-weight: 600;
  letter-spacing: 0.3px;
}
.mobile-my-order-filter-sorting-container {
  width: 100%;
  padding: 1vh 3vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-my-order-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mobile-my-order-each-filter {
  font-size: 3.2vw;
  padding: 0.5vh 2vw;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 25px;
  margin-right: 2vw;
}
.mobile-my-order-sorting-container {
  width: auto;
}
.mobile-my-order-sorting-container svg {
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.8);
}
.mobile-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
}
.mobile-my-order-bottom-filter-container {
  width: 100vw;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 15px 15px 0 0;
  padding-bottom: 1vh;
  z-index: 1000;
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.1);
}
.mobile-my-order-bottom-filter-close-icon {
  position: absolute;
  top: 1.5vh;
  right: 3vw;
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.8);
}
.mobile-my-order-bottom-filter-heading {
  width: 100%;
  padding: 1.2vh 3vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.mobile-my-order-bottom-filter-heading svg {
  font-size: 6vw;
  margin-right: 2vw;
  color: rgba(0, 0, 0, 0.8);
}
.mobile-my-order-bottom-filter-heading-name {
  font-size: 4vw;
  color: rgba(0, 0, 0, 0.8);
}
.mobile-my-order-bottom-filter-main-container {
  width: 100%;
  padding: 1vh 3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-my-order-eachs-filter {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5vh 0;
}
.mobile-my-order-eachs-filter input {
  width: 2.8vh;
  height: 2.8vh;
  border: 2px solid rgba(0, 0, 0, 0.5);
  margin-right: 3vw;
}
.mobile-my-order-each-filter-name {
  font-size: 4vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
.mobile-my-order-main-order-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-my-order-each-order-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2vh;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.mobile-my-order-each-order-header-container {
  width: 100%;
  padding: 1vh 3vw;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.mobile-my-order-each-order-header-left-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-my-order-each-order-header-left-order-id-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5vh 0;
}
.mobile-my-order-each-order-header-left-order-id-key {
  font-size: 3.5vw;
  color: black;
  font-weight: 700;
}
.mobile-my-order-each-order-header-left-order-id-value {
  font-size: 3.5vw;
  margin-left: 2vw;
  color: var(--color-green);
  font-weight: 600;
  font-family: Space Grotesk, sans-serif;
}
.mobile-my-order-each-order-header-left-status-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1vh 0;
}
.mobile-my-order-each-order-header-left-status-key {
  margin: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(132, 114, 249);
  margin-right: 5px;
  box-shadow: 0 0 4px 2px rgb(157, 143, 250);
}
.mobile-my-order-each-order-header-left-status-value {
  font-size: 3.8vw;
  margin-left: 2vw;
  color: rgb(132, 114, 249);
  font-weight: 700;
}
.mobile-my-order-each-order-header-right-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.mobile-my-order-each-order-header-right-eta-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.5vh 0;
}
.mobile-my-order-each-order-header-right-eta-key {
  font-size: 3.5vw;
  font-weight: 700;
  color: black;
}
.mobile-my-order-each-order-header-right-eta-value {
  font-size: 3.5vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  margin-left: 2vw;
  font-family: Space Grotesk, sans-serif;
}
.mobile-my-order-each-order-header-right-view-order-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vh 1.5vw;
  background: var(--color-blue);
  border-radius: 25px;
  margin: 0.5vh 0;
}
.mobile-my-order-each-order-header-right-view-order-container svg {
  font-size: 4vw;
  margin-left: 1vw;
  color: white;
}
.mobile-my-order-each-order-header-right-view-order-name {
  font-size: 4vw;
  color: white;
  margin-left: 1.5vw;
}
.my-order-each-order-product-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1vh 3vw;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.my-order-each-order-product-top-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.my-order-each-order-product-left-container {
  width: 38%;
  height: 18vh;
}
.my-order-each-order-product-left-img {
  max-width: 100%;
  width: auto;
  height: 100%;
}
.my-order-each-order-product-right-container {
  width: 62%;
  height: 18vh;
  padding-left: 3vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.my-order-each-order-product-manufacture-img {
  width: auto;
  max-width: 25vw;
  height: 2.8vh;
  object-fit: contain;
}
.my-order-each-order-product-name {
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.3px;
  font-weight: 700;
}
.my-order-each-order-product-tech {
  font-size: 3vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}
.my-order-each-order-product-qty-varient-details-container {
  width: 100%;
  height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.my-order-each-order-product-qty {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.8);
}
.my-order-each-order-product-size {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.8);
}
.my-order-each-order-product-review-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2vh;
}
.my-order-each-order-prduct-review {
  width: 48%;
  height: 4vh;
  font-size: 3.5vw;
  color: white;
  background: var(--color-purple);
  border-radius: 25px;
  padding: 0.8vh 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-order-each-order-product-return {
  width: 48%;
  height: 4vh;
  font-size: 3.5vw;
  border: 2px solid rgba(0, 0, 0, 0.7);
  border-radius: 25px;
  padding: 0.7vh 2vw;
  color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.my-order-each-order-total-price-invoice-container {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8vh 3vw;
}
.my-order-each-order-total-price-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-order-each-order-total-price-key {
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
.my-order-each-order-total-price-value {
  font-size: 3.5vw;
  color: black;
  font-weight: 700;
  margin-left: 1vw;
}
.my-order-each-order-product-invoice {
  border: 2px solid rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vh 2vw;
  border-radius: 25px;
}
.my-order-each-order-product-invoice svg {
  font-size: 3.5vw;
  margin-right: 1vw;
  color: rgba(0, 0, 0, 0.7);
}
.my-order-each-order-product-invoice span {
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.7);
}

.mobile-show-more-button {
  font-size: 3vw;
  background: var(--color-blue);
  color: white;
  padding: 0.5vw 2vw;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1vh 0;
  align-self: center;
  position: relative;
}

.mobile-show-more-button::before,
.mobile-show-more-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue);
  opacity: 0.5;
  top: 50%;
}

.mobile-show-more-button::before {
  left: 110%;
}

.mobile-show-more-button::after {
  right: 110%;
}

.mobile-show-less-button {
  font-size: 3vw;
  background: var(--color-blue);
  color: white;
  padding: 0.5vh 2vw;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1vh 0;
  align-self: center;
  position: relative;
}

.mobile-show-less-button::before,
.mobile-show-less-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue);
  opacity: 0.5;
  top: 50%;
}

.mobile-show-less-button::before {
  left: 110%;
}

.mobile-show-less-button::after {
  right: 110%;
}

.mobile-hidden {
  display: none;
}
.active-order-sorting-btn {
  background: var(--color-blue);
  color: white;
}
