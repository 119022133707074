.business-pan-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2vw;
}
.business-pan-heading {
  font-size: 1.2vw;
  color: black;
  font-weight: 600;
  margin-bottom: 1.5vh;
}
.business-pan-desc {
  width: 50%;
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-bottom: 1.5vh;
}
.show-aadhar-details-after-verify {
  /* position: fixed; */
  /* top: 30%;
  left: 40%; */
  width: 20vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1vw;
  border-radius: 5px;
  background: white;
  z-index: 999;
  margin-top: 5vh;
  background: #f7f7f7;
  border: 1px solid #ddd;
}

.show-aadhar-details-after-verify svg {
  background: var(--color-green);
  color: white;
  border-radius: 50%;
  font-size: 1.1vw;
  width: 2.5vw;
  padding: 0.3vw;
  height: 2.5vw;
}
.show-aadhar-details-after-verify-heading {
  font-size: 1vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  margin: 0.5vw 0;
}

.show-aadhar-details-after-verify-main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5vw 0.5vw 0 0.5vw;
}
.show-aadhar-details-after-verify-each-attribute {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0.3vw 0;
}
.show-aadhar-details-img-verify {
  position: absolute;
  width: 3.5vw;
  height: auto;
  top: 1vw;
  right: 1.5vw;
}
.show-aadhar-details-after-verify-each-attribute-key {
  width: 35%;
  text-align: start;
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 800;
}
.show-aadhar-details-after-verify-each-attribute-value {
  width: 65%;
  text-align: end;
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.65);
}

.show-aadhar-details-after-verify-close-btn {
  background: var(--color-purple);
  color: white;
  border-radius: 25px;
  padding: 0.3vw 1.2vw;
  font-size: 0.7vw;
  margin-top: 0.4vw;
  cursor: pointer;
}

.error-message {
  position: relative;
  bottom: 5px;
  color: black;
  left: 0;
  font-size: 0.65rem;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .business-pan-container {
    padding: 15px;
  }
  .business-pan-heading {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .business-pan-desc {
    width: 80%;
    font-size: 13px;
    margin-bottom: 15px;
  }
  .show-aadhar-details-after-verify {
    width: 50vw;
    padding: 10px;
    margin-top: 50px;
  }

  .show-aadhar-details-after-verify svg {
    font-size: 15px;
    padding: 4px;
    height: 25px;
    width: 25px;
  }
  .show-aadhar-details-after-verify-heading {
    font-size: 15px;
  }

  .show-aadhar-details-after-verify-main-container {
    padding: 6px 6px 0 6px;
  }
  .show-aadhar-details-after-verify-each-attribute {
    margin: 4px 0;
  }
  .show-aadhar-details-img-verify {
    width: 40px;
    top: 10px;
    right: 15px;
  }
  .show-aadhar-details-after-verify-each-attribute-key {
    font-size: 13px;
  }
  .show-aadhar-details-after-verify-each-attribute-value {
    font-size: 13px;
  }
}
