.infoFilter-detail-main-container{
    width: 420px;
    height: 100px;
    position: absolute;
    left: 180px;
    bottom: 10px;
    right: 0;
    z-index: 99999;
    color: black;
    text-transform: none;
}
.infoFilter-detail-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 5px 0px;
    background:white;
    width: 400px;
    height: 120px;
    font-size: 0.8rem;
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    box-shadow: 0px 0px 18px 2px rgb(159, 158, 158);
}
.standard-shipping-infoFilter-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border-right: 2px solid #800080;
}
.standard-shipping-infoFilter-container p{
    font-size: 0.8rem;
    padding-left:33px;
    color: rgb(47, 46, 46);
}
.standard-shipping-infoFilter-container .infoFilter-standard-name{
    display: flex;
    align-items: center;
    color: #800080;
    font-size: 0.95rem;
}
.standard-shipping-infoFilter-container .infoFilter-standard-name img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.standard-shipping-infoFilter-container .infoFilter-standard-heading{
    font-weight: bold;
    font-size: 0.9rem;
    margin-left: 20px;
}
.express-shipping-infoFilter-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
}
.express-shipping-infoFilter-container p{
    font-size: 0.8rem;
    padding-left:40px;
    color: rgb(47, 46, 46);
}
.express-shipping-infoFilter-container .infoFilter-express-name{
    display: flex;
    align-items: center;
    color: #800080;
    font-size: 0.95rem;
}
.express-shipping-infoFilter-container .infoFilter-express-name img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.express-shipping-infoFilter-container .infoFilter-express-heading{
    font-weight: bold;
    font-size: 0.9rem;
    margin-left: 15px;
}

.infoFilter-shipping-detail-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 0px 0px;
    border-color: white transparent transparent transparent;
  }
  