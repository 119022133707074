.my-order-page-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 20px 0;
}
.my-order-total-order {
  font-size: 18px;
  color: black;
  display: flex;
  align-items: center;
}
.my-order-total-order b {
  background: rgb(230, 228, 228);
  border-radius: 5px;
  padding: 3px 6px;
  margin-left: 0.6vw;
  font-family: sans-serif;
  font-size: 15px;
}
.my-order-page-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my-order-page-left-header-div {
  width: auto;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-order-page-delivery {
  font-size: 12px;
  padding: 5px 10px;
  border: 2px solid var(--color-blue);
  border-radius: 25px;
  margin-right: 15px;
  cursor: pointer;
  color: black;
}
.my-order-page-processing {
  font-size: 12px;
  padding: 5px 10px;
  border: 2px solid var(--color-blue);
  border-radius: 25px;
  margin-right: 15px;
  cursor: pointer;
  color: black;
}
.my-order-page-cancelled {
  font-size: 12px;
  padding: 5px 10px;
  border: 2px solid var(--color-blue);
  border-radius: 25px;
  margin-right: 15px;
  cursor: pointer;
  color: black;
}
.my-order-page-right-header-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-order-page-search-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  padding: 2px 10px;
}
.my-order-page-search-div input {
  outline: none;
  border: none;
  color: black;
}
.my-order-page-search-div svg {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.my-order-page-monthly-order-div {
}
.my-order-page-monthly-order-div select {
  background: rgb(230, 228, 228);
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  color: rgb(75, 75, 75);
  cursor: pointer;
}
.my-order-page-monthly-order-div select option {
  background: white;
  padding: 10px;
  cursor: pointer;
}
.my-order-page-main-order-by-date-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin: 20px 0;
}
.my-order-page-date-order-header-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(230, 228, 228);
  border-radius: 15px 15px 0 0;
  padding: 10px 20px;
}
.my-order-page-date-order-header-left-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-order-page-date-header-left-date-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  margin-right: 20px;
}
.left-date-heading {
  font-size: 0.95rem;
  font-weight: 700;
}
.left-date-value {
  font-size: 0.88rem;
  font-weight: 500;
  padding: 5px 0;
}
.my-order-page-date-header-left-status-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  margin-right: 20px;
}
.left-status-heading {
  font-size: 0.95rem;
  font-weight: 700;
}
.left-status-value-processing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.88rem;
  font-weight: 500;
  margin: 5px 0;
  color: rgb(132, 114, 249);
}
.left-status-value-processing p {
  margin: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgb(132, 114, 249);
  margin-right: 5px;
  box-shadow: 0 0 4px 2px rgb(157, 143, 250);
}

.left-status-value-delivered {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.88rem;
  font-weight: 500;
  margin: 5px 0;
  color: var(--color-green);
}
.left-status-value-delivered p {
  margin: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-green);
  margin-right: 5px;
  box-shadow: 0 0 4px 2px var(--color-green);
}
.left-status-value-cancelled {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.88rem;
  font-weight: 500;
  margin: 5px 0;
  color: var(--color-peach);
}
.left-status-value-cancelled p {
  margin: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-peach);
  margin-right: 5px;
  box-shadow: 0 0 4px 2px var(--color-peach);
}

.my-order-page-date-header-left-total-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  margin-right: 20px;
}
.left-total-heading {
  font-size: 0.95rem;
  font-weight: 700;
}
.left-total-value {
  font-size: 0.88rem;
  font-weight: 500;
  padding: 5px 0;
}

.my-order-page-date-header-left-orderID-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
  margin-right: 20px;
}
.left-orderID-heading {
  font-size: 0.95rem;
  font-weight: 700;
}
.left-orderID-value {
  font-size: 0.88rem;
  font-weight: 500;
  padding: 5px 0;
  color: var(--color-green);
}

.my-order-page-date-order-header-right-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.my-order-page-date-order-header-order-again {
  font-size: 0.88rem;
  background: var(--color-blue);
  color: white;
  font-weight: 700;
  padding: 8px 12px;
  border-radius: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.my-order-page-date-order-header-view-order {
  font-size: 0.88rem;
  color: var(--color-blue);
  border-radius: 25px;
  border: 2px solid var(--color-blue);
  background: white;
  padding: 6px 10px;
  margin-right: 15px;
  cursor: pointer;
}
.my-order-page-date-order-each-order-div {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
}
.my-order-page-date-order-each-order-left-div {
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.my-order-page-date-order-each-order-left-img {
  width: 100px;
  height: 120px;
  cursor: pointer;
}
.my-order-page-date-order-each-order-left-detail-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
}
.my-order-page-date-order-each-order-left-detail-brands-logo {
  width: 80px;
  /* max-width: 5vw; */
  height: auto;
  background-position: center;
  background-size: contain;
}
.my-order-page-date-order-each-order-left-product-name {
  font-size: 0.88rem;
  font-weight: 600;
  width: 250px;
  color: black;
}
.my-order-page-date-order-each-order-left-product-tech {
  font-size: 0.75rem;
  font-weight: 500;
  width: 250px;
  margin-top: 5px;
  color: black;
}
.my-order-page-date-order-each-order-left-product-variant-qty-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-20%);
}
.my-order-page-date-order-each-order-left-product-qty {
  font-size: 0.88rem;
  font-weight: 500;
  margin: 3px 0;
  color: black;
}
.my-order-page-date-order-each-order-left-product-qty b {
  font-size: 0.95rem;
  font-weight: 700;
  color: black;
}
.my-order-page-date-order-each-order-left-product-variant-detail {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.my-order-page-date-order-each-order-left-product-variant-size-div {
  font-size: 0.88rem;
  font-weight: 500;
  margin: 3px 0;
  color: black;
}
.my-order-page-date-order-each-order-left-product-variant-size-div b {
  font-size: 0.95rem;
  font-weight: 700;
}
.my-order-page-date-order-each-order-left-product-variant-color-div {
  font-size: 0.88rem;
  font-weight: 500;
  margin: 3px 0;
  color: black;
}
.my-order-page-date-order-each-order-left-product-variant-color-div b {
  font-size: 0.95rem;
  font-weight: 700;
}
.my-order-page-date-order-each-order-left-product-variant-variant-div {
  font-size: 0.88rem;
  font-weight: 500;
  margin: 3px 0;
  color: black;
}
.my-order-page-date-order-each-order-left-product-variant-variant-div b {
  font-size: 0.95rem;
  font-weight: 700;
}
.my-order-page-date-order-each-order-right-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}
.my-order-page-date-order-each-order-right-price {
  font-size: 0.88rem;
  font-weight: 600;
  margin: 5px 0;
}
.my-order-page-date-order-each-order-right-buy-again-it {
  width: 110px;
  height: 28px;
  border: 2px solid var(--color-blue);
  color: var(--color-blue);
  font-size: 0.7rem;
  font-weight: 700;
  border-radius: 25px;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.my-order-page-date-order-each-order-right-write-review {
  width: 110px;
  height: 28px;
  background: var(--color-purple);
  border-radius: 25px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 0.7rem;
  color: white;
  margin: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-more-button {
  font-size: 1rem;
  background: var(--color-blue);
  color: white;
  padding: 6px 12px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px 0;
  align-self: center;
  position: relative;
}

.show-more-button::before,
.show-more-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue);
  opacity: 0.5;
  top: 50%;
}

.show-more-button::before {
  left: 110%;
}

.show-more-button::after {
  right: 110%;
}

.show-less-button {
  font-size: 1rem;
  background: var(--color-blue);
  color: white;
  padding: 6px 12px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px 0;
  align-self: center;
  position: relative;
}

.show-less-button::before,
.show-less-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--color-blue);
  opacity: 0.5;
  top: 50%;
}

.show-less-button::before {
  left: 110%;
}

.show-less-button::after {
  right: 110%;
}

.hidden {
  display: none;
}

.my-order-page-main-order-by-date-total-price-invoice-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-radius: 0 0 15px 15px;
  background: rgb(230, 228, 228);
}
.my-order-page-main-order-by-date-total-price-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.my-order-total-price-key {
  font-size: 1.1rem;
  font-weight: 700;
  color: black;
}
.my-order-total-price-value {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(67, 67, 67);
  margin-left: 10px;
  letter-spacing: 0.5px;
}
.my-order-page-main-order-invoice-div {
  font-size: 0.95rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 12px;
  border-radius: 25px;
  border: 2px solid var(--color-blue);
  background: white;
  cursor: pointer;
  color: var(--color-blue);
}
.my-order-page-main-order-invoice-div svg {
  font-size: 1.1rem;
  margin-right: 5px;
  color: var(--color-blue);
}

.active-order-sorting-btn {
  background: var(--color-blue);
  color: white;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .my-order-page-cancelled {
    margin-right: 5px;
  }

  .my-order-page-search-div {
    width: 10vw;
    overflow-x: hidden;
  }
  .my-order-page-search-div input {
    font-size: 12px;
  }
  .my-order-page-search-div svg {
    font-size: 15px;
  }

  .my-order-page-monthly-order-div select {
    padding: 5px;
  }

  .my-order-page-date-order-header-div {
    padding: 10px 8px;
  }

  .my-order-page-date-header-left-date-div {
    margin-right: 8px;
  }
  .left-date-heading {
    font-size: 12px;
  }
  .left-date-value {
    font-size: 10px;
  }
  .my-order-page-date-header-left-status-div {
    margin-right: 10px;
  }
  .left-status-heading {
    font-size: 12px;
  }
  .left-status-value-processing {
    font-size: 10px;
  }
  .left-status-value-processing p {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  .left-status-value-delivered {
    font-size: 10px;
  }
  .left-status-value-delivered p {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }
  .left-status-value-cancelled {
    font-size: 10px;
  }
  .left-status-value-cancelled p {
    width: 10px;
    height: 10px;
    margin-right: 3px;
  }

  .my-order-page-date-header-left-total-div {
    margin-right: 10px;
  }
  .left-total-heading {
    font-size: 12px;
  }
  .left-total-value {
    font-size: 10px;
  }

  .my-order-page-date-header-left-orderID-div {
    margin-right: 10px;
  }
  .left-orderID-heading {
    font-size: 12px;
  }
  .left-orderID-value {
    font-size: 10px;
  }

  .my-order-page-date-order-header-order-again {
    font-size: 10px;
    padding: 4px 8px;
    white-space: nowrap;
    margin-right: 10px;
  }
  .my-order-page-date-order-header-view-order {
    font-size: 10px;
    white-space: nowrap;
    margin-right: 0;
    padding: 3px 8px;
  }

  .my-order-page-date-order-each-order-left-img {
    width: 60px;
    height: 75px;
  }
  .my-order-page-date-order-each-order-left-detail-div {
    margin-left: 10px;
  }
  .my-order-page-date-order-each-order-left-detail-brands-logo {
    width: 50px;
  }
  .my-order-page-date-order-each-order-left-product-name {
    font-size: 12px;
    width: 150px;
  }
  .my-order-page-date-order-each-order-left-product-tech {
    font-size: 10px;
    width: 150px;
  }
  .my-order-page-date-order-each-order-left-product-variant-qty-div {
    left: 55%;
  }
  .my-order-page-date-order-each-order-left-product-qty {
    font-size: 10px;
  }
  .my-order-page-date-order-each-order-left-product-qty b {
    font-size: 12px;
  }

  .my-order-page-date-order-each-order-left-product-variant-size-div {
    font-size: 10px;
    width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .my-order-page-date-order-each-order-left-product-variant-color-div {
    font-size: 12px;
    width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .my-order-page-date-order-each-order-left-product-variant-color-div b {
    font-size: 12px;
  }
  .my-order-page-date-order-each-order-left-product-variant-variant-div {
    font-size: 10px;
  }
  .my-order-page-date-order-each-order-left-product-variant-variant-div b {
    font-size: 12px;
  }
  .my-order-page-date-order-each-order-right-div {
    margin-right: 5px;
  }
  .my-order-page-date-order-each-order-right-price {
    font-size: 10px;
  }
  .my-order-page-date-order-each-order-right-buy-again-it {
    width: 100px;
    height: 25px;
    font-size: 10px;
  }
  .my-order-page-date-order-each-order-right-write-review {
    width: 100px;
    height: 25px;
    font-size: 10px;
  }

  .show-more-button {
    padding: 3px 10px;
    font-size: 12px;
    margin: 10px 0;
  }

  .show-more-button::before,
  .show-more-button::after {
    width: 100px !important;
  }

  .show-less-button {
    padding: 3px 10px;
    font-size: 12px;
    margin: 10px 0;
  }

  .show-less-button::before,
  .show-less-button::after {
    width: 100px !important;
  }

  .my-order-page-main-order-by-date-total-price-invoice-div {
    padding: 8px 20px;
  }

  .my-order-total-price-key {
    font-size: 15px;
  }
  .my-order-total-price-value {
    font-size: 13px;
  }
}
