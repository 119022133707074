.custom-dropdown {
  width: 100%;
  padding: 0.3vw 1.1vw 0.55vw 1.6vw;
  background: white;
  color: black;
  position: relative;
  display: flex;
  align-items: center;
}
.custom-dropdown svg {
  color: #595757;
}

.custom-dropdown a {
  text-transform: none;
  color: rgba(0,0,0,1);
  margin: 0 0.3vw;
  transition: 0.3s;
  font-weight: 600;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.menu {
  width: 4.5vw;
  background: #3a9978;
  border-radius: 6px;
  padding: 0.4vw 0;
  margin-right: 0.3vw;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 0.55vw;
  justify-content: flex-start;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  background: var(--color-green);
  font-size: 0.8vw;
}
.menu svg {
  position: absolute;
  right: 0;
  color: white;
  font-size: 1.8vw;
}
.total-product-length{
  font-size: 12px;
  color: rgba(0,0,0,0.8);
  font-weight: 500;
}
.product-name-underline {
  text-transform: none;
  padding-bottom: 0px;
  font-size: 12px;
  letter-spacing: 0.5px;
  border-bottom: 2px solid var(--color-blue);
}
.navigation-slash {
  width: 2.5px;
  height: 35px;
  font-size: 1.8vw;
  margin-right: 0.55vw;
  background: black;
}
.navigation-transition {
  margin-top: -1vw;
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  background: var(--main-background-color);
}

.navigation-transition.open {
  height: 700px; /* Set to the maximum height you want */
  transition: height 0.5s ease-in-out;
}

@media screen and (max-width: 500px) {
  .custom-dropdown {
    /* display: none; */
    padding: 5px 0;
    padding-top: 10px;
    padding-left: 15px;
    overflow-x: scroll;
  }
  .custom-dropdown svg {
    font-size: 1rem;
  }

  .custom-dropdown a {
    font-size: 0.65rem;
    margin: 0px;
  }

  .product-name-underline {
    font-size: 0.75rem;
  }
}
