.shop-by-categories-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3vh 2vw;
  background: white;
}
.shop-by-categories-heading-container {
  width: auto;
  height: auto;
  margin: 2vh 0;
}
.shop-by-categories-heading-span {
  font-size: 25px;
  color: var(--color-blue);
  font-weight: 700;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  text-transform: capitalize;
}
.shop-by-categories-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  gap: 15px;
}
.shop-by-categories-each-category-container {
  width: 100%;
  height: 31vw;
  position: relative;
  cursor: pointer;
  overflow: hidden;

}
.shop-by-categories-each-category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.shop-by-categories-each-category-container:hover
  .shop-by-categories-each-category-image {
  transform: scale(1.05);
}
.shop-by-categories-each-category-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  transition: opacity 0.3s ease;
  opacity: 0.7;
  z-index: 1;
}

.shop-by-categories-each-category-container:hover::before {
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.shop-by-categories-each-category-img {
  position: absolute;
  bottom: 1.6vw;
  left: 1vw;
  width: 8vw;
  height: 6vh;
  object-fit: cover;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(100%);
  background: white;
}
.garrari-cat{
  object-fit: contain;
  background: transparent;
}

@keyframes pulse {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.1); /* Increased size */
  }
}
.shop-by-categories-each-category-container:hover
  .shop-by-categories-each-category-img {
  opacity: 1;
  transform: translateY(0);
}
.shop-by-categories-each-category-name {
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-weight: bold;
  color: white;
  z-index: 1;
}
.shop-by-category-each-category-offers {
  display: none;
}
.shop-by-categories-each-category-container:hover
  .shop-by-categories-each-category-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5vw;
}
.shop-by-categories-each-category-container:hover
  .shop-by-category-each-category-offers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-delay: 1s;
  font-size: 1.3vw;
  color: white;
  font-weight: bold;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  text-transform: capitalize;
  z-index: 2;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .shop-by-categories-container {
    padding-bottom: 0;
  }

  .shop-by-categories-main-container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .shop-by-categories-each-category-img {
    width: 80px;
    height: 40px;
  }

  .shop-by-categories-each-category-name {
    font-size: 20px;
  }

  .shop-by-categories-each-category-container:hover
    .shop-by-categories-each-category-name {
    font-size: 26px;
  }
  .shop-by-categories-each-category-container:hover
    .shop-by-category-each-category-offers {
    font-size: 12px;
  }
}
