.sign-up-form {
  width: 100%;
  height: 100vh;
}

.sign-up-container {
  width: 100%;
  min-height: 100vh;
  height: auto;
  color: black;
  background: white;
  position: relative;
}

.sign-up-main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}

.sign-up-sidebar-container {
  flex: 0 0 20%;
  overflow-y: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

.sign-up-each-step-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.sign-up-back-btn-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 8vh;
  left: 2vw;
  cursor: pointer;
}
.sign-up-back-btn-container:hover {
  text-decoration: underline;
}
.sign-up-back-btn-container svg {
  font-size: 0.78vw;
}
.sign-up-back-btn {
  font-size: 0.95vw;
  color: rgba(0, 0, 0, 0.8);
}
.sign-up-website-need-help-container {
  position: absolute;
  top: 4vh;
  right: 3vw;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sign-up-website-need-help-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  padding-right: 1vw;
}

.sign-up-website-need-help-img {
  width: 1.6vw;
  height: auto;
  border-radius: 50%;
  padding: 0.25vw;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin-right: 0.4vw;
}

.sign-up-website-need-help-span-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.sign-up-website-need-help-span-upper-span {
  font-size: 0.75vw;
  color: black;
  font-weight: 600;
}

.sign-up-website-need-help-span-bottom-span {
  font-size: 0.65vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

.sign-up-website-need-help-emain-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1vw;
}

.sign-up-website-need-help-email-img {
  width: 1.4vw;
  height: auto;
  margin-right: 0.4vw;
}

.sign-up-website-need-help-email-span {
  font-size: 0.75vw;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
}
.sign-up-each-step-main-container {
  position: absolute;
  top: 12vh;
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
}
.sign-up-buttons-container {
  width: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding: 1vw 2vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  background: white;
}

.sign-up-cancel-btn {
  width: auto;
  font-size: 1vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.2px;
  cursor: pointer;
  outline: none;
  border: none;
  background: white;
  margin-left: 20%;
}
.sign-up-continue-btn {
  width: auto;
  font-size: 0.9vw;
  margin-left: 1.5vw;
  padding: 0.8vh 0.5vw;
  padding-left: 1vw;
  border-radius: 4px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 600;
  letter-spacing: 0.2px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sign-up-continue-btn svg {
  font-size: 1.2vw;
  margin-left: 0.3vw;
}

.signup-onboarding-complete-container {
  width: 20vw;
  height: auto;
  position: fixed;
  top: 25vh;
  left: 40vw;
  background: white;
  border-radius: 8px;
  padding: 3vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}
.signup-onboarding-img {
  width: auto;
  height: 25vh;
}
.signup-congratulation-text {
  font-size: 1.2vw;
  color: black;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 1vh 0 0.5vh 0;
}
.signup-congratulation-desc {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}
.signup-continue-shopping-btn {
  font-size: 0.88vw;
  color: white;
  background: var(--color-green);
  padding: 0.8vh 0.8vw;
  border-radius: 4px;
  margin-top: 2vh;
  cursor: pointer;
}
.signup-continue-shopping-btn:hover {
  background: #249e42;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .sign-up-sidebar-container {
    flex: 0 0 30%;
  }

  .sign-up-back-btn-container svg {
    font-size: 14px;
  }
  .sign-up-back-btn {
    font-size: 15px;
  }
  .sign-up-website-need-help-container {
    padding: 10px;
  }

  .sign-up-website-need-help-div {
    padding-right: 10px;
  }

  .sign-up-website-need-help-img {
    width: 25px;
    padding: 3px;
    margin-right: 4px;
  }

  .sign-up-website-need-help-span-upper-span {
    font-size: 12px;
  }

  .sign-up-website-need-help-span-bottom-span {
    font-size: 10px;
  }

  .sign-up-website-need-help-emain-div {
    padding-left: 10px;
  }

  .sign-up-website-need-help-email-img {
    width: 25px;
    margin-right: 4px;
  }

  .sign-up-website-need-help-email-span {
    font-size: 13px;
  }

  .sign-up-buttons-container {
    padding: 10px 20px;
  }

  .sign-up-cancel-btn {
    font-size: 15px;
    margin-left: 30%;
  }
  .sign-up-continue-btn {
    font-size: 15px;
    padding: 8px 5px;
    padding-left: 10px;
  }
  .sign-up-continue-btn svg {
    font-size: 20px;
    margin-left: 3px;
  }

  .signup-onboarding-complete-container {
    width: 20vw;

    width: 300px;
    left: 50%;
    transform: translateX(-50%);
  }
  .signup-onboarding-img {
    height: 200px;
  }
  .signup-congratulation-text {
    font-size: 16px;
    margin: 10px 0 6px 0;
  }
  .signup-congratulation-desc {
    font-size: 13px;
  }
  .signup-continue-shopping-btn {
    font-size: 14px;
    padding: 8px 10px;
    margin-top: 15px;
  }
}
