.product-description-adv-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  margin-top: 10px;
}
.product-description-adv-container h3 {
  color: black;
  font-size: 18px;
  margin: 0;
  margin-bottom: 8px;
}
.product-description-adv-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.product-description-each-adv-div {
  width: auto;
  height: auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 2px 5px 2px 2px;
  border-radius: 30px;
  color: black;
  margin: 0 10px 10px 0px;
  background: rgba(0, 0, 0, 0.05);
}
.product-description-each-adv-div img {
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50px;
  /* padding: 3px; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 5px;
}
.product-description-each-adv-div span {
  font-size: 12px;
  color: var(--color-blue);
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .product-description-adv-main-container {
    width: 95%;
  }
}

@media screen and (max-width: 500px) {
  .product-description-adv-container {
    padding: 0 20px;
  }
  .product-description-adv-container h3 {
    margin-bottom: 12px;
    font-size: 1rem;
  }
  .product-description-adv-main-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 6px;
    justify-items: center;
    align-items: start;
  }
  .product-description-each-adv-div {
    background: none;
    flex-direction: column;
    width: auto;
    height: auto;
    /* margin-right: 15px; */
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
  .product-description-each-adv-div img {
    /* background: #f4f2f2; */
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 0;
    margin-bottom: 3px;
  }
  .product-description-each-adv-div span {
    font-size: 0.85rem;
    text-align: center;
    font-size: 0.7rem;
  }
}
