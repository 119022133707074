.banner {
    position: relative;
    width: 100%;
    min-height: 30vh;
    padding: 20px;
    padding-top: 50px;
    color: black;
    background: white;
    padding-right: 50px;
    @media screen and (max-width: 500px) {
      padding-top: 10px;
    }
  }
  
  .banner img {
    width: 100%;
    height: auto;
    padding: 0 15%;
    object-fit: contain;
  }
   .content {
    padding: 20px;
    background: white;
  }
  
  .banner h1 {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 2px;
    background: linear-gradient(#1d2536, #29395c, #3f5279);
    padding: 10px;
    color: white;
    border-radius: 0 0 50px 0;
    text-align: start;
  }
  
  .content-p {
    margin: 20px 0;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    font-weight: 500;
    padding-left: 10px;
    color: rgba(0,0,0,0.9);
  }
  .content-p1{
    margin: 20px 0;
    font-size: 1.2rem;
    letter-spacing: 0.2px;
    font-weight: 600;
    padding-left: 10px;
    color: rgba(0,0,0,1);
  }
  