.navigations-container {
  width: 100%;
  min-height: 60vh;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 0;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.4) inset;
}
.navigations-main-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 1vw;
}
.navigations-each-categories-container {
  min-width: 20%;
  width: 25%;
  padding: 0.5vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigations-each-categories-banners {
  width: 10vw;
  height: 14vh;
  border-radius: 8px;
}
.navigations-each-categories-img {
  width: 10vw;
  height: 90%;
  border-radius: 8px;
  cursor: pointer;
}
.navigations-each-categories-name {
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 5px;
  margin-left: 0.5vw;
}
.navigations-each-categories-name-span {
  font-size: 1vw;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: black;
  opacity: 0.95;
  text-transform: uppercase;
  cursor: pointer;
  transition: font-size 0.1s;
}
.navigations-each-categories-name-span:hover {
  font-size: 1.05vw;
  opacity: 1;
}
.navigations-each-categories-sub-categories-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.navigations-each-categories-each-sub-categories {
  width: auto;
  height: 2.2vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25vh 0;
  margin-left: 0.5vw;
}
.navigations-each-categories-each-sub-categories-span {
  font-size: 0.82vw;
  font-weight: 600;
  color: black;
  opacity: 0.7;
  cursor: pointer;
  transition: font-size 0.1s;
}
.navigations-each-categories-each-sub-categories-span:hover {
  font-size: 0.85vw;
  opacity: 0.9;
}

.navigations-brands-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0 1vw;
}
.navigations-each-brands-container {
  width: 25%;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.8vh;
}
.navigations-each-brands-logo {
  width: 50%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s;
  cursor: pointer;
}
.navigations-each-brands-logo:hover {
  transform: scale(1.05);
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .navigations-container {
    width: 100%;
    position: absolute;
    left: 0;
    /* transform: translateX(-50%); */
    padding: 10px 0;
    min-height: 35vh;
  }
  .navigations-main-container {
    padding: 0 10px;
  }
  .navigations-each-categories-container {
    padding: 15px 10px;
  }
  .navigations-each-categories-banners {
    height: 100px;
    width: 100px;
  }
  .navigations-each-categories-img {
    width: 100px;
    height: 100px;
  }
  .navigations-each-categories-name {
    height: 20px;
    margin-top: 10px;
    margin-left: 5px;
  }
  .navigations-each-categories-name-span {
    font-size: 13px;
  }
  .navigations-each-categories-name-span:hover {
    font-size: 14px;
  }

  .navigations-each-categories-each-sub-categories {
    height: 15px;
    margin: 5px 0;
    margin-left: 5px;
  }
  .navigations-each-categories-each-sub-categories-span {
    font-size: 13px;
  }
  .navigations-each-categories-each-sub-categories-span:hover {
    font-size: 14px;
  }

  .navigations-brands-container {
    padding: 0 10px;
  }
  .navigations-each-brands-container {
    padding-top: 8px;
    height: 35px;
  }
}
