/* PHONE VERIFICATION*/
.via-phone-verification-via-sms-container{
    width: 100%;
    height: 188px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -10px;
    /* padding: 10px; */
  }
  .via-phone-verification-via-sms-container img{
    position: absolute;
    top: 10px;
    left: 10px;
    background: #F5F8FE;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
  }
  .via-phone-verification-via-sms-container h3{
    color: black;
    margin: 6px 0;
    margin-top: 10px;
  }
  .via-phone-verification-via-sms-container span{
    margin: 3px 0;
  }
  .via-phone-verify-otp-btn {
    width: 30%;
    height: 35px;
    border-radius: 10px;
    font-size: 1rem;
    margin-bottom: 15px;
    font-weight: 600;
    background-color: #819ef5;
    color: #fff !important;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 6px auto;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .via-phone-vefification-via-sms-input-div{
    width: 30%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    margin: 5px 0;
  }
  .via-phone-vefification-via-sms-input-div span{
    margin-right: 3px;
  }
  .via-phone-vefification-via-sms-input-div input{
    width: 40%;
    height: 25px;
    margin: 2px 3px;
    color: rgba(0,0,0,0.6);
    outline: none;
    border: none;
    text-align: center;
    letter-spacing: 2px;
    font-size: 15px;
    font-weight: 600;
  }
  .via-phone-vefification-via-sms-input-div input::placeholder{
    color: #A5A5A5;
    font-weight: bold;
    text-align: center;
  }


/* VERIFIED*/
.via-phone-verification-phone-email-verified{
    background: rgba(58, 153, 120, 0.15);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    /* top: -10px; */
}
.via-phone-verification-phone-email-verified svg{
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    background: white;
    border-radius: 50%;
    padding: 5px;
    color: var(--color-green);
}