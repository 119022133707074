@media (max-width: 500px) {
  .mobile-change-phone-main-contain {
    width: 94vw;
    margin: 0 auto;
    display: flex;
    margin-top: 1vh;
  }
  .mobile-change-phone-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mobile-change-phone-heading {
    font-size: 4.5vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .mobile-change-phone-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mobile-change-phone-label {
    font-size: 3.8vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-change-phone-main-container input {
    width: 100%;
    height: 6vh;
    border-radius: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 5vw;
    padding-left: 1vh;
    font-weight: 600;
    margin: 0.5vh 0;
  }
  .mobile-change-phone-send-otp-btn {
    width: 100%;
    padding: 1.5vh 2vw;
    font-size: 4vw;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: white;
    background: var(--color-blue);
    border-radius: 4px;
    text-align: center;
    margin: 1vh 0;
  }
  .mobile-change-phone-cancel-btn {
    width: 100%;
    padding: 1.5vh 2vw;
    font-size: 4vw;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--color-blue);
    border: 2px solid var(--color-blue);
    border-radius: 4px;
    text-align: center;
  }
  .mobile-change-phone-enter-otp-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-change-phone-enter-otp-top-heading {
    font-size: 4vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-change-phone-enter-otp-next-heading{
    font-size: 3.5vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-change-phone-enter-otp-phone-number{
    font-size: 3vw;
    color: var(--color-blue);
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .change-phone-enter-otp-phone-number-otp-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-change-phone-otp-input-boxes{
    width: auto;
    height: auto;
    margin: 0 2vw;
  }
  .mobile-change-phone-otp-input-boxes input{
    width: 10vw;
    height: 14vw;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
  .mobile-change-phone-enter-otp-verify-btn{
    width: 100%;
    padding: 1.5vh 2vw;
    font-size: 4vw;
    font-weight: 600;
    letter-spacing: 0.5px;
    background: var(--color-blue);
    color: white;
    border-radius: 4px;
    text-align: center;
    margin: 1vh 0;
  }
  .mobile-change-phone-enter-otp-dont-receive-otp-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5vh 0;
  }
  .mobile-change-phone-enter-otp-dont-receive-text{
    font-size: 3.2vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
  }
  .mobile-change-phone-enter-otp-send-again-btn{
    font-weight: 3.2vw;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: var(--color-blue);
    margin-left: 1vw;
  }
}
