.order-confirm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 900px;
  height: 600px;
  background: white;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  z-index: 999;
}
.order-confirm-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 20px;
  position: relative;
}
.close-svg {
  position: absolute;
  top: 15px;
  left: 15px;
  color: black;
  cursor: pointer;
}
.order-confirm-left-container {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.order-confirm-left-basic-detail-container {
  width: 100%;
  height: 40%;
  padding: 30px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: black;
}
.order-confirm-left-basic-detail-container img {
  width: 160px;
  height: auto;
}
.thank-text {
  text-transform: uppercase;
  font-size: 1.1rem;
}
.order-confirm-confirm-text {
  font-size: 1rem;
  font-weight: 800;
}

.order-confirm-email-notification-text {
  font-size: 0.9rem;
  margin: 2vh 0;
  text-align: center;
  color: rgb(25, 25, 25);
}

.order-confirm-left-track-detail-container {
  width: 93%;
  border-radius: 15px;
  box-shadow: 0 0 4px 2px var(--border-color);
  padding: 10px 15px;
  background: white;
  margin: 0 auto;
}
.order-confirm-order-code-date-container {
  font-size: 0.85rem;
  color: rgb(69, 68, 68);
}

.order-confirm-breadcrum-container {
  width: 100%;
  height: auto;
  padding: 15px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(65, 63, 63);
  background: white;
  display: flex;
}
/* .step-item */
.order-confirm-step-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12rem;
}

/* .step-item:not(:first-child):before */
.order-confirm-step-item:not(:first-child)::before {
  content: "";
  background-color: #cbd5e0;
  position: absolute;
  width: 100%;
  height: 5px;
  right: 50%;
  top: 15%;
  transform: translateY(-50%);
}

/* .step */
.order-confirm-step {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  background-color: #cbd5e0;
  border-radius: 9999px;
  font-weight: 600;
  color: #fff;
}

.order-confirm-step svg {
  color: var(--color-white);
  font-size: 1.6rem;
}

/* .active .step */
.active .order-confirm-step {
  background-color: var(--color-green);
}
.active .order-confirm-text-gray {
  color: black;
  font-weight: 600;
}
.active .order-confirm-step svg {
  color: white;
  font-size: 1.6rem;
}

/* .complete .step */
.order-confirm-complete .order-confirm-step {
  background-color: var(--color-green);
}

.order-confirm-text-gray {
  width: 80px;
  text-align: center;
  color: rgb(104, 103, 103);
  font-weight: 500;
  font-size: 0.9rem;
}

/* .complete p */
.order-confirm-complete p {
  color: rgb(104, 103, 103);
}
.order-confirm-complete svg {
  color: white;
}

/* .complete:not(:first-child):before, .active:not(:first-child):before */
.order-confirm-complete:not(:first-child)::before,
.active:not(:first-child)::before {
  background-color: var(--color-green);
}

.order-confirm-delivery-date-container {
  font-size: 0.85rem;
  color: rgb(69, 68, 68);
}

.order-confirm-bottom-continue-shopping-view-order {
  width: 100%;
  height: auto;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.order-confirm-continue-shopping-btn-container {
  width: 200px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--color-blue);
  border-radius: 10px;
  padding: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}
.order-confirm-continue-shopping-btn-container svg {
  color: white;
  font-size: 1.8rem;
}
.order-confirm-view-my-order-btn-container {
  width: 200px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-green);
  border-radius: 10px;
  padding: 10px;
  font-size: 0.9rem;
  cursor: pointer;
}
.order-confirm-view-my-order-btn-container svg {
  color: white;
  font-size: 1.8rem;
  margin-right: 10px;
}

.order-confirm-right-container {
  width: 40%;
  height: 100%;
  box-shadow: 0 0 4px 1px var(--border-color);
  border-radius: 15px;
  color: rgb(49, 49, 49);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.order-confirm-right-container img {
  margin: 10px 0 20px 0;
  height: 6vh;
}
.order-product-card-container {
  width: 96%;
  border-radius: 15px;
  background: #edebeb;
}
.order-product-each-product-container {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.order-product-each-product-container img {
  width: 85px;
  height: 100px;
  margin: 10px;
  border-radius: 10px;
}
.order-product-each-product-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
}
.order-product-each-product-details img {
  width: 80px;
  height: 40px;
  margin-left: -3px;
  margin-top: -5px;
}
.order-product-name {
  font-size: 0.8rem;
  margin-top: -15px;
}

.order-product-qty {
  font-size: 0.8rem;
}
.order-product-price {
  font-size: 0.9rem;
}
.order-confirm-order-summary-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  position: absolute;
  bottom: 2vh;
}
.order-confirm-right-view-all-order-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.4vh 0;
}
.order-confirm-right-view-all-order-container span {
  background: var(--color-blue);
  border-radius: 25px;
  padding: 0.3vh 1vw;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8vw;
  cursor: pointer;
}
.order-confirm-right-view-all-order-container hr {
  width: 25%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.order-confirm-order-summary-container h3 {
  font-size: 1.2vw;
  margin: 0;
  padding: 0;
  border: none;
  margin-bottom: 10px;
}
.order-confirm-sub-total-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.8vh 0;
}
.order-confirm-delivery-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.8vh 0;
}
.order-confirm-total-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.8vh 0;
  color: black;
  font-weight: 700;
  font-size: 1.1rem;
}

.order-confirm-right-download-invoice-btn-container {
  width: 200px;
  height: 45px;
  border-radius: 10px;
  background: var(--color-blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
}

.move-to-payment-container {
  width: 900px;
  height: 600px;
  background: white;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 5vh;
}
.move-to-payment-container img {
  width: auto;
  height: 140px;
  /* border-radius: 50%; */
}
.move-to-payment-container svg {
  width: 150px;
  height: 150px;
  margin-bottom: 40px;
}
.move-to-payment-container span {
  font-weight: 500;
  font-size: 1rem;
  color: rgb(69, 68, 68);
  margin: 10px 0;
}

.payment-failed-container {
  width: 450px;
  height: auto;
  background: white;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  position: relative;
  padding: 20px 0 20px 0;
  z-index: 2;
}



.payment-failed-container img {
  width: 80px;
  height: auto;
  border-radius: 50%;
  /* position: absolute;
  top: -40px; */
}
.payment-price {
  font-size: 1.1rem;
  font-weight: 700;
}
.payment-failed-red-text {
  font-size: 1rem;
  color: rgb(230, 3, 3);
  font-weight: 700;
  margin: 5px 0;
}

.payment-failed-big-text {
  font-size: 0.9rem;
  font-weight: 600;
  width: 80%;
  text-align: center;
  margin: 5px 0;
}
.payment-failed-order-id-date {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 10px 0;
}
.payment-again-btn {
  background: var(--color-green);
  color: white;
  padding: 6px 12px;
  border-radius: 25px;
  margin-top: 10px;
  cursor: pointer;
}

.style_lds-default__Vpcil {
  width: 120px !important;
  height: 120px !important;
  margin-bottom: 50px;
}

b {
  color: black;
  font-weight: 700;
}

.move-to-payment-svg {
  position: absolute;
  top: 15px;
  left: 15px;
  color: black;
  cursor: pointer;
  width: 1.1vw !important;
  height: 1.1vw !important;
}

.order-confirm-cashback-container {
  width: 100%;
  height: 25vh;
  position: relative;
  padding: 0 1vw;
}
.order-confirm-cashback-img {
  width: 100%;
  height: 100% !important;
  border-radius: 10px;
}
.order-confirm-cashback-text {
  font-size: 0.85vw;
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60%;
  white-space: nowrap;
}
.order-confirm-cashback-btn {
  font-size: 0.8vw;
  font-weight: 700;
  background: white;
  color: var(--color-blue);
  border-radius: 25px;
  padding: 0.8vh 1vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 75%;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}


.want-to-place-order{
  width: 300px;
  height: 180px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-40%);
  box-shadow: 0 0 4px 1px var(--border-color);
  background: white;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.want-to-place-text{
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: rgba(0,0,0,0.8);
  margin: 20px 0;
}
.want-to-place-btn-div{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 10px 0;
}
.want-to-place-yes{
  background: var(--color-peach);
  font-size: 13px;
  color: white;
  border-radius: 25px;
  padding: 3px 15px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
}
.want-to-place-no{
  border: 2px solid rgba(0,0,0,0.3);
  font-size: 13px;
  color: rgba(0,0,0,0.8);
  border-radius: 25px;
  padding: 3px 15px;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
}

.payment-pending-container {
  width: 450px;
  height: auto;
  background: white;
  border-radius: 20px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  position: relative;
  padding: 20px 0 20px 0;
  z-index: 2;
}

.payment-price {
  font-size: 22px;
  font-weight: bold;
  color: #333;
  margin-top: 8px;
}

.payment-pending-yellow-text {
  font-size: 18px;
  font-weight: bold;
  color: #ffcc00; /* Yellow text */
  margin-top: 5px;
}

.payment-pending-big-text {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

.payment-pending-order-id-date {
  font-size: 14px;
  color: #999;
  margin-top: 8px;
  display: block;
}

.support-text {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

.support-contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #666;
  margin-top: 5px;
  text-align: left;
  margin-left: 20px;
}

.support-contact span {
  margin: 5px;
}

.support-contact a {
  color: #007bff; /* Blue for links */
  text-decoration: none;
}

.support-contact a:hover {
  text-decoration: underline;
}


@media screen and (min-width: 501px) and (max-width: 1000px) {
  .order-confirm-container {
    width: 600px;
    height: 400px;
  }

  .order-confirm-left-basic-detail-container {
    padding: 10px 80px;
  }
  .order-confirm-left-basic-detail-container img {
    width: 100px;
  }
  .thank-text {
    font-size: 14px;
  }
  .order-confirm-confirm-text {
    font-size: 12px;
  }

  .order-confirm-email-notification-text {
    font-size: 12px;
    margin: 10px 0;
  }

  .order-confirm-left-track-detail-container {
    padding: 5px 10px;
  }
  .order-confirm-order-code-date-container {
    font-size: 12px;
  }

  .order-confirm-breadcrum-container {
    padding: 8px 0 8px 0;
  }

  /* .step */
  .order-confirm-step {
    width: 25px;
    height: 25px;
  }

  .order-confirm-step svg {
    font-size: 20px;
  }

  .active .order-confirm-step svg {
    font-size: 20px;
  }

  .order-confirm-text-gray {
    width: 60px;
    font-size: 12px;
  }

  .order-confirm-delivery-date-container {
    font-size: 12px;
  }

  .order-confirm-bottom-continue-shopping-view-order {
    padding: 10px 10px;
  }
  .order-confirm-continue-shopping-btn-container {
    width: 48%;
    height: 40px;
    font-size: 12px;
    padding: 8px;
    white-space: nowrap;
  }
  .order-confirm-continue-shopping-btn-container svg {
    font-size: 20px;
  }
  .order-confirm-view-my-order-btn-container {
    width: 48%;
    height: 40px;
    font-size: 12px;
    padding: 8px;
    white-space: nowrap;
  }
  .order-confirm-view-my-order-btn-container svg {
    font-size: 20px;
    margin-right: 8px;
  }

  .order-confirm-right-container img {
    height: 40px;
    margin: 5px 0 10px 0;
  }

  .order-product-each-product-container img {
    width: 50px;
    height: 65px;
    margin: 5px;
  }

  .order-confirm-order-summary-container {
    bottom: 0;
    padding: 6px 10px;
  }

  .order-confirm-order-summary-container h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .order-confirm-sub-total-container {
    margin: 5px 0;
  }
  .order-confirm-delivery-container {
    margin: 5px 0;
  }
  .order-confirm-total-container {
    margin: 5px 0;
    font-size: 13px;
  }

  .move-to-payment-container {
    width: 600px;
    height: 400px;
  }
  

  .order-confirm-cashback-container {
    height: 100px;
    padding: 0 10px;
  }

  .order-confirm-cashback-text {
    font-size: 12px;
  }
  .order-confirm-cashback-btn {
    font-size: 12px !important;
    padding: 5px 10px !important;
    white-space: nowrap;
  }
}

@media screen and (max-width: 500px) {
  .order-confirm-cashback-container {
    width: 100%;
    height: 20vh;
    position: relative;
    padding: 0 1vw;
    margin-bottom: 1vh;
  }
  .order-confirm-cashback-img {
    width: 100%;
    height: 100% !important;
    border-radius: 10px;
  }
  .order-confirm-cashback-text {
    font-size: 3.5vw;
    color: white;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 58%;
    white-space: nowrap;
  }
  .order-confirm-cashback-btn {
    font-size: 3.2vw;
    font-weight: 700;
    background: white;
    color: var(--color-blue);
    border-radius: 25px;
    padding: 0.6vh 2vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 75%;
    cursor: none;
  }
}
