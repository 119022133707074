.sortby-container {
  position: absolute;
  top: 190%;
  right: 0;
  width: 225px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px ;
  z-index: 9;
  color: black;
  background-color: white;
  border: 1.5px solid var(--border-color);
  border-radius: 6px;
  box-shadow: 1px 1px 10px 3px var(--border-color);
}
.sortby-price-sorting-container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 10px;
  border-radius: 6px;
  text-transform: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 3px 0;
  font-size: 12px;
}
.div-name {
  margin-left: 8px;
}
.price-sorting {
  width: 55%;
  background: white;
  color: black;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5px;
}
.a-z-price-sorting {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 4px;
  margin: 0 1px;
}

.a-z-rating-sorting {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin: 0 1px;
  border-radius: 4px;
}
.a-z-price-sorting span {
  width: 4px;
  line-height: 1.3;
  font-size: 6px;
}
.a-z-rating-sorting span {
  width: 5px;
  line-height: 1.3;
  font-size: 6px;
}
.a-z-price-sorting svg {
  font-size: 14px;
}
.a-z-rating-sorting svg {
  font-size: 14px;
}
.sortby-main-icon{
  width: 18px;
  height: auto;
  margin-left: 4px;
}

/* .sortby-container div:hover {
  background: var(--color-blue);
  color: white;
} */
input[type="checkbox"] {
  accent-color: var(--color-blue);
}

.sortby-container .sortby-price-sorting-container:hover .price-sorting {
  background: white;
  color: black;
}

.sortby-container .a-z-price-sorting.selected {
  background: var(--color-blue);
  color: white;
  border-radius: 4px;
}
.sortby-container .a-z-rating-sorting.selected {
  background: var(--color-blue);
  color: white;
  border-radius: 4px;
}

/*MOBILE VIEW CSS*/

.mobile-sortby-container {
  display: none;
}

.mobile-selected {
  background: var(--color-blue);
}

/* .overlay {
  display: none;
} */
.overlay-sortby{
  display: none;
}

.overlay.active {
  display: block; /* Show the overlay when active */
}

@media screen and (max-width: 500px) {
  .sortby-container {
    display: none;
  }
  .sortby-price-sorting-container {
    display: none;
  }

  .sortby-container .a-z-price-sorting.selected {
    background: var(--color-green);
  }
  .sortby-container .a-z-rating-sorting.selected {
    background: var(--color-green);
  }

  /*MOBILE VIEW CSS*/

  .mobile-sortby-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    position: fixed;
    bottom: 5vh; /* Start off-screen */
    left: 0;
    background: white;
    color: black;
    border-radius: 20px 20px 0 0;
    z-index: 1000;
    transition: height 0.3s ease; /* Add a transition for smooth animation */
  }
  .mobile-sortby-header-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 2px solid rgb(230, 228, 228);
    font-size: 1.1rem;
  }
  .mobile-sortby-main-container {
    padding: 20px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .mobile-sortby-relevance {
    width: 75%;
    height: 43px;
    padding: 10px 15px;
    border-radius: 10px;
    margin: 5px 0;
  }

  .mobile-selected {
    background: var(--color-green);
    color: white;
  }
  .mobile-white-selected {
    background: white !important;
    color: black;
  }

  .mobile-sortby-price-sort-container {
    width: 75%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 15px;
    padding-right: 3px;
    border-radius: 10px;
    margin: 5px 0;
  }
  .mobile-sortby-price-sort-up-down-div {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(241, 241, 241);
    border-radius: 8px;
    padding: 3px;
  }
  .mobile-sortby-price-sort-up-down-div svg {
    width: 48%;
    height: 100%;
    border-radius: 5px;
    padding: 2px;
  }
  .mobile-sortby-rating-sort-container {
    width: 75%;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 15px;
    padding-right: 3px;
    border-radius: 10px;
    margin: 5px 0;
  }

  .mobile-sortby-rating-sort-up-down-div {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(241, 241, 241);
    border-radius: 8px;
    padding: 3px;
  }

  .mobile-sortby-rating-sort-up-down-div svg {
    width: 48%;
    height: 100%;
    border-radius: 5px;
    padding: 2px;
  }

  .overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray */
    z-index: 999; /* Ensure the overlay is above other content */
  }
  .overlay-sortby{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent gray */
    z-index: 1000; /* Ensure the overlay is above other content */
  }
}
