.bottom-promises-container {
  width: 100%;
  height: auto;
}
.bottom-promises-main-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: white;
}
.bottom-promises-each-promise {
  width: 25%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2vh 1.5vw;
}
.promises-each-promise-img {
  width: 30px;
  height: 30px;
  margin-bottom: 0.5vh;
}
.promises-each-promise-heading {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 700;
  margin: 0.2vh 0;
}
.promises-each-promises-text {
  width: 100%;
  text-align: start;
  font-size: 10px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  padding: 0.2vh 0 0.5vh 0;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .bottom-promises-each-promise {
    height: auto;
    padding: 10px;
  }
  .promises-each-promise-img {
    width: 30px;
    height: 30px;
  }
}
