.copyright-container {
  width: 100%;
  padding: 20px;
  padding-top: 50px;
  color: black;
  background: white;
  padding-right: 50px;
  @media screen and (max-width: 500px) {
    padding-right: 20px;
    text-align: justify;
    padding-top: 10px;
  }
}
.copy-h2 {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  background: linear-gradient(#1d2536, #29395c, #3f5279);
  padding: 10px;
  color: white;
  border-radius: 0 0 50px 0;
  text-align: start;
}
.copyright-container p {
  margin: 20px 0;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-left: 10px;
}
.copyright-container ul {
  list-style-type: disc;
}
.copyright-container ul li {
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.copyright-container a {
  text-decoration: underline;
  color: blue;
  font-weight: 600;
}
