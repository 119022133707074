.listing-pagination-container {
  width: 100%;
  height: 11vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
}
.listing-pagination-total {
  position: absolute;
  left: 2vw;
}
.listing-pagination-total-span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.listing-pagination-main-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listing-pagination-prev-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(25, 43, 66, 0.6);
  border-radius: 4px;
  padding: 5px 8px 5px 5px;
  cursor: pointer;
  margin-right: 3vw;
}
.listing-pagination-prev-container:hover {
  border: 2px solid rgba(25, 43, 66, 0.9);
}
.listing-pagination-prev-container svg {
  font-size: 20px;
  color: var(--color-blue);
  margin-right: 3px;
}
.listing-pagination-prev-container span {
  font-size: 13px;
  color: var(--color-blue);
  font-weight: 600;
}
.listing-pagination-total-number-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listing-pagination-total-number-container span {
  width: 25px;
  height: 25px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5vw;
  cursor: pointer;
}
.listing-pagination-next-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(25, 43, 66, 0.7);
  border-radius: 4px;
  padding: 5px 5px 5px 8px;
  cursor: pointer;
  margin-left: 3vw;
}
.listing-pagination-next-container:hover {
  border: 2px solid rgba(25, 43, 66, 0.9);
}
.listing-pagination-next-container svg {
  font-size: 20px;
  color: var(--color-blue);
  margin-left: 3px;
}
.listing-pagination-next-container span {
  font-size: 13px;
  color: var(--color-blue);
  font-weight: 600;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .listing-pagination-container {
    height: 80px;
  }
}
