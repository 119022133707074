.sovereign-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 10px;
}

.sovereign-container h1 {
  text-align: center;
  color: #333;
}

.sovereign-brand-info p {
  margin-bottom: 15px;
  font-size: 15px;
}

.sovereign-highlights {
  margin-bottom: 15px;
}

.sovereign-highlights h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  margin: 0;
}

.sovereign-highlights ul {
  list-style: disc;
}

.sovereign-highlights li {
  color: #777;
  font-size: 13.8px;
  line-height: 1.3;
}

.sovereign-highlights li strong {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
