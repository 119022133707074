.productPrice-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.productPrice-left-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.productPrice-mrp-price-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.productPrice-mrp-price-heading {
  width: 3.5vw;
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  margin-top: 10px;
}
.productPrice-mrp-price-main-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.productPrice-offer-price-span {
  font-size: 1.5vw;
  color: var(--color-green);
  margin-left: 0.5vw;
}

.productPrice-mrp-price-span {
  font-size: 2vw;
  color: var(--color-blue);
  font-weight: 700;
}
.productPrice-mrp-price-inclusive {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  margin-top: 0.4vh;
  white-space: nowrap;
}
.productPrice-ndp-price-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5vh;
}
.productPrice-ndp-price-heading {
  width: 3.5vw;
  font-size: 0.9vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}
.productPrice-ndp-price-span {
  font-size: 17px;
  color: black;
  font-weight: 700;
}
.productPrice-divider-container {
  width: auto;
  height: auto;
}
.productPrice-divider-img {
  width: auto;
  height: auto;
}
.productPrice-right-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productPrice-right-margin-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-green);
  /* white-space: nowrap; */
  font-size: 15px;
}
.productPrice-right-margin-logo {
  width: 18px;
  height: 18px;
  margin-right: 0.2vw;
}
.productPrice-right-margin-span {
  font-size: 13px;
  color: var(--color-green);
  font-weight: 700;
}
.productPrice-right-offer-span {
  font-size: 13px;
  color: var(--color-green);
  font-weight: 700;
  margin-left: 4px;
}
.product-price-login-to-see-price {
  background: var(--color-green);
  padding: 0.3vh 0.5vw;
  border-radius: 3px;
  color: white;
  font-size: 10px;
  letter-spacing: 0.25px;
  margin-bottom: 0.5vh;
  cursor: pointer;
  white-space: nowrap;
}
.product-price-not-visible-container svg {
  font-size: 20px;
  cursor: pointer;
  margin-top: 0.6vh;
  margin-right: 0.3vw;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .productPrice-mrp-price-heading {
    width: 55px;
    font-size: 14px;
  }

  .productPrice-offer-price-span {
    font-size: 16px;
  }

  .productPrice-mrp-price-span {
    font-size: 22px;
  }

  .productPrice-ndp-price-heading {
    width: 55px;
    font-size: 15px;
  }

  .productPrice-divider-img {
    width: 20px;
  }

  .productPrice-right-margin-container {
    font-size: 10px;
    text-align: center;
  }
  .productPrice-right-margin-logo {
    width: 15px;
    height: 15px;
  }
}
