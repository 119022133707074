.support-container {
  width: 100%;
  height: auto;
  margin: 40px 0;
}
.support-main-container {
  width: 100%;
  min-height: 50vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.support-header-container {
  min-width: 50%;
  min-height: 5.5vh;
  width: auto;
  height: auto;
}
.support-header-main-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 25px;
  padding: 4px;
}
.support-each-header {
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 700;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
}
.header-active {
  background: var(--color-green);
  color: white;
}
/* .support-each-header:hover{
    background: var(--color-blue);
    color: white;
} */
.support-details-container {
  width: 65%;
  height: auto;
  margin: 2vh 0;
}
.support-each-details-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 13px 10px;
  margin: 5px 0;
  background: white;
  border-radius: 8px;
}
.support-each-details-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.support-each-details-header-container svg {
  position: relative;
  font-size: 20px;
  color: var(--color-blue);
  cursor: pointer;
  z-index: 1;
}
.support-each-details-header-text {
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 800;
}
.support-each-details-main-content {
  width: 100%;
  text-align: start;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-blue);
  margin-top: 14px;
  padding-right: 20px;
  line-height: 1.5;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .support-details-container {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
 
  .support-header-container {
    min-width: 50vw;
    min-height: 5.5vh;
    width: 95%;
    height: 20px;
  }

  .support-each-header {
    font-size: 10px;
    color: var(--color-blue);
    font-weight: 800;
    letter-spacing: 0.5px;
    padding: 5px 8px;
    border-radius: 25px;
    text-align: center;
    cursor: none;
  }
  .support-details-container {
    width: 85vw;
    height: auto;
    margin: 2vh 0;
  }
  .support-each-details-header-container svg {
    position: relative;
    font-size: 18px;
    color: var(--color-blue);
    cursor: none;
    z-index: 1;
  }
  .support-each-details-header-text {
    font-size: 14px;
    color: var(--color-blue);
    font-weight: 700;
  }
  .support-each-details-main-content {
    width: 100%;
    text-align: start;
    font-weight: 600;
    font-size: 12px;
    color: var(--color-blue);
    margin-top: 14px;
    padding-right: 20px;
    line-height: 1.5;
  }
}
