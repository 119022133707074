.containers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
}
.containers .ratings-box {
  width: 100%;
  height: 270px;
  background: #800080;
  /* background: linear-gradient(purple 15%,rgb(179, 2, 179)); */
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* padding: 0 20px 0 30px; */
  padding: 30px;
  box-shadow: 0 3px 6px 0px gray;
  justify-content: space-between;
}
.ratings-no-review-yet-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.ratings-no-review-yet-heading {
  font-size: 12px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.ratings-be-the-first-review-btn {
  background: white;
  border-radius: 25px;
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.3px;
  font-weight: 700;
  font-size: 12px;
  box-shadow: 3px 3px 8px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.ratings-be-the-first {
  font-size: 18px;
  color: white;
  font-weight: 700;
  letter-spacing: 0.4px;
}
.ratings-types-reviews-container {
  width: 65%;
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px 10px 0;
}
.ratings-types-review-container-div {
  width: 325px;
  height: 108px;
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ratings-types-review-container-div .rating_number {
  background: white;
  width: 140px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem !important;
  color: black;
  font-weight: bold;
  border-radius: 50%;
  margin: 0;
  margin-right: 10px;
  /* margin-right: 30px; */
  margin-bottom: 10px;
  box-shadow: 0px 0px 10px 1px rgb(45, 44, 44);
}
.rating-types-div-container {
  width: 430px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 20px;
  border-right: 2px solid var(--border-color);
  border-left: 2px solid var(--border-color);
}
.ratings-types-div {
  width: 410px;
  height: 25px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: none;
}
.ratings-types-div-img-container {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ratings-types-div-img-container img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 2px solid var(--border-color);
  border-radius: 33px;
  padding: 3px;
}
.ratings-types-div-img-container span {
  width: 100%;
  color: #eeeeee;
  font-size: 15px;
  white-space: nowrap;
}
.ratings-gold {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 3px;
}
.ratings-gold svg {
  color: rgb(250, 198, 42);
  font-size: 30px;
}
.ratings-types-div span {
  width: 4%;
  text-align: center;
}
.total-ratings {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.ratings-details-svg-div svg {
  color: rgb(250, 198, 42);
  font-size: 30px;
}
.ratings-details button {
  background-color: var(--color-green);
  border-radius: 25px;
  padding: 6px 15px;
  border: none;
  cursor: pointer;
  /* font-weight: 550; */
  font-size: 1rem;
  font-weight: 600;
  margin-top: 10px;
  /* box-shadow: 3px 3px 8px 1px rgb(68, 68, 68); */
}
.ratings-details .ratings-totals {
  font-size: 0.9rem;
  text-transform: capitalize;
  color: #eeeeee;
}
.ratings-graph {
  width: 489px;
  height: 224px;
  /* margin: 20px; */
  padding: 0 30px;
  border-radius: 10px;
  background: #fdfdfd;
  color: black;
  display: flex;
  flex-direction: column;
}
.containers .ratings-box .ratings-graph h4 {
  text-align: center;
  text-transform: capitalize;
  margin: 8px 0 0 0;
  font-size: 1.5rem;
  font-weight: 600;
}
.containers .ratings-box .ratings-graph li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1.5px 0;
}
.containers .ratings-box .ratings-graph li .ratings_5 {
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.containers .ratings-box .ratings-graph li .ratings_5 .ratings_5_details {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
}
.ratings_5_n svg {
  color: rgb(255, 237, 102);
  font-size: 25px;
}
.ratings_5_details_no_span {
  color: rgb(103, 102, 102);
  font-size: 0.7rem;
}
.containers .ratings-box .ratings-graph li .ratings_5 .ratings_5_details span {
  font-size: 0.85rem;
}
.containers .ratings-box .ratings-graph li .ratings-color-div {
  width: 100%;
  height: 5px;
  background: rgb(165, 165, 165);
  border-radius: 25px;
}
.containers .ratings-box .ratings-graph li .ratings-color-div p {
  height: 5px;
  background: #cc3d8a;
  border-radius: 25px;
  margin: 0;
}

.containers .reviews-containers {
  width: 100%;
  padding: 1.5rem 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #800080;
}
.containers .reviews-containers h3 {
  color: black;
  font-size: 2rem;
  margin: 0;
  font-weight: 700;
  text-transform: none;
}
.containers .reviews-containers .reviews-filter-containers {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.containers .reviews-containers .reviews-filter-containers .write-review {
  /* width: 35%; */
  /* width: 174px; */
  /* height: 40px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1vh 1vw;
  color: #f8f6f6;
  background-color: var(--color-green);
  border-radius: 30px;
  /* padding: 13px; */
  font-size: 1vw;
  /* border: 1px solid black; */
  text-transform: none;
  cursor: pointer;
  transition: all ease 0.3s;
  white-space: nowrap;
}
.containers .reviews-containers .reviews-filter-containers .write-review:hover {
  transform: scale(1.03);
  transition: all ease 0.3s;
}
.containers .reviews-containers .reviews-filter-containers .write-review svg {
  font-size: 1.5vw;
  margin-right: 3px;
  transition: all ease 0.3s;
  /* font-size: 25px; */
}
.containers .reviews-containers .reviews-filter-containers .reviews-filter {
  /* width: 33%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.containers
  .reviews-containers
  .reviews-filter-containers
  .reviews-filter
  select {
  /* width: 120px; */
  /* height: 40px; */
  margin: 0 15px;
  padding: 1vh 1vw;
  outline: none;
  font-size: 1vw;
  border-radius: 30px;
  font-weight: 600;
  background: white;
  color: #807e7e;
  border: 2px solid #595757;
  cursor: pointer;
}
.containers
  .reviews-containers
  .reviews-filter-containers
  .reviews-filter
  select
  option {
  font-weight: 600;
  padding: 8px 0;
}
.mobile-reviews-filter-sortby {
  display: none;
}

.sortby-selected {
  color: white;
  background: var(--color-green);
}
.overlay-sortby {
  display: none;
}

/*Review Info*/
/*Review Info*/
.containers .reviews-info-containers {
  width: 100%;
  padding: 0.8rem 0rem;
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
}
.totalNoOfReviews {
  position: absolute;
  bottom: 30px;
  right: 0;
  color: rgb(74, 72, 72);
}
.containers .reviews-info-containers .review-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  text-transform: none;
  padding-bottom: 50px;
  border-bottom: 2px solid rgb(100, 98, 98);
}
.containers .reviews-info-containers .review-detail .review-name-containers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.containers
  .reviews-info-containers
  .review-detail
  .review-name-containers
  .review-logo {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 5px;
}
.containers
  .reviews-info-containers
  .review-detail
  .review-name-containers
  .review-logo
  img {
  width: 70px;
  height: 70px;
  margin-bottom: 8px;
  margin-left: 8px;
}
.containers
  .reviews-info-containers
  .review-detail
  .review-name-containers
  .review-logo
  .review-name-time {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
}
.containers
  .reviews-info-containers
  .review-detail
  .review-name-containers
  .review-logo
  .review-name-time
  span {
  font-weight: 550;
  font-size: 0.8rem;
  color: rgb(72, 70, 70);
  padding-top: 5px;
}
.containers
  .reviews-info-containers
  .review-detail
  .review-name-containers
  .review-logo
  .review-name-time
  h4 {
  margin: 0;
  font-size: 1rem;
  width: 100%;
}
.user-total-ratings {
  display: none;
}
.containers
  .reviews-info-containers
  .review-detail
  .review-name-containers
  img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}
.containers .reviews-info-containers .review-detail .review-desc {
  font-size: 0.88rem;
  /* padding: 0.8rem 0 1rem 0; */
  padding: 0.2rem 0 1.2rem 0;
  margin-left: 10px;
  font-weight: 500;
  /* font-weight: 100; */
  color: rgb(54, 53, 53);
}
.containers .reviews-info-containers .review-detail .review-reaction {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.75rem;
  padding-right: 20px;
}
.containers .reviews-info-containers .review-detail .review-reaction span {
  margin: 0 10px 3px 10px;
}

/* .review-ratings svg{
  color: rgb(250, 198, 42);
  font-size: 30px;
} */

.review-ratings {
  width: 37%;
  padding-left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 2px solid var(--border-color);
  border-right: 2px solid var(--border-color);
}
.review-user-ratings-details-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.review-user-ratings-details-container h2 {
  width: 80px;
  height: 80px;
  background: #ffd700;
  color: black;
  border-radius: 50px;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 35px;
}
.review-user-ratings-details-types-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.review-user-ratings-details-types-each-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.review-user-ratings-types-img-name-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.review-user-ratings-types-img-name-div img {
  width: 27px;
  height: 27px;
  margin-right: 5px;
}
.review-user-ratings-types-img-name-div span {
  font-size: 0.98rem;
  width: 150px;
}

.review-user-ratings-details-types-ratings-div {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.review-user-ratings-details-types-ratings-div svg {
  font-size: 1.8rem;
}

.review-ratings-desc-reaction-container {
  width: 52%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 15px;
}
.load-more-review-btn {
  width: 140px;
  height: 50px;
  border: 2px solid #720372;
  color: lightgray;
  border-radius: 10px;
  background: #720372;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
}
.review-desc {
  width: 100%;
  text-align: start;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .containers {
    padding: 0 10px;
  }
  .containers .ratings-box {
    padding: 10px;
    height: 200px;
  }

  .ratings-types-reviews-container {
    padding: 5px 10px 5px 0;
  }
  .ratings-types-review-container-div {
    width: 200px;
    height: 80px;
    padding: 10px 0;
  }
  .ratings-types-review-container-div .rating_number {
    width: 80px;
    height: 50px;
    font-size: 20px !important;
    margin-right: 5px;
  }
  .rating-types-div-container {
    width: 300px;
    height: 130px;
    padding: 5px 10px;
  }
  .ratings-types-div {
    width: 280px;
    height: 20px;
    margin: 15px 0;
  }
  .ratings-types-div-img-container {
    width: 60%;
  }
  .ratings-types-div-img-container img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  .ratings-types-div-img-container span {
    font-size: 12px;
  }

  .ratings-gold svg {
    font-size: 18px;
  }
  .ratings-types-div span {
    width: 10%;
  }

  .ratings-details-svg-div svg {
    font-size: 16px;
  }
  .ratings-details button {
    font-size: 12px;
    padding: 3px 8px;
    margin-top: 10px;
    margin-left: -20px;
  }
  .ratings-details .ratings-totals {
    font-size: 8px;
  }
  .ratings-graph {
    width: 220px;
    height: 180px;
    padding: 0 10px;
  }
  .containers .ratings-box .ratings-graph h4 {
    font-size: 18px;
  }

  .containers .ratings-box .ratings-graph li .ratings_5 {
    height: 20px;
  }
  .containers .ratings-box .ratings-graph li .ratings_5 .ratings_5_details {
    width: 50%;
  }
  .ratings_5_n svg {
    font-size: 16px;
  }
  .ratings_5_details_no_span {
    font-size: 6px;
  }
  .containers
    .ratings-box
    .ratings-graph
    li
    .ratings_5
    .ratings_5_details
    span {
    font-size: 6px;
  }

  .containers .reviews-containers {
    padding: 10px 0 0 0;
  }
  .containers .reviews-containers h3 {
    font-size: 25px;
  }
  .containers .reviews-containers .reviews-filter-containers {
    width: 40%;
    justify-content: flex-end;
  }
  .containers .reviews-containers .reviews-filter-containers .write-review {
    padding: 8px 15px;
    font-size: 13px;
  }

  .containers .reviews-containers .reviews-filter-containers .write-review svg {
    font-size: 18px;
  }

  .containers
    .reviews-containers
    .reviews-filter-containers
    .reviews-filter
    select {
    font-size: 13px;
    padding: 8px 15px;
  }

  .containers .reviews-info-containers {
    padding: 8px 0;
  }

  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo
    img {
    width: 45px;
    height: 45px;
  }

  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo
    .review-name-time
    span {
    font-size: 12px;
  }
  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo
    .review-name-time
    h4 {
    font-size: 13px;
  }

  .containers .reviews-info-containers .review-detail .review-desc {
    font-size: 12px;
    padding: 2px 0 8px 0;
  }
  .containers .reviews-info-containers .review-detail .review-reaction {
    padding-right: 10px;
  }
  .containers .reviews-info-containers .review-detail .review-reaction span {
    margin: 0 8px 2px 8px;
  }

  .review-ratings {
    width: 45%;
    padding-left: 10px;
  }

  .review-user-ratings-details-container h2 {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    font-size: 18px;
  }

  .review-user-ratings-details-types-each-div {
    margin: 5px 0;
  }

  .review-user-ratings-types-img-name-div img {
    width: 20px;
    height: 20px;
  }
  .review-user-ratings-types-img-name-div span {
    font-size: 12px;
    width: 100px;
  }

  .review-user-ratings-details-types-ratings-div svg {
    font-size: 18px;
  }

  .review-ratings-desc-reaction-container {
    width: 40%;
    margin-left: 10px;
  }
}

@media screen and (max-width: 500px) {
  .containers {
    padding: 0;
  }
  .containers .ratings-box {
    box-shadow: none;
    background: white;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0;
  }
  .ratings-types-reviews-container {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
  .ratings-types-review-container-div {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
  .ratings-types-review-container-div .rating_number {
    box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    margin-bottom: 0;
  }
  .rating-types-div-container {
    width: 100%;
    border-right: none;
    border-left: none;
    padding: 0.5vh 0;
  }
  .ratings-types-div {
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
  }

  .ratings-types-div-img-container img {
    border: 1px solid rgb(112, 110, 110);
    background: rgb(186, 229, 210);
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  .ratings-types-div-img-container p {
    color: black;
  }
  .ratings-gold {
    width: 30%;
  }
  .ratings-gold svg {
    font-size: 25px;
  }
  .ratings-types-div span {
    display: none;
    width: 6%;
    font-size: 0.9rem;
    margin-left: 3px;
  }
  .total-ratings {
    width: 100%;
    flex-direction: column;
    border: none;
  }

  .ratings-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ratings-details-svg-div svg {
    font-size: 25px;
  }
  .ratings-details button {
    background: var(--color-green);
    color: white;
    padding: 10px 15px;
    margin-top: 5px;
  }
  .ratings-details .ratings-totals {
    display: none;
    margin: 5px 0;
  }
  .ratings-graph {
    width: 100%;
    height: auto;
    padding: 0 3vw;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .containers .ratings-box .ratings-graph h4 {
    margin-bottom: 8px;
    font-size: 1.1rem;
  }

  .containers .ratings-box .ratings-graph li .ratings_5 .ratings_5_details {
    min-width: 33%;
    width: auto;
  }
  .ratings_5_n svg {
    font-size: 20px;
  }

  .containers .reviews-containers {
    border: none;
    background: white;
    padding: 0 3vw;
  }
  .containers .reviews-containers h3 {
    display: none;
  }
  .containers .reviews-containers .reviews-filter-containers {
    width: 100%;
  }
  .containers .reviews-containers .reviews-filter-containers .write-review {
    width: auto;
    /* height: 40px; */
    padding: 0.8vh 2vw;
    font-size: 3vw;
  }
  .containers .reviews-containers .reviews-filter-containers .write-review svg {
    font-size: 5vw;
  }

  .containers
    .reviews-containers
    .reviews-filter-containers
    .reviews-filter
    select {
    width: auto;
    margin: 0;
    border-radius: 25px;
    margin-right: 0;
    border: 2px solid var(--border-color);
    font-size: 3.5vw !important;
    padding: 0.8vh 2vw;
  }
  .containers
    .reviews-containers
    .reviews-filter-containers
    .reviews-filter
    select
    option {
    display: none;
    font-size: 3.5vw;
  }
  .mobile-reviews-filter-sortby {
    width: 100%;
    height: auto;
    background: white;
    color: black;
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 101 !important;
    padding: 20px;
    padding-bottom: 10px;
    box-shadow: 0 -5px 8px 2px rgba(0, 0, 0, 0.1);
  }
  .mobile-review-filter-sortby-header-div {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-review-filter-sortby-header-div span {
    font-size: 1.1rem;
    font-weight: 700;
  }
  .mobile-review-filter-sortby-header-div svg {
    font-size: 1.4rem;
  }
  .mobile-review-filter-sortby-main-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mobile-review-filter-sortby-main-div span {
    font-size: 0.95rem;
    padding: 15px 10px;
    width: 100%;
    border-radius: 10px;
  }

  .overlay-sortby {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent grey color */
    z-index: 99 !important;
  }

  /*Review Info*/
  /*Review Info*/
  .containers .reviews-info-containers {
    background: white;
    padding: 0 3vw;
  }
  .totalNoOfReviews {
    right: 20px;
    bottom: 10px;
  }
  .containers .reviews-info-containers .review-detail {
    /* border-bottom: 2px solid var(--border-color); */
    border: none;
    padding: 15px 0;
  }
  .containers .reviews-info-containers .review-detail .review-name-containers {
    flex-direction: column;
    align-items: flex-start;
  }
  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo {
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-left: 0;
  }
  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo
    img {
    width: 50px;
    height: 50px;
    border: 1px solid var(--border-color);
  }
  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo
    .review-name-time {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    .review-logo
    .review-name-time
    h4 {
    width: auto;
    margin-bottom: 30px;
  }
  .user-total-ratings {
    display: flex;
    position: absolute;
    bottom: 5px;
    left: 0;
  }
  .containers
    .reviews-info-containers
    .review-detail
    .review-name-containers
    img {
    margin-right: 8px;
  }
  .containers .reviews-info-containers .review-detail .review-desc {
    padding: 0;
    padding-right: 5px;
  }
  .containers .reviews-info-containers .review-detail .review-reaction {
    justify-content: flex-start;
    padding: 5px 3vw;
  }
  .containers .reviews-info-containers .review-detail .review-reaction span {
    font-size: 1rem;
  }

  .review-ratings {
    display: none;
  }

  .review-ratings-desc-reaction-container {
    width: 100%;
    margin: 0;
  }

  .review-desc {
    width: 100%;
    padding: 0 3vw;
  }
  .ratings-no-review-yet-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 3vw;
  }
  .ratings-no-review-yet-heading {
    font-size: 4vw;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .ratings-be-the-first {
    width: 60%;
    text-align: center;
    font-size: 3.5vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 1.5vh 0;
  }
  .ratings-be-the-first-review-btn {
    font-size: 4vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    letter-spacing: 0.5px;
    margin-top: 3vh;
  }
}
