.brand-select-container {
  position: absolute;
  top: 165%;
  width: 225px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  color: black;
  background-color: white;
  border: 1.5px solid var(--border-color);
  border-radius: 6px;
  z-index: 9;
  box-shadow: 1px 1px 10px 3px var(--border-color);
}
.brand-select-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 13px;
}
.brand-select-btn-contain {
  width: 55%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.brand-select-btn-contain button {
  border-radius: 6px;
  padding: 4px 10px;
  cursor: pointer;
  color: white;
  border: none;
  font-size: 12px;
  font-weight: 600;
}
.brand-select-btn-apply {
  background: var(--color-green);
}
.brand-select-btn-apply:hover {
  background: #239740;
}
.brand-select-btn-clear {
  background: rgb(233, 14, 14);
}
.brand-select-btn-clear:hover {
  background: rgb(220, 14, 14);
}

.brand-select-search-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.brand-select-search-container input {
  width: 100%;
  padding: 4px;
  background-color: #e6e6e6;
  border: 1.5px solid var(--border-color);
  border-radius: 6px;
  outline: none;
}
.brand-select-search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  cursor: pointer;
}
.brand-select-option-container {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: auto;
  padding: 5px;
}
.brand-select-option-container span {
  font-size: 12px;
}
.mobile-brand-select-option-container {
  display: none;
}
.brand-select-option-container::-webkit-scrollbar {
  display: block;
}
.brand-name-checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  border-radius: 4px;
  margin: 3px 0;
}
.brand-name-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
  accent-color: var(--color-green);
}
.brand-name-checkbox-selected input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
  accent-color: white;
}
.brand-name-checkbox-selected input[type="checkbox"]:checked {
  /* border: 2px solid white;
  accent-color: var(--color-blue); */
}
.brand-name-checkbox span {
  cursor: pointer;
  font-size: 13px;
  white-space: nowrap;
}
.brand-name-checkbox-selected {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 3px 0;
  padding: 4px;
  border-radius: 4px;
  background: var(--color-blue);
  color: white;
}

@media screen and (max-width: 500px) {
  .brand-select-container {
    width: 100%;
    position: relative;
    top: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    padding-left: 10px;
    height: auto;
  }
  .brand-select-btn-container {
    display: none;
  }

  .brand-select-search-container {
    display: none;
  }

  .brand-select-option-container {
    display: none;
  }

  .mobile-brand-select-option-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: auto;
    padding: 0.5vh 2vh;
    height: auto;
  }
  .brand-name-checkbox {
    font-size: 3.5vw;
  }

  .brand-name-checkbox-selected {
    font-size: 3.5vw;
  }
  .brand-name-span-d {
    font-size: 3.5vw !important;
    margin-left: 2vw;
  }
}
