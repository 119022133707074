.big-search-container {
  width: 100%;
  height: 55vh;
  position: relative;
  z-index: 999;
  background: white;
  border-radius: 0 0 15px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3vw 1.5vw 0 1.5vw;
  box-shadow: 0 0px 8px 1px rgba(0, 0, 0, 0.2);
}
.big-search-top-vertical-container {
  width: 75%;
  min-height: 40vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 1vw;
}
.big-search-top-vertical-main-container {
  width: 100%;
  height: 11vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 1vw;
}
.big-search-top-vertical-parts-container {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.big-search-top-vertical-each-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.big-search-top-vertical-each-img {
  width: 1.8vw;
  height: 1.8vw;
  border-radius: 50%;
  margin-right: 0.4vw;
  background: darkgray;
  padding: 0.25vw;
  margin-left: 0.2vw;
}
.big-search-top-vertical-each-span {
  font-size: 1vw;
  color: black;
  font-weight: 700;
}
.big-search-searched-items-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.55vw 0;
}
.big-search-searched-each-item {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.2vw 0;
  padding: 0.35vw;
  cursor: pointer;
  transition: background 0.3s;
}
.big-search-searched-each-item:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.big-search-searched-each-img {
  width: 1.4vw;
  height: 1.4vw;
  background: white;
  border-radius: 4px;
  margin-right: 0.4vw;
  padding: 0;
}
.big-search-searched-each-span {
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}
.big-search-top-vertical-brand-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1vw;
  padding-top: 2vh;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding-right: 1vw;
}

.big-search-top-vertical-each-brand {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.big-search-top-vertical-each-brand-img {
  width: 80%;
  height: auto;
  max-height: 5vh;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.big-search-top-vertical-each-brand-img:hover {
  transform: scale(1.05);
}

.big-search-search-items-total-count {
  width: auto;
  padding-left: 1vw;
}
.big-search-search-items-total-count-span {
  font-size: 0.8vw;
  font-weight: 700;
  color: var(--color-blue);
  transition: font-size 0.3s ease;
  cursor: pointer;
}

.big-search-search-items-total-count-span:hover {
  font-size: 0.82vw;
  text-decoration: underline;
}

.big-search-trending-search-container {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 1vw;
  padding-top: 0.8vh;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1vw;
}
.big-search-trending-search-heading-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.big-search-trending-search-heading-div svg {
  font-size: 1.25vw;
  color: black;
  margin-right: 0.4vw;
}
.big-search-trending-search-heading-span {
  font-size: 1vw;
  color: black;
  font-weight: 700;
}
.big-search-trending-search-trend-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0.4vw 0;
}
.big-search-trending-each-search-trend-div {
  width: auto;
  margin: 0.3vw 0.4vw;
  border-radius: 5px;
  border: 2px solid var(--color-green);
  font-size: 0.7vw;
  color: var(--color-green);
  padding: 0.3vw 0.8vw;
  cursor: pointer;
  transition: background 0.3s;
}
.big-search-trending-each-search-trend-div:hover {
  background: var(--color-green);
  color: white;
}
.big-search-recent-search-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 1vh;
}
.big-search-recent-search-heading-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.big-search-recent-search-left-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.big-search-recent-search-left-div svg {
  font-size: 1.25vw;
  color: black;
  margin-right: 0.4vw;
}
.big-search-recent-search-left-span {
  font-size: 1vw;
  color: black;
  font-weight: 700;
  white-space: nowrap;
}
.big-search-recent-search-right-clear-history {
  width: 5vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#big-search-recent-search-all-delete-icon {
  color: var(--color-peach);
  font-size: 1.2vw;
  cursor: pointer;
  transition: transform 0.5s;
}
#big-search-recent-search-all-delete-icon:hover {
  transform: scale(1.1);
}
.big-search--main-recent-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0.4vw 0;
}
.big-search-each-recent-search {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 0.3vw 0.6vw;
  margin: 0.2vw 1.2vw 0.4vw 0.4vw;
  cursor: pointer;
}
.big-search-each-recent-search:hover {
  background: rgba(0, 0, 0, 0.75);
}
.big-search-each-recent-search svg {
  font-size: 0.75vw;
  color: white;
}
.big-search-each-recent-search-span {
  font-size: 0.8vw;
  font-weight: 600;
  color: white;
  margin-right: 0.6vw;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .big-search-container {
    height: 350px;
    padding: 25px 10px 0 10px;
  }
  .big-search-top-vertical-container {
    width: 100%;
    padding-bottom: 10px;
    min-height: 25vh;
  }

  .big-search-top-vertical-each-img {
    width: 25px;
    height: 25px;
    margin-left: 2px;
    margin-right: 5px;
    padding: 3px;
  }
  .big-search-top-vertical-each-span {
    font-size: 13px;
  }
  .big-search-searched-items-div {
    margin: 5px 0;
  }
  .big-search-searched-each-item {
    margin: 2px 0;
    padding: 3px;
  }

  .big-search-searched-each-img {
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 4px;
  }
  .big-search-searched-each-span {
    font-size: 10px;
  }
  .big-search-top-vertical-brand-container {
    padding-top: 15px;
    gap: 10px;
  }

  .big-search-search-items-total-count {
    padding-left: 10px;
  }
  .big-search-search-items-total-count-span {
    font-size: 10px;
  }

  .big-search-search-items-total-count-span:hover {
    font-size: 11px;
  }

  .big-search-trending-search-container {
    display: none;
  }
}
