@media screen and (max-width: 500px) {
  .mobile-advantage-container {
    width: 100vw;
    height: auto;
    padding: 2vh 3vw;
  }
  .mobile-product-description-adv-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    padding: 0;
  }
  .mobile-product-description-adv-container h3 {
    color: black;
    margin: 0;
    margin-bottom: 1vh;
    font-size: 4.2vw;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .mobile-product-description-adv-main-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    gap: 2vw;
    justify-items: center;
    align-items: start;
  }
  .mobile-product-description-each-adv-div {
    display: flex;
    align-items: center;
    background: none;
    flex-direction: column;
    width: auto;
    height: auto;
    /* margin-right: 15px; */
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
  .mobile-product-description-each-adv-div img {
    /* background: #f4f2f2; */
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    margin-right: 0;
    margin-bottom: 0.4vh;
    border: 1px solid rgba(0,0,0,0.1);
  }
  .mobile-product-description-each-adv-div span {
    font-size: 3vw;
    text-align: center;
    color: rgba(0,0,0,0.8);
  }
}
