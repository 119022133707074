.about_containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 0.5vw 2vw 1.5vw 2vw; */
    color: black;
    text-transform: none;
  }
  .about_containers .container-Name {
    font-size: 1.8vw;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 5vh;
    text-align: center;
    color: black;
  }
  .about_containers .about_heading {
    color: #1f1e1e;
    font-size: 1.5vw;
    font-weight: bold;
    text-transform: capitalize;
    margin: 2vh 0;
  }
  .about_containers .about_details {
    margin-left: 5vw;
  }
  .about_containers .about_details .about_para {
    font-size: 1vw;
  }
  .about_containers .about_details .about_points ul {
    list-style-type: decimal;
  }
  .about_containers .about_details .about_points .about_features_heading {
    margin: 0;
    margin-left: 0.3vw;
  }
  .about_containers .about_details .about_points ul li {
    padding: 0.3vw 0;
  }
  
  @media screen and (max-width: 500px) {
    .about_containers {
      padding: 0 20px;
    }
    .about_containers .container-Name {
      text-transform: capitalize;
      font-size: 0.85rem;
      margin: 15px 0;
    }
    .about_containers .about_heading {
      margin: 0;
      font-size: 2rem;
      text-align: center;
      color: rgb(47, 46, 46);
    }
    .about_containers .about_details {
      font-size: 0.9rem;
      margin-left: 0;
    }
    .about_containers .about_details .about_para {
      font-size: 0.9rem;
      text-align: justify;
    }
  
    .about_containers .about_details .about_points .about_features_heading {
      font-size: 0.65rem;
    }
    .about_containers .about_details .about_points ul li {
      padding: 5px 0;
  
      font-size: 0.6rem;
      line-height: 12px;
      text-align: justify;
    }
  }
  