.mywishlist-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1vh 2vw;
  color: rgb(58, 56, 56);
  background: transparent;
}
.text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.5rem;
}
.text-container h3 {
  font-size: 1.4rem;
  text-transform: capitalize;
}

.mywishlist-total-no {
  margin-left: 10px;
  font-size: 0.9rem;
  color: rgb(137, 131, 131);
}
.mywishlist-remove-all {
  font-size: 13px;
  color: var(--color-peach);
  margin-left: 1vw;
  cursor: pointer;
  letter-spacing: 0.5px;
}
.mywishlist-remove-all:hover {
  text-decoration: underline;
}
.product-card-container {
  width: 90%;
  height: 100%;
  min-height: 70vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .mywishlist-container {
    padding: 10px 15px;
  }
}
