.dass-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 10px;
  }
  
  .dass-container h1 {
    text-align: center;
    color: #333;
  }
  
  .dass-brand-info p {
    margin-bottom: 15px;
    font-size: 15px;
  }
  
  .dass-key-products, .dass-manufacturing-capabilities, .dass-certifications-recognition {
    margin-bottom: 15px;
  }
  
  .dass-key-products h2, .dass-manufacturing-capabilities h2, .dass-certifications-recognition h2 {
    color: rgba(0,0,0,0.8);
    font-size: 16px;
    margin: 0;
  }
  .dass-manufacturing-capabilities p{
    font-size: 13.8px;
    margin-left: 20px;
  }
  .dass-key-products ul, .dass-certifications-recognition ul {
    list-style: disc;
  }
  
  .dass-key-products li, .dass-certifications-recognition li {
    font-size: 13.8px;
    line-height: 1.3;
  }
  