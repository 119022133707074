.mobile-view-order-container{
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 8vh;
}
.mobile-view-order-header{
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 3vw;
    margin: 0vh 0;
    background: white;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
}
.mobile-view-order-header-left{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.mobile-view-order-header-left svg{
    font-size: 6vw;
    color: rgba(0,0,0,0.8);
}
.mobile-view-order-header-left-order-id{
    font-size: 3.2vw;
    color: rgba(0,0,0,0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family:Space Grotesk, sans-serif;
}
.mobile-view-order-header-right{
    border-radius: 25px;
    border: 2px solid rgba(0,0,0,0.8);
    padding: 0.2vh 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-view-order-header-right svg{
    font-size: 3.5vw;
    margin-right: 1vw;
    color: rgba(0,0,0,0.8);
}
.mobile-view-order-header-right span{
    font-size: 3.5vw;
    color: rgba(0,0,0,0.8);
}
.mobile-view-order-date-container{
    width: 100%;
    padding: 1vh 3vw 0 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mobile-view-order-order-date{
    font-size: 3.5vw;
    color: rgba(0,0,0,0.8);
    font-weight: 600;
    white-space: nowrap;
}
.mobile-view-order-delivery-order-date{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mobile-view-order-delivery-order-date svg{
    font-size: 3.5vw;
    color: var(--color-green);
    margin-right: 1vw;
}
.mobile-view-order-delivery-order-date span{
    font-size: 3vw;
    color: var(--color-green);
}
.mobile-view-order-product-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.mobile-view-order-product-total-number{
    width: 100%;
    background: rgba(0,0,0,0.1);
    padding: 1vh 3vw;
    color: rgba(0,0,0,0.8);
    font-weight: 600;
    font-size: 4.2vw;
    margin: 1vh 0 0 0;
    /* font-family:Space Grotesk, sans-serif; */
}
.mobile-view-order-product-main-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.mobile-view-order-order-summary-container{
    width: 100%;
    padding: 2vh 3vw;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.mobile-view-order-order-summary-heading{
    font-size: 5vw;
    color: rgba(0,0,0,0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    padding: 1vh 0;
}
.mobile-view-order-order-summary-container hr{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.3);
}
.mobile-view-order-order-summary-each-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mobile-view-order-order-summary-each-container span{
    font-size: 3.8vw;
    color: rgba(0,0,0,0.8);
    font-weight: 600;
    margin: 1vh 0;
    font-family:Space Grotesk, sans-serif;
}

.mobile-view-order-order-summary-total-price-container{
    width: calc(100% + 6vw);
    margin-left: -3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5vh 3vw;
    background: white;
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
    border-top: 2px solid rgba(0,0,0,0.2);
}
.mobile-view-order-order-summary-total-price-container span{
    font-size: 4.5vw;
    color: black;
    font-weight: 700;
    font-family:Space Grotesk, sans-serif;
}
.mobile-view-order-payment-method-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2vh 3vw;
    background: white;

}
.mobile-view-order-payment-method-container span{
    font-size: 4vw;
    color: rgba(0,0,0,0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
}
.mobile-view-order-payment-method-container img{
    width: auto;
    height: 3vh;
}

.mobile-view-order-booking-address-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1vh 3vw;
    background: white;
}
.mobile-view-order-support-address-div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.mobile-view-order-support-address-heading{
    font-size: 4.5vw;
    color: rgba(0,0,0,0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 1vh 0;
}
.mobile-view-order-support-address-main{
    font-size: 3vw;
    color: rgba(0,0,0,0.7);
    font-weight: 600;
    letter-spacing: 0.3px;
}
.mobile-view-order-booking-address{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}
.mobile-view-order-booking-address-heading{
    font-size: 4.5vw;
    color: rgba(0,0,0,0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 1vh 0;
}
.mobile-view-order-booking-address-main{
    font-size: 3vw;
    color: rgba(0,0,0,0.7);
    font-weight: 600;
    letter-spacing: 0.3px;
    text-align: end;
}
.mobile-view-order-have-question-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh 3vw;
    background: white;
}
.mobile-view-order-have-question-main-container{
    width: 100%;
    height: auto;
    border: 2px solid rgba(0,0,0,0.3);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh 5vw;
}
.mobile-view-order-have-question-heading{
font-size: 4.5vw;
font-weight: 700;
letter-spacing: 0.5px;
color: var(--color-blue);
}
.mobile-view-order-have-question-supporting-text{
    font-size: 3.5vw;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: rgba(0,0,0,0.7);
    text-align: center;
    margin: 1vh 0;
}
.mobile-view-order-have-question-contact-support-span{
    font-size: 4.5vw;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: var(--color-blue);
}

.mobile-view-order-track-order-order-again-container{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5vh 3vw 1.5vh 3vw;
    background: white;
    box-shadow: 0 -5px 5px 0 rgba(0,0,0,0.1);
}






.mobile-track-order-btn{
    width: 48%;
    background: var(--color-blue);
    border-radius: 8px;
    padding: 1.2vh 2vw;
    color: white;
    text-decoration: none;
    font-size: 4.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-view-order-order-again{
    width: 48%;
    background: var(--color-green);
    border-radius: 8px;
    padding: 1.2vh 2vw;
    color: white;
    font-size: 4.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }