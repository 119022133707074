.mobile-product-all-info {
  display: none;
}

.active-tabs-compatility::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid var(--color-green);

}
.active-tabs-insights::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid var(--color-peach);

}

.active-tabs-how::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid var(--color-peach);

}

.active-tabs-reviews::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid var(--color-purple);

}

.active-tabs-technical::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid var(--color-blue);

}
.active-tabs-About-Brands::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid rgba(20, 91, 104, 1);

}

.active-tabs-compare::before{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
  border: 2px solid #3643ba;

}




