.wallet-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 3vh 2vw;
}
.wallet-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wallet-heading {
  font-size: 18px;
  color: black;
  font-weight: 600;
}
.wallet-input-search-container {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  padding: 0.4vh 0.8vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wallet-input-search-container svg {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.3vw;
}
.wallet-order-search {
  outline: none;
  border: none;
}

.wallet-cashback-purchase-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0;
}
.wallet-wallet-container {
  width: 100%;
  height: 100px;
  border-radius: 8px;
  background: linear-gradient(to right, var(--color-blue), #5a8af3);
  position: relative;
  padding: 15px;
}

.wallet-cashback-name {
  position: absolute;
  top: 38%;
  transform: translateY(-62%);
  left: 100px;
  font-size: 14px;
}

.wallet-cashback-price {
  position: absolute;
  top: 58%;
  transform: translateY(-42%);
  left: 100px;
  font-size: 20px;
}
.wallet-cashback-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 55px;
  height: auto;
}

.wallet-recent-transaction-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  justify-content: flex-start;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}
.wallet-recent-transaction-heading {
  font-size: 18px;
  color: black;
  font-weight: 600;
}
.wallet-recent-transaction-main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.wallet-recent-transaction-tabs-container {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
}
.wallet-recent-transaction-each-tabs {
  font-size: 13px;
  padding: 3px 10px;
  border-radius: 25px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.8);
  margin-right: 8px;
  cursor: pointer;
}
.wallet-recent-transaction-each-transaction-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  margin: 10px 0;
}
.wallet-recent-transaction-each-transaction-date {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
}
.wallet-recent-transaction-each-transaction-amount {
  position: absolute;
  right: 5px;
  top: 60%;
  transform: translateY(-40%);
  color: var(--color-green);
  font-size: 14px;
}
.wallet-recent-transaction-each-transaction-img {
  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 5px;
  margin-top: 10px;
}
.wallet-recent-transaction-each-transaction-details-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5px;
}
.wallet-recent-transaction-each-transaction-main-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}
.wallet-recent-transaction-details-head {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 700;
}
.wallet-recent-transaction-details-p {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  width: 18%;
  margin-top: 0.5vh;
}

.wallet-recent-transaction-not-available-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.wallet-recent-transaction-not-available-img {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 10px;
}

.wallet-recent-transaction-head {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  margin: 10px 0;
  font-weight: 600;
}
.wallet-recent-transaction-detail {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}

.wallet-information-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40px 0;
}
.wallet-information-detail-heading {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: black;
}

.wallet-tab-active {
  background: var(--color-green);
  color: white;
  padding: 4px 10px;
  border: none;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .wallet-container {
    padding: 15px;
  }

  .wallet-recent-transaction-details-p {
    width: 50%;
  }
}
