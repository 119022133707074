.change-email-container {
    position: fixed;
    top: 40%;
    left: 42%;
    width: 18vw;
    height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 9999;
    border-radius: 15px;
    background: white;
    padding: 1vw 1.5vw;
  }
  .change-email-heading {
    font-size: 1.15vw !important;
    color: black;
    font-weight: 800 !important;
    margin-bottom: 0.4vw !important;
  }
  .change-email-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .change-email-main-container input {
    width: 100%;
    outline: none;
    color: rgba(0, 0, 0, 0.6);
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    font-size: 0.95vw;
    font-weight: 600;
    padding: 0.3vw;
    margin: 0.3vw 0 0.5vw 0 !important;
  }
  .change-email-main-container input::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.8vw;
    font-weight: 600;
  }
  .change-email-label {
    font-size: 0.9vw;
    font-weight: 600;
    color: black;
    margin-bottom: 0.2vh !important;
  }
  .change-email-send-otp-btn {
    width: 100%;
    height: 4vh;
    color: white !important;
    background: #819ef5;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.2vw 0 !important;
  }
  .change-email-cancel-btn {
    width: 100%;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.2vw 0 !important;
    color: black;
    border: 2px solid rgba(0, 0, 0, 0.4);
    cursor: pointer;
    border-radius: 6px;
  }
  

  /* OTP COMPONENT */
.change-email-enter-otp-container {
    position: fixed;
    top: 35%;
    left: 35%;
    width: 25vw;
    height: 32vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
    border-radius: 15px;
    background: white;
    padding: 1vw 1.5vw;
  }
  .change-email-enter-otp-top-heading {
    font-size: 1.1vw !important;
    color: black;
    font-weight: 800 !important;
  }
  .change-email-enter-otp-next-heading {
    font-size: 0.95vw !important;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600 !important;
    text-align: center;
  }
  .change-email-enter-otp-email-number {
    font-size: 1vw !important;
    color: #819ef5 !important;
    font-weight: 600;
  }
  .change-email-enter-otp-email-number-otp-container {
    width: 100%;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .change-email-enter-otp-verify-btn {
    width: 100%;
    height: 5vh;
    background: #819ef5;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
  }
  .change-email-enter-otp-dont-receive-otp-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .change-email-enter-otp-dont-receive-text {
    font-size: 0.8vw !important;
    font-weight: 600;
    color: black;
  }
  .change-email-enter-otp-send-again-btn {
    font-size: 0.8vw;
    font-weight: 600;
    color: #819ef5 !important;
    margin-left: 0.4vw !important;
    cursor: pointer;
  }
  

  .change-email-otp-input-boxes {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.55vw auto;
  }
  /* Apply this style to the input element */
  .change-email-otp-input-boxes input {
    width: 2vw;
    height: 5vh;
    background: transparent;
    border-radius: 6px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    margin: 0 0.25vw;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    text-align: center;
    font-size: 1vw;
    outline: none;
    transition: border-color 0.3s; /* Add a transition for smoother border color change */
  }
  
  /* Add this CSS rule */
  .change-email-otp-input-boxes input.filled {
    border: 2.2px solid rgba(0, 0, 0, 0.5);
  }
  
  .change-email-otp-recovery-boxes [type="number"]::-webkit-inner-spin-button,
  .change-email-otp-recovery-boxes [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* For Firefox */
  .change-email-otp-recovery-boxes [type="number"] {
    -moz-appearance: textfield;
  }
  .form-container [type="number"]::-webkit-inner-spin-button,
  .form-container [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* For Firefox */
  .form-container [type="number"] {
    -moz-appearance: textfield;
  }
  .change-email-otp-input-boxes [type="number"]::-webkit-inner-spin-button,
  .change-email-otp-input-boxes [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* For Firefox */
  .change-email-otp-input-boxes [type="number"] {
    -moz-appearance: textfield;
  }
  /* .check-pincode-availability [type="number"]::-webkit-inner-spin-button,
  .check-pincode-availability [type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  } */
  
  /* For Firefox */
  /* .check-pincode-availability [type="number"] {
    -moz-appearance: textfield;
  } */
  