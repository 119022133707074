.AuthenticationPage-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* background:url('../../img/banners/login-banner.jpg'); */
  background-position: center;
  background-size: contain;
  /* margin-top: 20px; */
  position: relative;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.AuthenticationPage-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.AuthenticationPage-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.video-background video {
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  min-width: 100%;
  min-height: 100vh;
  background-size: contain;
}

.AuthenticationPage-left-container {
  width: 50%;
  height: 100%;
  padding: 1.1vw;
  overflow: auto;
}
.left-container-logo {
  width: 8vw;
  height: auto;
  padding: 0.9vh 1.1vw;
}

/* .AuthenticationPage-left-container img {
  padding: 10px 0px 0 30px;
  height: 80%;
  width: 95%;
} */

.login-promices-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.login-promices-containerss {
  margin: 0 auto;
  margin-top: 70%;
}

.login-promices {
  width: 26vw !important;
  height: 13vh;
  margin: 0.55vw;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.55vw;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: black;
  text-transform: none;
  font-size: 0.75vw;
  margin: auto;
  background: white;
}
.slick-dots li button:before {
  font-size: 0.75vw !important;
}
.slick-dots .slick-active button:before {
  font-size: 0.9vw !important;
}

.login-promices img {
  width: 20%;
  height: auto;
  margin-bottom: 0.55vw;
}
.login-promices-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1.1vw;
}
.login-promices-heading {
  font-size: 1vw;
  margin-bottom: 0.3vw;
  color: #000000;
}
.login-promices-desc {
  width: 100%;
  font-size: 0.8vw;
  text-align: start;
  color: #474545;
}

.AuthenticationPage-right-container {
  width: 25%;
  height: auto;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 20px;
  /* margin: 5vw 3vw; */
  align-self: flex-start;
  position: absolute;
  top: 18%;
  /* transform: translateY(-50%); */
  right: 3%;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .left-container-logo {
    width: 100px;
  }

  .AuthenticationPage-right-container {
    width: 50%;
    right: 50%;
    transform: translateX(50%);
  }
}

@media screen and (max-width: 500px) {
  .AuthenticationPage-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
  }

  .AuthenticationPage-left-container {
    display: none;
  }

  .AuthenticationPage-right-container {
    width: 100%;
    border-radius: 0;
  }
}
