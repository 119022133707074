.mobile-fav-share-container{
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile-favourite img{
    width: 6.5vw;
    height: auto;
    margin-right: 5vw;
}
.mobile-share svg{
    color: #800080;
}