.loginPage-container {
  width: 100%;
  height: 100%;
  padding: 1vw;
  color: white;
  text-align: center;
  position: relative;
}
.close-icon {
  position: absolute;
  top: 0.55vw;
  right: 0.55vw;
  cursor: pointer;
}

.loginPage-container > h2 {
  font-size: 1.5vw;
  margin: 0;
  margin-bottom: 0.55vw;
}
.login-upper-navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
}
.login-upper-login-tab {
  margin: 0 1vw;
  cursor: pointer;
  position: relative;
  padding: 0.3vh 0;
}
.login-upper-signup-tab {
  margin: 0 1vw;
  cursor: pointer;
  position: relative;
  padding: 0.3vh 0;
}
.login-tab-active {
  /* text-decoration: underline; */
  color: gold;
}
.login-tab-active::after {
  content: "";
  width: 100%;
  border: 1px solid gold;
  position: absolute;
  bottom: 0;
  left: 0;
}

.login-upper-nav-divider {
  width: 1vw;
  border: 1px solid white;
  transform: rotate(90deg);
  margin: 0;
}

.hr-separation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.1vh 0;
}
.hr-separation span {
  margin: 0 0.55vw;
}
.hr-separation hr {
  /* width: 50px; */
  margin: 0;
  flex-grow: 0.3;
  height: 1px;
  background-color: #ccc;
}
.form-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5vw;
  margin-top: 0.6vw;
}
.form-container form label {
  width: 100%;
  text-align: left;
  font-size: 0.95vw;
}
.form-container form input {
  width: 100%;
  height: 36px;
  border-radius: 4.13px;
  border: 0.82px solid var(--border-color);
  outline: none;
  margin: 0.35vw auto 0.35vw auto;
  padding: 0.55vw;
  background: transparent;
  color: white;
  font-size: 0.9vw;
  font-weight: 500;
}
.form-container form input::placeholder {
  color: rgb(204, 203, 203);
}
.phone-exist-or-not {
  font-size: 0.55vw;
  color: var(--color-gold);
  text-align: right;
  margin-top: -0.8vh;
  margin-right: 0.2vw;
  font-weight: 500;
  letter-spacing: 0.2px;
}
.form-container form button {
  width: 100%;
  height: 36px;
  background-color: #9e3dcc;
  color: #fff;
  padding: 0.55vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.45vw auto 0.65vw auto;
  margin-bottom: 0;
  font-size: 0.95vw;
  font-weight: 600;
}

.dont-have-an-account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5vh auto;
}
.dont-have-an-account p {
  font-size: 0.85vw;
  margin: 0;
  margin-right: 0.3vw;
}
.dont-have-an-account-sign-up {
  font-size: 0.95vw;
  color: #ffd700;
  text-decoration: none;
  cursor: pointer;
}
.signUp-separation {
  width: 80%;
  margin: 1.2vw auto;
}

.having-trouble-contact-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.trouble-text {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 0.9vw;
  margin-bottom: 0.65vw;
}
.phone-email-support-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1vh 0;
}
.phone-email-support-container hr {
  width: 2vh;
  transform: rotate(90deg);
  border: 1px solid white;
}
.phone-contact-div {
  display: flex;
  align-items: center;
  font-size: 0.85vw;
  padding-left: 0.2vw;
}
.phone-contact-div svg {
  margin-right: 0.3vw;
  color: white;
  font-size: 1.2vw;
}
.login-page-phone-contact {
  color: white;
}
.email-contact-div {
  display: flex;
  align-items: center;
  font-size: 0.85vw;
  padding-right: 0.2vw;
}
.email-contact-div svg {
  margin-right: 0.3vw;
  color: white;
  font-size: 1.2vw;
}
.login-page-email-contact {
  color: white;
}

.login-page-privacy-terms-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95vw;
  margin: 1.5vh 0;
}
.login-page-privacy-terms-container a {
  font-size: 0.8vw;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 0.35vw;
}

.otp-verification-container {
  width: 100%;
  height: 100%;
  margin-top: 1.5vh;
}
.otp-verification-container p {
  font-size: 0.8vw;
  margin: 0.3vw auto;
}
.otp-verification-container .sent-code {
  font-size: 1vw;
  margin: auto;
  margin-top: 0px;
}
.otp-verification-container span {
  font-size: 1vw;
  margin: 3px auto;
  margin-top: 0.3vw;
  color: #9e3dcc;
}
.otp-input-boxes {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.55vw auto;
}
/* Apply this style to the input element */
.otp-input-boxes input {
  width: 2.3vw;
  height: 46.7px;
  background: transparent;
  border-radius: 6px;
  border: 2px solid gray;
  margin: 0 8.7px;
  color: white;
  text-align: center;
  font-size: 1vw;
  outline: none;
  transition: border-color 0.3s; /* Add a transition for smoother border color change */
}

/* Add this CSS rule */
.otp-input-boxes input.filled {
  border: 3px solid white;
}
.otp-recovery-boxes input.filled {
  border: 3px solid white;
}
.otp-recovery-boxes [type="number"]::-webkit-inner-spin-button,
.otp-recovery-boxes [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.otp-recovery-boxes [type="number"] {
  -moz-appearance: textfield;
}
.form-container [type="number"]::-webkit-inner-spin-button,
.form-container [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.form-container [type="number"] {
  -moz-appearance: textfield;
}
.otp-input-boxes [type="number"]::-webkit-inner-spin-button,
.otp-input-boxes [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.otp-input-boxes [type="number"] {
  -moz-appearance: textfield;
}

.dont-receive-code {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.6vw auto;
  margin-bottom: 0;
}
.dont-receive-code p {
  margin: 0;
  font-size: 0.75vw;
  margin-right: 0.3vw;
}
.dont-receive-code a {
  font-size: 0.85vw;
  color: #9e3dcc;
}
.otp-verify-btn {
  width: 100%;
  height: 36px;
  background-color: #9e3dcc;
  color: #fff;
  padding: 0.55vw;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.65vw auto;
  font-size: 1.1vw;
}
.login-page-black-promises-container {
  width: 100%;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1vh 0 1vh 0;
  padding: 0.5vh 0;
  /* border-bottom: 1px solid white; */
}
.each-black-promises-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* border-right: 1px solid white; */
  padding: 0 0.5vw;
}

.each-black-promises-img {
  width: 2.1vw;
  height: auto;
  margin-bottom: 0.8vh;
}
.each-black-promises-top {
  width: 4.5vw;
  font-size: 0.72vw;
  color: white;
  font-size: 500;
}

.login-customer-page-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.login-customer-page-p {
  font-size: 13px;
  color: white;
  font-weight: 600;
}
.login-customer-search-pincode {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.login-customer-search-pincode input {
  width: 65%;
  height: 35px;
  padding: 8px;
  border-radius: 4px 0 0 4px;
  border: none;
  outline: none;
}
.login-customer-check-btn {
  width: 27.5%;
  height: 35px;
  padding: 8px;
  border-radius: 0 4px 4px 0;
  background: var(--color-green);
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.8px;
  border: none;
  outline: none;
  cursor: pointer;
}
@media screen and (min-width: 501px) and (max-width: 1000px) {
  .loginPage-container {
    padding: 15px;
  }
  .close-icon {
    top: 10px;
    right: 10px;
  }

  .loginPage-container > h2 {
    font-size: 20px;
  }

  .login-upper-login-tab {
    font-size: 15px;
  }
  .login-upper-signup-tab {
    font-size: 15px;
  }

  .login-upper-nav-divider {
    width: 10px;
  }

  .hr-separation {
    margin: 12px 0;
  }

  .form-container form {
    margin-top: 15px;
  }
  .form-container form label {
    font-size: 15px;
  }
  .form-container form input {
    font-size: 13px;
    margin: 5px auto 5px auto;
    padding: 5px 8px;
  }

  .form-container form button {
    font-size: 13px;
    margin: 6px auto 6px auto;
  }

  .signUp-separation {
    margin: 10px auto;
  }

  .trouble-text {
    font-size: 13px;
  }
  .phone-email-support-container {
    margin: 10px 0;
  }
  .phone-email-support-container hr {
    width: 20px;
  }
  .phone-contact-div {
    font-size: 12px;
    padding-left: 2px;
    white-space: nowrap;
  }
  .phone-contact-div svg {
    font-size: 20px;
  }

  .email-contact-div {
    font-size: 12px;
    padding-right: 2px;
    white-space: nowrap;
  }
  .email-contact-div svg {
    font-size: 20px;
  }

  .login-page-privacy-terms-container {
    font-size: 13px;
    margin: 12px 0;
  }
  .login-page-privacy-terms-container a {
    font-size: 12px;
    margin: 0 5px;
  }

  .otp-verification-container p {
    font-size: 13px;
  }
  .otp-verification-container .sent-code {
    font-size: 15px;
  }
  .otp-verification-container span {
    font-size: 15px;
    margin-top: 5px;
  }
  .otp-input-boxes {
    margin: 5px auto;
  }
  /* Apply this style to the input element */
  .otp-input-boxes input {
    width: 35px;
    font-size: 13px;
  }

  .dont-receive-code {
    margin: 10px auto;
  }
  .dont-receive-code p {
    font-size: 12px;
    margin-right: 5px;
  }
  .dont-receive-code a {
    font-size: 13px;
  }
  .otp-verify-btn {
    padding: 10px;
    font-size: 13px;
    margin: 8px auto;
  }
  .login-page-black-promises-container {
    margin: 10px 0 10px 0;
  }
  .each-black-promises-container {
    padding: 0 6px;
  }

  .each-black-promises-img {
    width: 30px;
    margin-bottom: 8px;
  }
  .each-black-promises-top {
    font-size: 12px;
    width: 70px;
  }
}

@media screen and (max-width: 500px) {
  .loginPage-container {
    border-radius: 0;
    padding: 20px;
  }
  .close-icon {
    top: 20px;
  }

  .loginPage-container h2 {
    font-size: 1rem;
    position: absolute;
    top: 5px;
    left: 25%;
    margin-bottom: 0;
  }

  .form-container {
    height: auto;
  }

  .loginPage-container form input {
    width: 100%;
    margin: 5px 0;
  }

  form button {
    width: 100%;
  }

  .forgotPassword-login-via-otp-container {
    width: 100%;
    margin: 0;
  }

  .dont-have-an-account {
    margin-top: 3vh;
  }

  .signUp-separation {
    width: 80%;
    margin-top: 20px;
  }

  .check-pincode-availability-cyclecircle-info p {
    width: 100%;
    font-size: 0.85rem;
  }
  .nearby-dealer-location {
    font-size: 0.85rem;
  }

  .check-pincode-availability input {
    width: 72%;
  }

  .check-pincode-availability button {
    width: 28%;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .otp-verification-container {
    height: auto;
  }
  .otp-verification-container p {
    font-size: 0.75rem;
  }
  .otp-verification-container .sent-code {
    font-size: 0.95rem;
  }
  .otp-verification-container span {
    font-size: 1rem;
  }
  .otp-input-boxes {
    width: 100%;
    padding: 0 20px;
  }

  .dont-receive-code {
    margin: 15px 0;
  }

  .otp-verify-btn {
    width: 100%;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
  }
}
