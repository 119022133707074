.profile-component-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    background: transparent;
    min-height: 50vh;
    height: auto;
    padding: 1vh;
  }
  
  .profile-content {
    flex: 1;
    width: 80%;
    margin-left: 20px;
  }
  