.mobile-basic-detail-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: 2vh 3vw;
}
.mobile-basic-fav-share-container {
  position: absolute;
  top: 2vh;
  right: 5vw;
}
.mobile-basic-detail-brands-logo-container {
  width: auto;
  height: auto;
}
.mobile-basic-details-brand-logo {
  width: auto;
  max-width: 30vw;
  height: 4vh;
  margin-bottom: 1vh;
  object-fit: contain;
}
.mobile-basic-details-brand-name {
  font-size: 4vw;
  font-style: italic;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.8);
}
.mobile-basic-details-product-name {
  width: 85%;
  font-size: 4.2vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 1.2;
}
.mobile-basic-details-product-ratings-container {
  width: auto;
  height: auto;
  position: absolute;
  right: 5vw;
  bottom: 2vh;
  display: flex;
  align-items: center;
  padding: 0.5vh 1vw;
  background: var(--color-green);
  border-radius: 4px;
}
.mobile-basic-details-product-ratings-container svg {
  font-size: 4vw;
  color: white;
}
.mobile-basic-details-product-ratings {
  font-size: 3.5vw;
  margin-right: 0.2vw;
}
