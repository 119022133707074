@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: white;
  position: relative;
  background-color: #f3f3f3;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
  box-sizing: border-box;
}

@layer utilities {
  .before-overlay {
    @apply absolute top-0 left-0 w-full h-full opacity-100 z-1;
    content: "";
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 1) 100%
    );
    transition: opacity 0.3s ease;
  }

  .before-overlay:hover::before {
    @apply opacity-100 bg-black-1;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
  .text-12 {
    @apply text-[12px] leading-normal;
  }
  .text-14 {
    @apply text-[14px] leading-normal;
  }
  .text-16 {
    @apply text-[16px] leading-normal;
  }
  .text-18 {
    @apply text-[18px] leading-normal;
  }
  .text-20 {
    @apply text-[20px] leading-normal;
  }
  .text-24 {
    @apply text-[24px] leading-normal;
  }
  .text-32 {
    @apply text-[32px] leading-normal;
  }
  .input-class {
    @apply text-16 placeholder:text-16 bg-black-1 rounded-[6px] placeholder:text-gray-1 border-none text-gray-1;
  }

  .mobile-what-navigation-active::before {
    @apply absolute bottom-0 left-0 w-full opacity-80 border-2 border-blue;
    content: "";
    border-radius: 50% 0 50% 0 / 150px 0 150px 0;
    transform: skewX(-45deg);
  }

  .mobile-recommend-navigation-active::before {
    @apply absolute bottom-0 left-0 w-full opacity-80 border-2 border-peach;
    content: "";
    border-radius: 50% 0 50% 0 / 150px 0 150px 0;
    transform: skewX(-45deg);
  }

  .mobile-hot-navigation-active::before {
    @apply absolute bottom-0 left-0 w-full opacity-80 border-2 border-green;
    content: "";
    border-radius: 50% 0 50% 0 / 150px 0 150px 0;
    transform: skewX(-45deg);
  }

  .auto-spinner {
    @apply rounded-[50%] w-[26px] h-[26px] inline-block align-middle mr-[8px];
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid white;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .input-radio {
    @apply mr-[8px] appearance-none w-5 h-5 border-2 border-[#6e6c6c] outline-none cursor-pointer rounded-sm;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .input-radio:checked {
    @apply bg-green;
    box-shadow: inset 0px 0px 0px 2px white;
    border: 2px solid var(--color-green);
  }

  .input-transition {
    transition: border-color 0.3s;
  }
  .inset-shadow {
    box-shadow: 0 0 0px 0.8vw white inset;
  }
  .inset-shadow-black {
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1) inset;
  }

  .mobile-step-item:not(:first-child)::before {
    @apply bg-gray-400 absolute w-[calc(100%-30px)]  h-[3px] right-[30px] top-[50%] transform -translate-x-1/2 -translate-y-1/2;
    content: "";
  }
  .mobile-complete:not(:first-child)::before,
  .mobile-active:not(:first-child)::before {
    background-color: var(--color-blue);
    color: var(--color-blue);
  }

  .mobile-active .mobile-step,
  .mobile-complete .mobile-step {
    color: var(--color-blue);
  }

  .mobile-complete svg {
    color: var(--color-blue);
  }

  /* swipper */
  .ListItem {
    width: 100%;
    height: 100%;
  }

  .BouncingListItem {
    @apply w-full;
    transition: transform 0.5s ease-out;
  }

  /* Brand Carousel */
  .video-carousel_container {
    @apply relative sm:w-[60vw] w-[65vw] md:h-[60vh] sm:h-[40vh] h-[30vh]
  }

  .control-btn {
    @apply ml-4 p-4 rounded-full bg-gray-300 backdrop-blur flex-center
  }

  .common-padding {
    @apply sm:py-16 py-10 sm:px-10 px-5
  }

  .section-heading {
    @apply text-gray lg:text-5xl md:text-4xl text-2xl lg:mb-0 mb-3 font-medium opacity-0 translate-y-20
  }

  .feature-text {
    @apply text-gray max-w-md text-lg md:text-xl font-semibold opacity-0 translate-y-[100px]
  }

  .feature-text-container {
    @apply w-full flex-center flex-col md:flex-row mt-10 md:mt-16 gap-5
  }

  .feature-video {
    @apply w-full h-full object-cover object-center scale-150 opacity-0
  }

  .feature-video-container {
    @apply w-full flex flex-col md:flex-row gap-5 items-center
  }

  .link {
    @apply text-blue hover:underline cursor-pointer flex items-center text-xl opacity-0 translate-y-20
  }


  .step-item:not(:first-child)::before {
    content: "";
    background-color: #cbd5e0;
    position: absolute;
    width: 100%;
    height: 4px;
    right: 50%;
    top: 28%;
    transform: translateY(-50%);
  }

  .complete:not(:first-child)::before,
  .active:not(:first-child)::before {
    background-color: var(--color-green) !important;
  }

  .red-line-window {
    color: var(--color-peach) !important;
    font-weight: 700;
    font-size: 12px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    animation: pulse 2s infinite ease-in-out;
    position: absolute;
    top: 10%;
    text-align: center;
  }

  /* Desktop */

  .scroll-promices-container .slick-slider {
    width: 70%;
    height: 100%;
    display: flex !important;
    justify-content: center !important;
    position: static;
    z-index: 10;
  }

  .slick-active div {
    display: flex !important;
    justify-content: center !important;
  }

  .shop-by-categories-hover:hover img {
    transform: scale(1.05);
  }
  .shop-by-categories-hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 1) 100%
    );
    transition: opacity 0.3s ease;
    opacity: 0.7;
    z-index: 1;
  }

  .shop-by-categories-hover:hover::before {
    background: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

:root {
  --main-background-color: #e3e6e6;
  --page-background-color: white;
  --border-color: #cacaca;
  --color-blue: #283248;
  --color-blue-hover: #181f30;
  --color-green: #28a447;
  --color-bike: #88058a;
  --color-green-hover: rgb(46, 149, 113);
  --color-peach-hover: #cd1a3b;
  --color-black: rgba(0, 0, 0, 1);
  --color-dark-gray: rgba(89, 87, 87, 1);
  --color-gold: gold;
  --color-gray: rgba(105, 105, 105, 1);
  --color-peach: #ba3737;
  --color-primary: rgba(40, 50, 72, 1);
  --color-purple: #800080;
  --color-purple-dark: rgba(114, 3, 114, 1);
  --color-purple-light: rgba(158, 61, 204, 1);
  --color-red: rgba(253, 0, 37, 1);
  --color-tertiary: rgba(186, 55, 55, 1);
  --color-white: rgba(255, 255, 255, 1);
  --gray-hover: rgba(237, 235, 235, 1);
}


::-webkit-scrollbar {
  width: 12px; 
}


::-webkit-scrollbar-track {
  background: #f3f3f3; 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background-color: #283248;
  border-radius: 10px;
  border: 2px solid #f3f3f3;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #1e2835; 
}

.mobile-brand-scroll::-webkit-scrollbar {
  display: none; 
}

.mobile-ctg-v-1::-webkit-scrollbar {
  display: none; 
}
.brand-scroll-v1::-webkit-scrollbar {
  display: none; 
}

.collection-scrollbar {
  overflow: hidden; /* Ensures no scrollbars are visible */
}

.collection-scrollbar::-webkit-scrollbar {
  display: none;
}



ul {
  list-style: none;
}
a {
  text-decoration: none;
}

#pre-loader {
  background: #ffffff url(img/pre-loader.gif) no-repeat center center;
  background-size: 60px;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1000;
  /* background-color: #fac2ea */
}


.screen-max-width {
  margin-inline-start: auto;
  margin-inline-end: auto;
  position: relative;
  max-width: 100%;
}

