.shipping_policy_container {
    width: 100%;
    min-height: 60vh;
    padding: 20px;
    color: black;
    background: white;
    padding-right: 50px;
    @media screen and (max-width: 500px) {
      padding-right: 20px;
      text-align: justify;
      padding-top: 50px;
    }
  }
  .shipping_policy_container h2{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 2px;
    background: linear-gradient(#1d2536, #29395c, #3f5279);
    padding: 10px;
    color: white;
    border-radius: 0 0 50px 0;
    text-align: start;
  }
  .shipping_policy_container h3{
      font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
    /* background: linear-gradient(#f2f2f2,#ede8e8) ; */
    padding: 10px;
  }
  .shipping-pol{
    background: linear-gradient(#f2f2f2,#ede8e8);
  }
  .shipping_policy_container p{
      margin: 20px 0;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    font-weight: 500;
    padding-left: 10px;
  }