.nearby-dealers-store-container-overlay{
    width: 1000px;
    height: 600px;
    background: white;
    border-radius: 10px;
    z-index: 1001;
    position: fixed;
    top: 54%;
    left: 50%;
    transform: translate(-50%,-46%);
}
.nearby-overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.6);
    z-index: 1000;
}

@media screen and (min-width: 501px) and (max-width: 1100px) {
    .nearby-dealers-store-container-overlay{
        width: 700px;
        height: 500px;
        background: white;
        border-radius: 10px;
        z-index: 1001;
        position: fixed;
        top: 54%;
        left: 50%;
        transform: translate(-50%,-46%);
    }
}

@media screen and (max-width:500px) {
    .nearby-dealers-store-container-overlay{
        width: 100%;
        height: 100vh;
        background: white;
        border-radius: 0;
        z-index: 1001;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%,-0vh);
    }
}