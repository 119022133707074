@media (max-width: 500px) {
  .mobile-phone-and-email-change-container {
    width: 94vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1vh;
  }
  .mobile-phone-change-main-container {
    width: 48%;
    height: 20vh;
    background: var(--color-blue);
    color: white;
    padding: 2vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-a-phone-change-img-address {
    width: 5vh;
    height: 5vh;
  }
  .mobile-phone-change-name {
    font-size: 3.8vw;
    color: white;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-phone-change-phone-number {
    font-size: 3vw;
    color: white;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-phone-change-btn {
    width: 100%;
    background: white;
    border-radius: 4px;
    padding: 1vh 0;
    font-size: 3vw;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    font-weight: 700;
  }
  .mobile-email-change-main-container {
    width: 48%;
    height: 20vh;
    background: var(--color-blue);
    color: white;
    padding: 2vh;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-a-email-change-img-address {
    width: 5vh;
    height: 5vh;
  }
  .mobile-email-change-name {
    font-size: 3.8vw;
    color: white;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-email-change-email-address {
    font-size: 3vw;
    color: white;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0.5vh 0;
  }
  .mobile-email-change-btn {
    width: 100%;
    background: white;
    border-radius: 4px;
    padding: 1vh 0;
    font-size: 3vw;
    font-weight: 700;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
  }
}
