.policy-container {
  width: 100%;
  padding: 20px;
  color: black;
  background: white;
  padding-right: 50px;
  @media screen and (max-width: 500px) {
    padding-right: 20px;
    text-align: justify;
    padding-top: 10px;
  }
}
.policy-container h2 {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  background: linear-gradient(#1d2536, #29395c, #3f5279);
  padding: 10px;
  color: white;
  border-radius: 0 0 50px 0;
  text-align: start;
}
.policy-container p {
  margin: 20px 0;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-left: 10px;
}
.policy-cookies-container {
  width: 100%;
}
.policy-cookies-container h3 {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  background: linear-gradient(#f2f2f2,#ede8e8) ;
    padding: 10px;
}
.policy-cookies-container h4 {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 2px;
  margin: 10px 0;
  padding-left: 10px;
}
.policy-cookies-container p{
    margin: 20px 0;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-left: 10px;
}

.policy-automatic-logging{
    width: 100%;
}
.policy-automatic-logging h3{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 2px;
    background: linear-gradient(#f2f2f2,#ede8e8) ;
    padding: 10px;
}
.policy-automatic-logging p{
    margin: 20px 0;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding-left: 10px;
}
.policy-gray{
    background: #f2f2f2;
    padding: 10px;
}
.policy-container ul{
    list-style-type:disc;
}
.policy-container ul li{
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.policy-container a{
    text-decoration: underline;
    color: blue;
    font-weight: 600;
}