.no-product-item-container {
  width: 100%;
  height: 100%;
  /* padding: 5vh 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-product-item-div {
  width: auto;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 100px; */
  /* margin-right: 70px; */
}
.no-product-item-div img {
  width: 300px;
  height: auto;
  /* border-radius: 50%; */
}
.no-product-item-div h3 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: black;
}
.sorry-mate-product {
  color: rgb(82, 81, 81);
  font-size: 13px;
  font-weight: 600;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .no-product-item-div img {
    width: 200px;
  }
}

@media screen and (max-width: 500px) {
  .no-product-item-container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 5;
  }
  .no-product-item-div {
    margin-right: 0;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .no-product-item-div img {
    width: 100px;
  }
  .no-product-item-div h3 {
    font-size: 1rem;
  }
  .sorry-mate-product {
    font-size: 0.85rem;
  }
}
