.reviewTypesStar-ratings {
  height: 30px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviewTypesStar-ratings .reviewTypesStar-rating {
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviewTypesStar-ratings .reviewTypesStar-starIcon {
  height: 15px;
  width: 15px;
  margin: 0 6px;
  cursor: pointer;
}
.reviewTypesStar-ratings .reviewTypesStar-starIcon svg {
  color: rgb(250, 198, 42);
  font-size: 30px;
}
.reviewTypesStar-total-star-count {
  color: black;
  margin: 12px 0 0 8px;
  font-size: 1rem;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .reviewTypesStar-ratings {
    height: 25px;
    width: 100px;
  }

  .reviewTypesStar-ratings .reviewTypesStar-starIcon {
    margin: 0 3px;
  }
  .reviewTypesStar-ratings .reviewTypesStar-starIcon svg {
    font-size: 20px;
  }
  .reviewTypesStar-total-star-count {
    font-size: 13px;
  }
}

@media screen and (max-width: 500px) {
  .reviewTypesStar-ratings {
    width: 50%;
    height: 100%;
    justify-content: flex-end;
  }

  .reviewTypesStar-ratings .reviewTypesStar-starIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 2px;
  }

  .reviewTypesStar-total-star-count {
    display: none;
    margin-top: 20px;
    margin-left: 5px;
  }
}
