.profile-add-new-address-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  background: white;
  color: black;
  border-radius: 15px;
  padding-top: 35px;
}
.profile-add-new-address-container h3 {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0;
}

.my-profile-add-address-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.my-profile-add-address-heading {
  padding-top: 5px;
}

.my-profile-basic-address-basic-detail-form-1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 !important;
}
.my-profile-basic-address-basic-detail-level-1 {
  width: 100%;
  height: 56%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.my-profile-basic-address-basic-detail-level-1 fieldset {
  margin: 1vh 0;
}

.my-profile-save-address-as {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.5vw;
}
.my-profile-save-address-as-h3 {
  color: var(--color-blue);
  font-weight: 700;
  font-size: 1.1vw;
  text-align: start;
  margin: 0;
}

.my-profile-save-address-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5vw;
}
.my-profile-save-address-option span {
  border-radius: 5px;
  padding: 0.25vw 0.3vw;
  margin-right: 1vw;
  cursor: pointer;
}
.my-profile-save-address-option .type-active {
  background: var(--color-blue);
  color: white;
}

.my-profile-dealership-detail-form-2-default-address {
  width: calc(50% - 1.1vw);
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 500px) {
    width: 100%;
  }
}
.my-profile-dealership-detail-default-address-div {
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    margin: 5px 0;
  }
}
.my-profile-dealership-detail-form-2-default-address fieldset {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  border: none;
  padding-right: 3vw;
  padding-top: 0.55vw;
}

.my-profile-dealership-detail-form-2-default-address fieldset input {
  border: 1px solid black;
  width: 18px;
  height: 18px;
  margin-left: 0;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.my-profile-address-submit-btn {
  width: auto;
  height: 30px;
  padding: 3px 15px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  background: var(--color-blue);
  border-radius: 5px;
  position: absolute;
  bottom: 12px;
  right: 18%;
}
.my-profile-address-cancel-btn {
  width: auto;
  height: 30px;
  border: 2px solid var(--color-blue);
  border-radius: 5px;
  background: white;
  color: black;
  font-size: 15px;
  padding: 3px 15px;
  position: absolute;
  bottom: 12px;
  right: 1%;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .my-profile-address-submit-btn {
    right: 22%;
  }
}
