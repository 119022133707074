
.user-agreement-container {
    width: 100%;
    padding: 20px;
    color: black;
    background: white;
    padding-right: 50px;
    @media screen and (max-width: 500px) {
      padding-right: 20px;
      text-align: justify;
      padding-top: 50px;
    }
  }
  .user-agreement-container h2 {
    font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 2px;
  background: linear-gradient(#1d2536, #29395c, #3f5279);
  padding: 10px;
  color: white;
  border-radius: 0 0 50px 0;
  text-align: start;

  }
  .user-agreement-container p {
    margin: 20px 0;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
    font-weight: 500;
    padding-left: 10px;
  }
  .user-agreement-container ul{
    list-style-type:disc;
}
.user-agreement-container ul li{
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.5px;
}
.user-agreement-container a{
    text-decoration: underline;
    color: blue;
    font-weight: 600;
}
.user-agrement-notice-infringement{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 100px auto;
}
.user-agrement-notice-infringement div{
    width: 100%;
    text-align: center;
    @media screen and (max-width: 500px) {
      text-align: justify;
    }
}
.user-agrement-notice-infringement ol li{
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 8px 0;
}
.user-agrement-notice-infringement ul{
list-style-type: none;
margin: 0;
}
.user-agrement-notice-infringement ul li{
margin: 30px 0;
color: black;
font-weight: 700;
}

/* CSS for the table */
.user-agrement-notice-infringement table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  /* Styling for table header cells */
  .user-agrement-notice-infringement th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
    padding: 8px;
  }
  
  /* Styling for table body cells */
  .user-agrement-notice-infringement td {
    border: 1px solid #ddd;
    padding: 15px;
  }
  
  /* Alternating row colors for better readability */
  .user-agrement-notice-infringement tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* Hover effect on rows */
  .user-agrement-notice-infringement tr:hover {
    background-color: #e2e2e2;
  }
  .user-agrement-notice-infringement h4{
    font-size: 1.05rem;
    margin: 15px 0 10px 0;
  }
  .user-agrement-notice-infringement p{
    margin: 5px 0;
  }
  