.funn-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 10px;
}

.legacy,
.innovation,
.performance,
.riders,
.style {
  margin-top: 10px;
}

.funn-container > h2 {
    font-size: 18px;
}

.funn-container h3 {
  color: rgba(0,0,0,0.8);
    font-size: 16px;
    margin: 0;
}

.funn-container p {
  color: #777;
  margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.3;
}

.riders ul {
  list-style: disc;
}
.riders ul li {
  color: #777;
    font-size: 13.8px;
    line-height: 1.3;
}
