.write-review-container {
  background-color: #ffffff;
  border-radius: 20px;
  height: 535px;
  position: fixed;
  width: 850px;
  left: 50%;
  transform: translateX(-50%);
  top: 15%;
  z-index: 9999;
  text-transform: none;
}

.write-review-container .div {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  left: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
}

.write-review-container .text-wrapper-2 {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  left: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 390px;
  white-space: nowrap;
}
.write-review-container .text-wrapper-2 span {
  color: gray;
  margin-left: 10px;
  font-size: 1rem;
}

.write-review-container .p {
  color: darkgray;
  font-size: 14px;
  font-weight: 600;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 108px;
}

.write-review-container .octicon-x {
  height: 20px;
  left: 800px;
  position: absolute;
  top: 25px;
  width: 20px;
  cursor: pointer;
}

.write-review-container .overlap {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--border-color);
  border-radius: 20px;
  height: 181px;
  left: 27px;
  position: absolute;
  top: 190px;
  width: 770px;
}
.write-review-container .overlap textarea {
  color: rgb(58, 57, 57);
  /* font-family: Inter, "san-sarif"; */
}

.write-review-container .text-wrapper-3 {
  color: darkgray;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  max-width: 730px;
  max-height: 155px;
  min-width: 730px;
  min-height: 155px;
  width: 730px;
  height: 155px;
  outline: none;
  border: none;
  margin: 15px 20px;
}

.write-review-container .policy {
  height: 24px;
  left: 26px;
  position: absolute;
  top: 425px;
  width: 95%;
  display: flex;
  align-items: center;
}

.write-review-container .text-wrapper-4 {
  color: darkgray;
  font-size: 14px;
  font-weight: 600;
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
}

.write-review-container .gridicons-globe {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.write-review-container .publish {
  height: 47px;
  left: 32px;
  position: absolute;
  top: 465px;
  width: 119px;
}

.write-review-container .overlap-group {
  background-color: purple;
  border-radius: 10px;
  height: 47px;
  position: relative;
  width: 117px;
  cursor: pointer;
}

.write-review-container .text-wrapper-5 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  left: 29px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.write-review-container .cancel {
  height: 47px;
  left: 168px;
  position: absolute;
  top: 465px;
  width: 119px;
  cursor: pointer;
}

.write-review-container .div-wrapper {
  border: 1px solid;
  border-color: darkgray;
  border-radius: 10px;
  height: 47px;
  left: -1px;
  position: relative;
  top: -1px;
}

.write-review-container .text-wrapper-6 {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.write-review-container .ratings-instance {
  left: 19px !important;
  position: absolute !important;
  top: 151px !important;
}

.ratings-types-container {
  width: 46%;
  position: relative;
  top: 0px;
  left: 20px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.ratings-types-div {
  width: 100%;
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.ratings-types-img-div {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ratings-types-img-div img {
}
.ratings-types-img-div span {
  width: 100%;
  color: black;
  font-size: 0.9rem;
  text-align: start;
  margin-left: 8px;
}
.publish-privacy {
  position: relative;
  width: 220px;
  height: 50px;
  color: black;
  top: 380px;
  left: 570px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.publish-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.publish-privacy input {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 5px;
}

.publish-privacy label {
  font-size: 1rem;
  margin-top: 2px;
}

.user-avg-review {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #ffd700;
  color: black;
  top: 80px;
  right: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 1.8rem;
  font-weight: 600;
}

.mobile-write-review-container {
  display: none;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .write-review-container {
    width: 600px;
    height: 450px;
  }

  .write-review-container .div {
    top: 15px;
  }

  .write-review-container .text-wrapper-2 {
    top: 320px;
  }

  .write-review-container .octicon-x {
    top: 15px;
    left: 560px;
    width: 15px;
    height: 15px;
  }

  .write-review-container .overlap {
    width: 545px;
    top: 160px;
    height: 150px;
  }

  .write-review-container .text-wrapper-3 {
    min-width: 500px;
    min-height: 120px;
    max-height: 120px;
    max-width: 500px;
    width: 500px;
    height: 120px;
    margin: 10px 15px;
  }

  .write-review-container .policy {
    top: 350px;
    height: 20px;
  }

  .write-review-container .text-wrapper-4 {
    left: 30px;
  }

  .write-review-container .gridicons-globe {
    width: 20px;
    height: 20px;
  }

  .write-review-container .publish {
    width: 100px;
    height: 40px;
    top: 385px;
  }

  .write-review-container .overlap-group {
    width: 98px;
    height: 40px;
  }

  .write-review-container .text-wrapper-5 {
    left: 22px;
    top: 12px;
    font-size: 14px;
  }

  .write-review-container .cancel {
    width: 100px;
    height: 40px;
    top: 385px;
    left: 150px;
  }

  .write-review-container .div-wrapper {
    height: 40px;
  }

  .write-review-container .text-wrapper-6 {
    top: 12px;
    left: 24px;
    font-size: 14px;
  }

  .ratings-types-container {
    width: 45%;
  }

  .ratings-types-img-div img {
    width: 25px;
    height: 25px;
  }
  .ratings-types-img-div span {
    font-size: 12px;
  }
  .publish-privacy {
    top: 305px;
    left: 350px;
  }

  .user-avg-review {
    width: 70px;
    height: 70px;
    top: 70px;
    right: 100px;
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .write-review-container {
    display: none;
  }

  .mobile-write-review-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    background: white;
    color: black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 9999;
  }
  .mobile-write-review-header-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-bottom: 2px solid rgb(244, 240, 240);
    padding: 2vh 3vw;
  }
  .mobile-write-review-back-btn {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.95rem;
  }
  .mobile-write-review-back-btn svg {
    font-size: 1.2rem;
    margin-right: 4px;
  }
  .mobile-write-review-name {
    position: absolute;
    right: 35%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
  }

  .mobile-total-review-number {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2vh 3vw;
  }
  .mobile-total-review-number h4 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gold);
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 2rem;
    margin: 0;
  }

  .mobile-ratings-types-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobile-ratings-types-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5vh 3vw;
  }
  .mobile-ratings-types-img-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-ratings-types-img-div img {
    margin-right: 5px;
    width: 1.5rem;
    height: 1.5rem;
  }
  .mobile-text-area-div {
    width: 100%;
    height: auto;
    padding: 2vh 3vw;
    border-radius: 20px;
    padding-bottom: 10px;
  }
  .mobile-text-wrapper-3 {
    width: 100%;
    height: 200px;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid var(--border-color);
    outline: none;
  }

  .mobile-text-wrapper-2 {
    height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
    padding: 0 3vw;
  }
  .mobile-text-wrapper-2 span {
    height: 100%;
    color: gray;
    margin-left: 10px;
    font-size: 1rem;
    margin-left: 5px;
  }

  .mobile-policy {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1vh 3vw;
    padding-top: 10px;
  }
  .mobile-text-wrapper-4 {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
  }
  .mobile-gridicons-globe {
    margin-right: 5px;
  }
  .mobile-publish-privacy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 3vw;
  }
  .mobile-publish-container-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 10px;
  }

  .mobile-publish {
    width: 100%;
    padding: 2vh 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-overlap-group {
    width: 100%;
    height: 100%;
    padding: 15px;
    background: var(--color-purple);
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-text-wrapper-5 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .mobile-cancel {
    width: 100%;
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-div-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-text-wrapper-6 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
