.popular-search-container {
  width: 100%;
  height: auto;
  background: white;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;
}

.popular-search-upper-div {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.popular-search-upper-div h3 {
  color: black;
  font-weight: 800;
  margin: 10px 0;
  margin-left: 8px;
  font-size: 1rem;
}
.popular-search-upper-main-div {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 0.7vw;
}
.popular-search-upper-main-div span {
  margin: 0 8px;
  font-size: 0.8rem;
  color: rgb(85, 84, 84);
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5;
}

.popular-search-upper-main-div span:hover {
  text-decoration: underline;
}

.popular-search-bottom-div {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(45%, 1fr)
  ); /* Two columns with equal width */
  gap: 10px; /* Adjust the gap between grid items as needed */
  margin: 20px;
  margin-left: 0;
  font-size: 0.65rem;
}
.bottom-upper {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.bottom-upper h3 {
  color: black;
  font-weight: 800;
  margin: 10px 0;
  margin-left: 8px;
  font-size: 0.85rem;
}
.inner-main {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.inner-main span {
  margin: 0 8px;
  font-size: 0.8rem;
  color: rgb(85, 84, 84);
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  line-height: 1.5;
}

.inner-main span:hover {
  text-decoration: underline;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .popular-search-container {
    padding: 20px;
  }

  .popular-search-upper-main-div {
    font-size: 10px;
  }

  .popular-search-bottom-div {
    margin: 10px 20px;
    margin-left: 0;
  }
}

@media (max-width: 500px) {
  .popular-search-container {
    padding: 3.5vw;
    font-size: 2.5vw;
    background: #374562;
    color: white;
  }
  .popular-search-upper-main-div {
    width: 100%;
    height: auto;
    display: flex;
    align-items: first baseline;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
  }
  .popular-search-upper-div h3 {
    margin-bottom: 0.8vw;
    margin-left: 0.6vw;
    font-size: 4.2vw;
    color: white;
  }
  .popular-search-upper-main-div span {
    margin: 0 1.5vw;
    font-size: 3.2vw;
    color: white;
    cursor: pointer;
    font-weight: 600;
  }

  .popular-search-bottom-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2.5vw;
    margin-left: 0;
    font-size: 0.65rem;
    color: white;
  }
  .bottom-upper {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }
  .bottom-upper h3 {
    color: black;
    font-weight: 800;
    margin: 0.8vw 0;
    margin-left: 4px;
    font-size: 3.5vw;
    color: var(--color-gold);
  }
  .inner-main {
    width: 100%;
    height: auto;
    display: flex;
    align-items: first baseline;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
  }
  .inner-main span {
    margin: 0 1.5vw;
    font-size: 3.2vw;
    color: white;
    cursor: pointer;
    font-weight: 600;
  }
}
