.moon-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 10px;
}

.moon-container h2 {
  font-size: 18px;
}

.moon-container h3 {
  color: #555;
  font-size: 15px;
  margin: 0;
  margin-top: 5px;
}

.moon-container p {
  color: #777;
  line-height: 1.3;
  font-size: 13.8px;
  margin: 10px 0;
  margin-left: 0;
}

.innovation,
.design,
.safety,
.versatility,
.sustainability {
}
