.insight-container {
  padding: 20px;
  color: var(--color-blue) !important;
}
.insight-container h2{
    font-size: 16px;
    font-weight: 700;
    color: var(--color-blue);
    margin-bottom: 2vh;
}
.insight-product-ranking-container {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.insight-ranking-in-categories{
    font-size: 14px;
    font-weight: 500;
    color: var(--color-blue);
    margin: 0.5vh 0;
}
.insight-ranking-in-categories b{
    margin-left: 2px;
    color: var(--color-peach);
}
.chart-container {
  margin-top: 20px;
}
.insight-graph-container {
  width: 50vw;
  height: auto;
  @media screen and (min-width: 501px) and (max-width: 900px) {
    width: 100%;
  }
}
