@media (max-width: 500px) {
    .mobile-via-phone-verification-via-sms-container{
        width: calc(94vw);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
    }
    .mobile-back-a-arrow{
        width: 2vh;
        height: auto;
        margin-bottom: 1vh;
    }
    .mobile-via-phone-heading{
        font-size: 4.5vw;
        color: rgba(0,0,0,0.8);
        font-weight: 700;
        letter-spacing: 0.5px;
        margin: 0.5vh 0;
    }
    .mobile-via-phone-otp{
        font-size: 3.8vw;
        color: rgba(0,0,0,0.8);
        font-weight: 600;
        letter-spacing: 0.5px;
        margin: 0.5vh 0;
    }
    .mobile-via-phone-below{
        font-size: 3.8vw;
        color: rgba(0,0,0,0.8);
        font-weight: 600;
        letter-spacing: 0.5px;
        margin: 0.5vh 0;
    }
    .mobile-via-phone-vefification-via-sms-input-div{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(0,0,0,0.1);
        border-radius: 4px;
        padding: 1vh 2vw;
        margin: 0.5vh 0;
    }
    .mobile-via-phone-vefification-via-sms-input-div input{
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        text-align: center;
        font-size: 5vw;
        border: none;
        background: transparent;
    }
    .mobile-via-phone-otp-divider{
        font-size: 4vw;
        color: rgba(0,0,0,0.8);
    }
    .mobile-via-phone-verify-otp-btn{
        width: 100%;
        padding: 1.2vh 2vw;
        background: var(--color-blue);
        color: white;
        font-size: 4.5vw;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-align: center;
        border-radius: 4px;
        margin: 1vh 0;
    }
}

