.filter-component-container {
  width: 100%;
  position: absolute;
  width: 560px;
  height: 425px;
  background: white;
  color: black;
  border: 1.5px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1001;
  padding-bottom: 70px;
  display: none;
}
.filter-name-back-btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.1rem;
}
.filter-name-back-btn svg {
  font-size: 1.7rem;
}
.filter-name-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}
.filter-cross-icon {
  background: rgb(172, 169, 169);
  color: white;
  border-radius: 50px;
  font-size: 1.6rem;
  padding: 3px;
  cursor: pointer;
}
.filter-main-container {
  width: 100%;
  height: 350px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
}
.filter-categories-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #f3eeee;
}
.filter-categories-container span {
  width: 100%;
  text-align: center;
  padding: 1.3rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  white-space: nowrap;
}
.sselected {
  background: white;
}

.mobile-filter-clearall {
  color: gray;
  margin-right: 10px;
}

.filter-search-sub-categories-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.filter-search-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-search-container input {
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 33px;
  padding-left: 30px;
  margin: 0 20px 10px 0;
  border: 1.6px solid var(--border-color);
}
.filter-search-icon {
  position: absolute;
  top: 66px;
  left: 170px;
  color: rgb(101, 98, 98);
  cursor: pointer;
}
.filter-subcategories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filter-subcategories-item {
  width: 33%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem;
}
.filter-subcategories-item input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;
}
.filter-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3vw;
  position: absolute;
  bottom: 10vh;
}
.filter-btn-clearall {
  background: rgb(233, 14, 14);
  margin-right: 16px;
}
.filter-btn-clearall:hover {
  background: rgb(220, 14, 14);
}
.filter-btn-apply {
  width: 100%;
  height: 45px;
  background: var(--color-blue);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: white;
  border-radius: 10px;
  border: none;
}
.filter-btn-apply:hover {
  background: var(--color-green);
}
input[type="checkbox"] {
  accent-color: var(--color-green);
}

.filter-categories-option-container {
  display: none;
}

@media screen and (max-width: 500px) {
  .filter-component-container {
    display: flex !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 95vh !important;
    z-index: 1010 !important;
    padding-top: 8vh;
  }

  .filter-categories-option-container {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 70vh;
    align-items: flex-start;
    overflow-y: scroll;
  }
}
