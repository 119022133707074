.uploads-document-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2vw;
}
.uploads-documents-heading {
  font-size: 1.2vw;
  color: black;
  font-weight: 600;
  margin-bottom: 1.5vh;
}
.uploads-documents-desc {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-bottom: 1.5vh;
}
.select-upload-option {
  width: 100%;
}
.upload-state {
  height: 6vh;
}
.upload-documents-main-upload-container {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.upload-documents-upload-and-detail {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4vw;
}
.upload-documents-img-text-div {
  width: 15vw;
  border: 2px dashed var(--color-blue);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.4vw 0.8vw;
  cursor: pointer;
  position: relative;
  background: #fdfbfb;
}

.upload-document-name {
  display: flex;
  align-items: center;
  margin-top: 1.2vh;
}
.upload-document-span {
  font-size: 0.9vw;
  font-weight: 500;
}
.upload-document-name svg {
  font-size: 0.9vw;
  margin-left: 0.5vw;
  cursor: pointer;
}
.upload-document-logo {
  width: 0.8vw;
  height: auto;
  margin-right: 0.4vw;
}
.upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
}

.upload-documents-img {
  width: 2vw;
  height: auto;
  margin-right: 0.4vw;
}
.upload-documents-text {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  margin-top: 1vh;
}
.upload-documents-size-details {
  width: auto;
  height: auto;
}
.upload-documents-ul {
  list-style-type: disc;
}

.upload-documents-ul li {
  font-size: 0.7vw;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  padding: 0.25vh 0;
  margin-left: -1.5vw;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .uploads-document-container {
    padding: 15px;
  }
  .uploads-documents-heading {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .uploads-documents-desc {
    font-size: 13px;
    margin-bottom: 15px;
  }

  .upload-state {
    height: 40px;
  }

  .upload-documents-img-text-div {
    width: 40vw;
    padding: 5px 10px;
  }

  .upload-document-name {
    margin-top: 15px;
  }
  .upload-document-span {
    font-size: 14px;
  }
  .upload-document-name svg {
    font-size: 14px;
    margin-left: 6px;
  }
  .upload-document-logo {
    width: 25px;
  }

  .upload-documents-img {
    width: 30px;
  }
  .upload-documents-text {
    font-size: 14px;
  }

  .upload-documents-ul li {
    font-size: 12px;
  }
}
