.master-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 10px;
}

.master-container h1 {
  text-align: center;
  color: #333;
}

.master-brand-info p {
  margin-bottom: 15px;
    font-size: 15px;
}

.master-key-highlights {
  margin-bottom: 15px;
}

.master-key-highlights h2 {
  color: rgba(0,0,0,0.8);
    font-size: 16px;
    margin: 0;
}

.master-key-highlights ul {
  list-style: disc;
}

.master-key-highlights li {
  color: #777;
    font-size: 13.8px;
    line-height: 1.3;
}

.master-key-highlights li strong {
  font-weight: bold;
    color: rgba(0,0,0,0.7);
}

