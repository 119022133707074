.double-slider-box {
  background-color: #fff;
  padding: 0.6vh 0.4vw;
  border-radius: 10px;
  width: 85%;
}
.range-slider {
  position: relative;
  width: 100%;
  height: 5px;
  margin: 1.6vw 0;
  background-color: #d9d9d9;
}
.left-corner {
  position: absolute;
  left: 0px;
  top: -5px;
  border-radius: 50px;
  background: #d9d9d9;
  width: 15px;
  height: 15px;
}
.right-corner {
  position: absolute;
  right: 0;
  top: -5px;
  background: #d9d9d9;
  width: 15px;
  height: 15px;
  border-radius: 50px;
}

.slider-track {
  height: 100%;
  position: absolute;
  background-color: var(--color-blue);
}

.range-slider input {
  position: absolute;
  width: 100%;
  background: none;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  margin: 0;
  z-index: 1;
}
input[type="range"]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  z-index: 10;
  border-radius: 50%;
  border: 3px solid var(--color-blue);
  pointer-events: auto;
  appearance: none;
  background: var(--color-blue);
  cursor: pointer;
  box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
}
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  z-index: 10;
  border-radius: 50%;
  border: 3px solid var(--color-blue);
  pointer-events: auto;
  background: var(--color-blue);
  -moz-appearance: none;
  cursor: pointer;
  box-shadow: 0 0.125vw 0.5625vw -0.125vw rgba(0, 0, 0, 0.25);
}
.tooltip {
  width: auto;
  height: 25px;
  padding: 3px 5px;
  border: 0;
  background: var(--color-blue);
  color: #fff;
  font-size: 9px;
  line-height: 1;
  border-radius: 0.25vw;
  bottom: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.min-tooltip {
  left: 50%;
  transform: translateX(-20%) translateY(-100%);
  z-index: 5;
}
.max-tooltip {
  right: 50%;
  transform: translateX(50%) translateY(-100%);
}
.tooltip-bottom-left-arrow {
  position: absolute;
  top: 100%;
  left: 30%;
  border-style: solid;
  border-width: 8px 8px 6px 6px;
  border-color: var(--color-blue) transparent transparent transparent;
}
.input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.min-box,
.max-box {
  width: 48px;
  /* height: 2vh; */
}
.min-box {
  /* padding-right: 0.2vw; */
  /* margin-right: 0rem; */
}
.input-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  height: 100%;
}
.input-range-arrow {
  font-size: 18px;
  color: gray;
  /* margin: 0.4vw 0.4vw; */
}
.input-addon {
  display: flex;
  align-items: center;
  padding: 0.3vw;
  font-size: 8px;
  font-weight: 400;
  line-height: 1.5;
  color: #4b566b;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solidvar(--border-color);
  border-radius: 5px;
}
.input-field {
  margin-left: -1px;
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  color: #595757;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid gray;
  border-radius: 5px;

  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
}

.input-field:focus {
  outline: none;
}

.filter-go-btn {
  height: 25px;
  font-size: 10px;
  background: var(--color-blue);
  text-align: center;
  color: white;
  padding: 3px 8px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 0.5vw;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .range-slider {
    margin: 20px 0;
  }
}

@media screen and (max-width: 500px) {
  .double-slider-box {
    background-color: #fff;
    padding: 1vh;
    border-radius: 10px;
    width: 90%;
    align-self: center;
  }

  .range-slider {
    position: relative;
    width: 100%;
    height: 0.8vh; /* Adjusted height for mobile */
    margin: 1vh 0; /* Adjusted margin for mobile */
    background-color: #d9d9d9;
  }

  .left-corner,
  .right-corner {
    position: absolute;
    top: -1.2vw; /* Adjusted top position for mobile */
    background: #d9d9d9;
    width: 4vw; /* Adjusted width for mobile */
    height: 4vw; /* Adjusted height for mobile */
    border-radius: 50px;
  }

  .slider-track {
    height: 100%;
    position: absolute;
    background-color: var(--color-blue);
  }

  .range-slider input {
    position: absolute;
    width: 100%;
    background: none;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    appearance: none;
    margin: 0;
    z-index: 1;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 4vw;
    width: 4vw;
    z-index: 10;
    border-radius: 50%;
    border: 3px solid var(--color-blue);
    pointer-events: auto;
    appearance: none;
    background: var(--color-blue);
    cursor: pointer;
    box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
  }
  input[type="range"]::-moz-range-thumb {
    height: 4vw;
    width: 4vw;
    z-index: 10;
    border-radius: 50%;
    border: 3px solid var(--color-blue);
    pointer-events: auto;
    background: var(--color-blue);
    -moz-appearance: none;
    cursor: pointer;
    box-shadow: 0 0.125vw 0.5625vw -0.125vw rgba(0, 0, 0, 0.25);
  }

  .tooltip {
    width: auto;
    height: 4vh;
    padding: 1vh 2vw;
    font-size: 3vw;
    border-radius: 1vw;
    bottom: -1vw;
  }
  .tooltip-bottom-left-arrow {
    border-width: 1.8vw 1.8vw 1.8vw 1.8vw;
  }
  .min-tooltip {
    left: 50%;
    transform: translateX(-20%) translateY(-100%);
    z-index: 5;
  }

  .max-tooltip {
    right: 50%;
    transform: translateX(50%) translateY(-100%);
  }

  .input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2vh;
  }

  .min-box,
  .max-box {
    width: 6vh; /* Adjusted width for mobile */
  }

  .input-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    height: 100%;
  }

  .input-range-arrow {
    font-size: 3vw !important; /* Adjusted font size for mobile */
  }

  .input-addon {
    padding: 1vw; /* Adjusted padding for mobile */
    font-size: 1vw; /* Adjusted font size for mobile */
  }

  .input-field {
    padding: 0.5vh 1.2vw;
    font-size: 3.2vw;
  }

  .filter-go-btn {
    font-size: 3vw; /* Adjusted font size for mobile */
    padding: 1vh 3vw; /* Adjusted padding for mobile */
    margin-left: 2vw; /* Adjusted margin-left for mobile */
  }
}
