.signup-sidebar-container {
  width: 100%;
  height: 100vh;
  padding: 2vw;
  position: relative;
  z-index: 0;
}
.sign-up-website-logo-container {
  width: auto;
}
.sign-up-website-logo-img {
  width: auto;
  height: 10vh;
  cursor: pointer;
}

.signup-sidebar-main-container {
  margin-top: 8vh;
}

.signup-sidebar-each-information-div {
  display: flex;
  align-items: center;
  margin: 5vh 0;
  position: relative;
}

.signup-sidebar-each-info-dot-div {
  width: 1vw;
  height: 1vw;
  border-radius: 33px;
  position: relative;
  margin-right: 0.8vw;
}
.signup-sidebar-each-info-dot-div svg {
  font-size: 100%;
  color: white;
  background: var(--color-green);
  border-radius: 33px;
  padding: 0.1vw;
}
.signup-sidebar-each-info-dot {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 33px;
  background-color: rgba(0, 0, 0, 0.2);
}

.signup-sidebar-each-info-name {
  font-size: 0.95vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.2);
}

.signup-sidebar-each-info-hr {
  position: absolute;
  left: 0;
  top: 100%;
  border: none;
  height: 4.5vh;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0.2vh 0.4vw;
  transition: background-color 0.5s;
}

/* Styling for completed steps */
.signup-sidebar-each-info-dot-div.completed {
  border: 1px solid var(--color-green);
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-sidebar-each-info-dot-div.active-step {
  border: 1px solid black;
}
.signup-sidebar-each-info-dot.completed {
  background-color: var(--color-green);
  box-shadow: 0 0 0px 0.1vw white inset;
}
.signup-sidebar-each-info-dot.active-step {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0px 0.1vw white inset;
}

.signup-sidebar-each-info-name.completed {
  color: var(--color-green); /* Completed color */
}
.signup-sidebar-each-info-name.active-step {
  color: rgba(0, 0, 0, 0.8);
}

.signup-sidebar-each-info-hr.completed {
  background-color: var(--color-green); /* Completed color */
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .signup-sidebar-container {
    padding: 10px;
  }

  .sign-up-website-logo-img {
    height: 80px;
  }

  .signup-sidebar-each-info-dot-div {
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
  .signup-sidebar-each-info-dot-div svg {
    padding: 2px;
  }

  .signup-sidebar-each-info-name {
    font-size: 12px;
  }
}
