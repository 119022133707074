.mySlider-div {
  width: 100%;
  height: 100%;
  position: relative;
}
.mySlides {
  width: 80%;
  height: 100%;
  margin: auto;
  position: relative;
}
.mobile-mySlides {
  display: none;
}
.mobile-big-image {
  display: none;
}

.largeImageContainer1 {
  display: none;
  position: fixed !important;
  width: 45% !important;
  height: 440px !important;
  top: 22vh !important;
  left: auto !important;
  right: 20px !important;
  /* transform: translate(-100%, -0%) !important; */
  z-index: 0 !important;
  overflow: auto !important;
  /* background: transparent !important; */
  object-fit: contain !important;
  margin-left: 0 !important;
  z-index: 2 !important;
  background-color: #ff980030;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px !important;
  border: 1px solid rgb(148, 148, 148) !important;
  opacity: 0;
  transform: scale(0.5);
  transition: opacity .5s transform .5s;
  @media screen and (min-width: 501px) and (max-width: 800px) {
    top: 150px !important;
    height: 200px !important;
  }
}
.largeImageContainer1.active{
  display: block;
  opacity: 1;
  transform: scale(1);
}

.largeImageStyle {
  width: 100% !important;
  height: 100% !important;
  /* z-index: 1 !important; */
}
.largeImageImg {
  /* width: 1200px !important;
    height: 1800px !important;  */
  /* object-fit: contain !important; */
  background-size: contain;
  background-position: center;
  object-fit: contain;
  z-index: 2 !important;
}
.smallImageImg {
  width: 100% !important;
  height: 100% !important;
  /* object-fit: contain !important; */
  background-size: contain;
  background-position: center;
  object-fit: contain;
  /* transform: scale(1.1); */
}

@media screen and (max-width: 500px) {
  .mySlides {
    display: none;
  }
  .mobile-mySlides {
    display: flex;
    width: auto;
    height: 100%;
    object-fit: contain;
  }
  .mobile-big-image {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .largeImageContainer {
    display: none;
  }

  .largeImageImg {
    display: none;
  }
}


.magnifier-lens{
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 140px;
  background-color: #ff980030;
  border: 1px solid rgb(231, 211, 173);
  opacity: 0;

}

.magnifier-lens.active{
  opacity: 1;

}