.admin-dashboard-preorder-container {
  width: 100%;
  height: 100vh;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.admin-dashboard-left-container {
  width: 220px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--color-blue);
  border-right: 1px solid var(--color-blue);
  overflow-y: auto;
}

.admin-dashboard-cc-logo {
  width: 95px;
  margin: 20px auto 10px auto;
  cursor: pointer;
}
.admin-dashboard-back-to-home {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  padding: 0 20px;
  color: white;
}
.admin-dashboard-back-to-home svg {
  font-size: 20px;
  margin-right: 10px;
}
.admin-dashboard-navigator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 25px;
}

.admin-dashboard-heading {
  font-size: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  padding: 0 20px;
  color: white;
}

.admin-dashboard-navigate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  margin: 1px 0;
  cursor: pointer;
  background: white;
  color: rgba(0, 0, 0, 0.8);
}

.admin-dashboard-navigate svg {
  font-size: 18px;
  margin-right: 10px;
}

.admin-dashboard-navigator-name {
  font-size: 15px;
}

.admin-dashboard-right-container {
  width: calc(100% - 220px);
  margin-left: 220px;
  flex: 1;
  /* overflow-y: auto; */
}
.admin-dashboard-right-pre-order-1 {
  width: 100%;
  height: 100vh;
  /* overflow-y: auto; */
  position: relative;
}
.admin-dashboard-right-top-heading {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  padding: 5px 20px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
  background: #f6f5f5;
}

.admin-dashboard-heading-name {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.8);
}
.admin-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.admin-dashboard-main-container {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #ebe8e8;
  /* margin-bottom: 20px; */
  padding-bottom: 50px;
  overflow-y: auto;
}
.admin-dashboard-middle-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-dashboard-sort-by-search-container{
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  right: 75px;
  top: 20px;
}
.admin-dashboard-sort-by{
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.admin-dashboard-sort-by-name{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.4px;
  font-weight: 600;
  margin-right: 5px;
}
#admin-dashboard-sort-by-id{
  border: none;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  border-radius: 4px;
  padding: 8px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  letter-spacing: 0.5px;
}
.admin-dashboard-middle-search-container{
  width: 250px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* position: relative;
  right: 75px;
  top: 20px; */
  margin-left: 40px;
  background: white;
  border-radius: 25px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  padding: 0 8px;
}
.admin-dashboard-middle-search-container svg{
  color: rgba(0, 0, 0, 0.6);
  font-size: 22px;
}
.admin-dashboard-search-input{
  width: fit-content;
  height: 100%;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 5px;
  border-radius: 25px;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.8);
}
.admin-dashboard-total-preorder-container {
  width: 200px;
  height: 100px;
  position: relative;
  left: 20px;
  top: 20px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.admin-dashboard-total-preorder-container svg {
  position: absolute;
  bottom: 30%;
  right: 10px;
  font-size: 25px;
  color: #c1bebe;
}
.admin-dashboard-total-count {
  position: absolute;
  bottom: 30%;
  left: 20px;
  font-size: 20px;
  color: var(--color-green);
}
.admin-dashboard-heading-total {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  letter-spacing: 0.5px;
}

.admin-dashboard-main-user-preorder-container {
  width: 80vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  margin-top: 40px;
  margin-left: 20px;
  border-radius: 6px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.admin-dashboard-main-user-preorder-header{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-dashboard-main-user-preorder-heading {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 8px;
}
.admin-dashboard-main-user-preorder-overall{
  font-size: 14.5px;
  color: rgba(0,0,0,0.8);
  padding: 8px;
}

.admin-dashboard-main-user-item-head {
  width: 100%;
  padding: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.admin-dashboard-serial-no {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.admin-dashboard-item-each-head {
  width: 21%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.admin-dashboard-item-dot {
  width: 6%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.role-label {
  font-weight: 600;
  margin: 0px;
  color: rgba(0, 0, 0, 0.5);
}

.role-label.active {
  color: var(--color-green);
}


.admin-dashboard-main-user-prebook-container {
  width: 100%;
  height: auto;
}
.admin-dashboard-main-each-user {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.admin-dashboard-main-each-user-top {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.admin-dashboard-user-sno {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.admin-user-each-attr {
  width: 21%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.admin-user-req-fulfilled {
  color: white;
  background: var(--color-peach);
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.admin-user-bottom-products {
  width: 100%;
  background: #f7f5f5;
  padding: 0;
}



@media screen and (min-width: 501px) and (max-width: 1300px) {
  .admin-dashboard-preorder-container {
    width: 100vw;
    height: 100vh;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .admin-dashboard-left-container {
    width: 150px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--color-blue);
    border-right: 1px solid var(--color-blue);
    overflow-y: auto;
  }

  .admin-dashboard-cc-logo {
    width: 80px;
    margin: 20px auto 10px auto;
    cursor: pointer;
  }
  .admin-dashboard-back-to-home {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 20px;
    cursor: pointer;
    padding: 0 15px;
  }
  .admin-dashboard-back-to-home svg {
    font-size: 18px;
    margin-right: 8px;
  }
  .admin-dashboard-navigator {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 25px;
  }
  .admin-dashboard-middle-search-container{
    right: 15px;
  }

  .admin-dashboard-heading {
    font-size: 15px;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
    padding: 0 15px;
  }

  .admin-dashboard-navigate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
    cursor: pointer;
    background: white;
    color: rgba(0, 0, 0, 0.8);
  }
  .admin-dashboard-navigate svg {
    font-size: 16px;
    margin-right: 8px;
  }

  .admin-dashboard-navigator-name {
    font-size: 12px;
  }

  .admin-dashboard-right-container {
    width: calc(100% - 150px);
    margin-left: 150px;
    flex: 1;
    overflow-y: auto;
  }
  .admin-dashboard-right-top-heading {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .admin-dashboard-heading-name {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.8);
  }
  .admin-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .admin-dashboard-main-container {
    width: 100%;
    min-height: 100vh;
    height: auto;
    background: #ebe8e8;
    /* margin-bottom: 20px; */
    padding-bottom: 50px;
  }
  .admin-dashboard-total-preorder-container {
    width: 200px;
    height: 100px;
    position: relative;
    left: 20px;
    top: 20px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .admin-dashboard-total-preorder-container svg {
    position: absolute;
    bottom: 30%;
    right: 10px;
    font-size: 25px;
    color: #c1bebe;
  }
  .admin-dashboard-total-count {
    position: absolute;
    bottom: 30%;
    left: 20px;
    font-size: 20px;
    color: var(--color-green);
  }
  .admin-dashboard-heading-total {
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  .admin-dashboard-main-user-preorder-container {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: white;
    margin-top: 40px;
    margin-left: 20px;
    border-radius: 6px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .admin-dashboard-main-user-preorder-heading {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 8px;
  }

  .admin-dashboard-main-user-item-head {
    width: 100%;
    padding: 5px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .admin-dashboard-serial-no {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .admin-dashboard-item-each-head {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .admin-dashboard-item-dot {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .admin-dashboard-main-user-prebook-container {
    width: 100%;
    height: auto;
  }
  .admin-dashboard-main-each-user {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .admin-dashboard-main-each-user-top {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .admin-dashboard-user-sno {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .admin-user-each-attr {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .admin-user-req-fulfilled {
    color: white;
    background: var(--color-peach);
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 10px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
  .admin-user-bottom-products {
    width: 100%;
    background: rgb(247, 245, 245);
    padding: 0 10px;
  }
}

.increase-width {
  width: 21%;
  padding-top: 8px;
  padding-bottom: 8px;
}
.increase-so {
  width: 16%;
}
.increase-widths {
  width: 16%;
  padding-top: 8px;
  padding-bottom: 8px;
}
.increase-soon {
  width: 4%;
}


.color-gray {
  background: #e8dede;
}

.color-lazy-gray {
  background: #f7f6f6;
}
.open-btn-admin {
  color:white;
  margin-left: 5px;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 11px;
  letter-spacing: 1px;
  cursor: pointer;
}
.admin-order-view-1{
  color:white;
  background: linear-gradient(var(--color-green),green);
  margin-left: 5px;
  border-radius: 25px;
  padding: 4px 10px;
  font-size: 11px;
  letter-spacing: 1px;
  cursor: pointer;
}
