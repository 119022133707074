.bikepro-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 10px;
  }
  
 .bikepro-container h1 {
    text-align: center;
    color: #333;
    font-size: 25px;
  }
  
  .bikepro-brand-info p {
    margin-bottom: 15px;
    font-size: 15px;
  }
  
  .bikepro-product-line {
    margin-bottom: 15px;
  }
  
  .bikepro-product-line h2 {
    color: rgba(0,0,0,0.8);
    font-size: 16px;
    margin: 0;
  }
  
  .bikepro-product-line ul {
    list-style: disc;
  }
  .bikepro-product-line li {
    font-size: 13.8px;
    line-height: 1.3;
  }
  
  .bikepro-highlights {
    font-size: 16px;
  }
  
  .bikepro-highlights h2 {
    color: #333;
    font-size: 16px;
  }
  
  .bikepro-highlights ul {
    list-style-type: none;
    padding: 0;
  }
  
  .bikepro-highlights li {
    color: #777;
    font-size: 13.8px;
    line-height: 1.3;
  }
  
  .bikepro-highlights li strong {
    font-weight: bold;
    color: rgba(0,0,0,0.7);
  }

