.want-delete-product-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f3f3f3;
}
.want-delete-product-heading{
    font-size: 14px;
    color: rgba(0,0,0,0.9);
    font-weight: 700;
    letter-spacing: 0.5px;
}
.want-delete-product-text{
    font-size: 12px;
    color: rgba(0,0,0,0.85);
    font-weight: 600;
    margin: 15px 0;
}
.want-delete-product-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.want-delete-cancel-btn{
    width: 80px;
    height: 25px;
    border-radius: 4px;
    border: 2px solid rgba(0,0,0,0.4);
    color: rgba(0,0,0,0.7);
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.want-delete-it-btn{
    width: 80px;
    height: 25px;
    border-radius: 4px;
    background: var(--color-peach);;
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}