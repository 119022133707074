/* EMAIL VERIFICATION*/
.via-email-verification-via-email-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* padding: 10px; */
  }
  .via-email-verification-via-email-container img{
    position: absolute;
    top: 10px;
    left: 10px;
    background: #F5F8FE;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
  }
  .via-email-verification-via-email-container h3{
    margin: 5px 0;
    color: black;
  }
  .via-email-verification-via-email-container span{
    margin: 3px 0;
  }
  .via-email-verification-via-email-container button{
    width: 30%;
    height: 35px;
    border-radius: 10px;
    font-size: 1rem;
    margin-bottom: 15px;
  }
  .via-email-vefification-via-email-input-div{
    width: 30%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    margin: 5px 0;
  }
  .via-email-vefification-via-email-input-div span{
    margin-right: 3px;
  }
  .via-email-vefification-via-email-input-div input{
    width: 40%;
    height: 25px;
    margin: 2px 3px;
    color: rgba(0,0,0,0.6);
    outline: none;
    border: none;
    text-align: center;
    letter-spacing: 2px;
    font-size: 15px;
    font-weight: 600;
  }
  .via-email-vefification-via-email-input-div input::placeholder{
    color: #A5A5A5;
    font-weight: bold;
    text-align: center;
  }