.katour-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 10px;
    color: #000;
  }
  
  .katour-container h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .katour-container p {
    font-size: 14px;
    color: #555;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  
  .brake-details h3 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 10px;
  }
  
  .brake-details ul {
    list-style: disc;
    margin-left: 20px;
  }
  
  .brake-details ul li {
    font-size: 14px;
    color: #777;
    margin-bottom: 8px;
    line-height: 1.4;
  }
  
  .brake-details ul li strong {
    color: #283248;
  }
  