.desktop-select-variant-bottom-container {
  width: 26vw;
  height: calc(100vh - 110px);
  position: fixed;
  right: -27vw;
  bottom: 0;
  z-index: 9999;
  background: white;
  color: rgb(60, 60, 60);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  cursor: default;
  transition: right 0.5s;
}
.variant-active {
  right: 0;
}
.desktop-select-variant-bottom-header-div {
  width: 100%;
  height: 4.5vh;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  color: var(--color-blue);
}
.desktop-select-variant-bottom-header-div span {
  font-size: 1.2vw;
}
.desktop-select-variant-bottom-header-div svg {
  font-size: 1.3vw;
  cursor: pointer;
}

.desktop-select-variant-bottom-product-details-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.3vw 1vw;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
.desktop-select-variant-bottom-product-img-div {
  width: auto;
  height: auto;
}
.desktop-select-variant-bottom-product-img-div img {
  width: 8vw;
  height: auto;
  cursor: pointer;
}
.desktop-select-variant-bottom-product-details-div {
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 0.8vw;
  position: relative;
}
.desktop-select-variant-comming-soon {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 15px;
}
.desktop-select-variant-bottom-product-name {
  font-size: 15px;
  font-weight: 700;
}
.desktop-select-variant-bottom-product-tech {
  font-size: 0.7vw;
  font-weight: 500;
  margin: 3px 0;
}
.desktop-select-variant-bottom-brand-img {
  width: 70px;
  height: auto;
  max-height: 30px;
}
.desktop-select-variant-bottom-brand-name {
  font-size: 0.78vw;
  font-weight: 600;
  color: rgb(44, 43, 43);
}
.desktop-select-variant-bottom-price-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0;
}
.desktop-select-variant-bottom-mrp-discount-price-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.desktop-select-variant-bottom-mrp-price {
  font-size: 0.8vw;
  margin: 0.3vh 0;
  font-weight: 500;
  color: gray;
}
.desktop-select-variant-bottom-discount-percentage {
  font-size: 0.7vw;
  color: var(--color-green);
  font-weight: 500;
  margin-left: 0.5vw;
  background: var(--color-peach);
  color: white;
  padding: 0.2vw 0.3vw;
  border-radius: 4px;
  margin: 0.5vh 0;
}
.desktop-select-variant-bottom-sale-price {
  font-size: 13px;
  font-weight: 600;
}
.desktop-select-variant-bottom-sale-price b {
  font-size: 14px;
  font-weight: 600;
}
.desktop-select-variant-bottom-offer-price {
  font-size: 0.95vw;
  color: var(--color-green);
  font-weight: 600;
  margin-left: 0.35vw;
}
.desktop-select-variant-bottom-product-qty-div {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.3vw;
}
.desktop-select-variant-bottom-qty-decrease-btn {
  width: 1.5vw;
  height: 1.5vw;
  background: var(--color-blue);
  color: white;
  border-radius: 50%;

  margin: 0 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.desktop-select-variant-bottom-qty-increase-btn {
  width: 1.5vw;
  height: 1.5vw;
  background: var(--color-blue);
  color: white;
  border-radius: 50%;

  margin: 0 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.desktop-select-variant-bottom-qty-decrease-btn svg {
  font-size: 1.3vw;
}
.desktop-select-variant-bottom-qty-increase-btn svg {
  font-size: 1.3vw;
}
.desktop-select-variant-bottom-qty-amount {
  width: 3vw;
  height: auto;
  outline: none;
  border: none;
  border-radius: 4px;
  margin: 0 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  font-weight: 500;
  text-align: center;
}
.desktop-select-variant-bottom-advantage-div {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.4vw 0 0.4vw 1vw;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.desktop-select-variant-bottom-adv-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.desktop-select-variant-bottom-adv-each-div {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.5vw;
  margin-bottom: 0.3vw;
  margin-top: 0.4vw;
  padding: 0.28vw 0.34vw 0.28vw 0.28vw;
  background: rgb(230, 228, 228);
  border-radius: 25px;
}
.desktop-select-variant-bottom-adv-each-div img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
}
.desktop-select-variant-bottom-adv-each-div span {
  font-size: 0.7vw;
  font-weight: 500;
}
.desktop-select-variant-bottom-adv-heading {
  font-size: 1vw;
  font-weight: 700;
}
.desktop-select-variant-bottom-product-variant-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5vw 1vw;
}
.desktop-select-variant-bottom-product-variant-select-size-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0.3vw;
}
.desktop-select-variant-bottom-product-variant-select-size-heading {
  font-size: 1vw;
  font-weight: 700;
}
.desktop-selct-variant-bottom-product-variant-select-size-option-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.4vw 0;
}
.desktop-select-variant-bottom-product-variant-select-size-option-span {
  font-size: 13px;
  font-weight: 500;
  padding: 0.4vw 0.8vw;
  color: rgb(79, 78, 78);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-right: 0.8vw;
  cursor: pointer;
}

.desktop-select-variant-bottom-product-color-div {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8vw;
}
.desktop-select-variant-bottom-product-color-div.active {
  width: 30px;
  height: 30px;
  border: 2px solid var(--color-peach);

  padding: 2px;
}
.desktop-select-variant-bottom-product-color-div button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
}
.desktop-select-variant-bottom-product-color-div button.active {
  width: 22px;
  height: 22px;
}

.desktop-select-variant-bottom-product-variant-select-color-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0.3vw;
}
.desktop-select-variant-bottom-product-variant-select-color-heading {
  font-size: 1vw;
  font-weight: 700;
}
.desktop-selct-variant-bottom-product-variant-select-color-option-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.4vw 0;
}
.desktop-select-variant-bottom-product-variant-select-color-option-span {
  font-size: 0.8vw;
  font-weight: 600;
  padding: 0.4vw 1vw;
  color: rgb(79, 78, 78);
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-right: 0.8vw;
  cursor: pointer;
}
.desktop-show-message-for-variant-part {
  width: 100%;
  position: absolute;
  left: 15px;
  top: -28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-peach);
  font-size: 12.5px;
}
.desktop-show-message-for-variant-part span {
  /* margin: 0 2px; */
}

.desktop-select-variant-bottom-product-variant-select-continue-buy-btn {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.desktop-select-variant-bottom-product-variant-select-continue-btn {
  width: 46%;
  height: 48px;
  margin: 0 auto;
  color: white;
  background: var(--color-green);
  border-radius: 30px;
  padding: 0.3vw;
  font-size: 1.1vw;
  font-weight: 800;
  letter-spacing: 0.2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.desktop-select-variant-bottom-product-variant-select-continue-btn svg {
  position: absolute;
  left: 0.3vw;
  top: 0.3vw;
  background: white;
  color: black;
  border-radius: 50%;
  padding: 7px;
  width: 38px;
  height: 38px;
}
.desktop-select-variant-bottom-product-variant-select-continue-btn span {
  margin-left: 1vw;
}
.desktop-select-variant-bottom-product-variant-select-buy-now-btn {
  width: 46%;
  height: 48px;
  margin: 0 auto;
  color: white;
  background: var(--color-blue);
  border-radius: 30px;
  padding: 0.3vw;
  font-size: 1.1vw;
  font-weight: 800;
  letter-spacing: 0.2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.adv-user-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.admin-select-user {
  padding: 3px;
}
.desktop-select-variant-bottom-product-variant-select-buy-now-btn svg {
  position: absolute;
  left: 0.3vw;
  top: 0.3vw;
  background: var(--color-gold);
  color: black;
  border-radius: 50%;
  padding: 7px;
  width: 38px;
  height: 38px;
}
.desktop-select-variant-bottom-product-variant-select-buy-now-btn span {
  margin-left: 0.4vw;
}
.variant-option-selected {
  border: 2px solid rgba(216, 71, 71, 0.5);
  background: rgba(216, 71, 71, 0.1);
}
.desktop-variant-out-of-stock {
  color: var(--color-peach);
  font-size: 10px;
  margin-left: 10px;
}
.desktop-prebook-btn-container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.desktop-prebook-book-btn {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: goldenrod;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
.desktop-prebook-remove-btn {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: var(--color-green);
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
.desktop-prebook-remove-btn svg {
  font-size: 22px;
  margin-right: 10px;
}
@media screen and (max-width: 500px) {
  .desktop-select-variant-bottom-container {
    display: none;
  }
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .desktop-select-variant-bottom-container {
    width: 50vw;
  }
  .desktop-select-variant-bottom-header-div {
    height: 50px;
  }
  .desktop-select-variant-bottom-header-div span {
    font-size: 15px;
  }

  .desktop-prebook-book-btn {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background: goldenrod;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .desktop-prebook-remove-btn {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background: var(--color-green);
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
  }
  .desktop-prebook-remove-btn svg {
    font-size: 22px;
    margin-right: 10px;
  }
  .desktop-select-variant-bottom-header-div svg {
    font-size: 18px;
  }

  .desktop-select-variant-bottom-product-details-container {
    padding: 5px 10px;
  }
  .desktop-select-variant-bottom-product-img-div img {
    width: 15vw;
  }
  .desktop-select-variant-bottom-product-details-div {
    width: 25vw;
    padding-left: 10px;
  }
  .desktop-select-variant-bottom-product-name {
    font-size: 14px;
  }
  .desktop-select-variant-bottom-product-tech {
    font-size: 12px;
    margin: 4px 0;
  }
  .desktop-select-variant-bottom-brand-img {
    width: 80px;
    max-height: 35px;
  }
  .desktop-select-variant-bottom-brand-name {
    font-size: 12px;
  }

  .desktop-select-variant-bottom-mrp-price {
    font-size: 12px;
    margin: 4px 0;
  }
  .desktop-select-variant-bottom-discount-percentage {
    font-size: 10px;
    margin-left: 5px 0;
    padding: 3px 8px;
  }
  .desktop-select-variant-bottom-sale-price {
    font-size: 15px;
  }
  .desktop-select-variant-bottom-sale-price b {
    font-size: 14px;
  }
  .desktop-select-variant-bottom-offer-price {
    font-size: 14px;
    max-lines: 4px;
  }
  .desktop-select-variant-bottom-product-qty-div {
    margin-top: 4px;
  }
  .desktop-select-variant-bottom-qty-decrease-btn {
    width: 20px;
    height: 20px;
    margin: 0 4px;
  }
  .desktop-select-variant-bottom-qty-increase-btn {
    width: 20px;
    height: 20px;
    margin: 0 4px;
  }
  .desktop-select-variant-bottom-qty-decrease-btn svg {
    font-size: 18px;
  }
  .desktop-select-variant-bottom-qty-increase-btn svg {
    font-size: 18px;
  }
  .desktop-select-variant-bottom-qty-amount {
    width: 30px;
    margin: 0 4px;
    font-size: 16px;
  }
  .desktop-select-variant-bottom-advantage-div {
    padding: 3px 0 3px 10px;
  }

  .desktop-select-variant-bottom-adv-each-div {
    padding: 3px 5px 3px 3px;
  }

  .desktop-select-variant-bottom-adv-each-div span {
    font-size: 12px;
  }
  .desktop-select-variant-bottom-adv-heading {
    font-size: 16px;
  }
  .desktop-select-variant-bottom-product-variant-container {
    padding: 5px 10px;
  }
  .desktop-select-variant-bottom-product-variant-select-size-div {
    margin-bottom: 4px;
  }
  .desktop-select-variant-bottom-product-variant-select-size-heading {
    font-size: 16px;
  }
  .desktop-selct-variant-bottom-product-variant-select-size-option-div {
    margin: 4px 0;
  }
  .desktop-select-variant-bottom-product-variant-select-size-option-span {
    font-size: 12px;
    padding: 4px 10px;
  }

  .desktop-select-variant-bottom-product-variant-select-color-heading {
    font-size: 16px;
  }

  .desktop-select-variant-bottom-product-variant-select-continue-btn {
    font-size: 15px;
    padding: 3px;
  }
  .desktop-select-variant-bottom-product-variant-select-continue-btn svg {
    left: 5px;
    top: 5px;
  }

  .desktop-select-variant-bottom-product-variant-select-buy-now-btn {
    font-size: 15px;
    padding: 3px;
  }
  .desktop-select-variant-bottom-product-variant-select-buy-now-btn svg {
    left: 5px;
    top: 5px;
  }
}
