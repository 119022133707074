.contact-info-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2vw;
}
.contact-info-heading {
  font-size: 1.2vw;
  color: black;
  font-weight: 600;
  margin-bottom: 1.5vh;
}
.contact-info-text {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-bottom: 1.5vh;
}
.contact-info-prerequisites-info {
  width: 100%;
  height: auto;
  border: 2px solid var(--color-green);
  background: rgba(111, 228, 141, 0.2);
  border-radius: 8px;
  padding: 2vh 1vw 1vh 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 2vh 0 4vh 0;
}
.contact-info-prerequisites-heading {
  width: 100%;
  text-align: start;
  font-size: 1vw;
  font-weight: 700;
}
.contact-info-prerequisites-ul {
  width: 100%;
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2vh 0 1vh 0;
}
.contact-info-each-li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2vw;
}
.contact-info-each-img {
  width: 1.5vw;
  height: auto;
  margin-right: 0.5vw;
}
.contact-info-each-span {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
}
.phone-number-verified-check {
  width: 20vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.phone-number-verified-check svg {
  font-size: 0.9vw;
  padding: 0.1vw;
  color: white;
  background: var(--color-green);
  border-radius: 33px;
  margin-right: 0.4vw;
}
.phone-number-verified-span {
  font-size: 0.9vw;
  font-weight: 600;
  color: var(--color-green);
}
.email-verification-container {
  width: 20vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.email-verification-text-div {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.verify-symbol {
  width: 0.9vw;
  height: 0.9vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75vw;
  color: white;
  background: goldenrod;
  border-radius: 50%;
  padding: 0.2vw;
  margin-right: 0.2vw;
}
.email-verification-text {
  font-size: 0.75vw;
  color: goldenrod;
}
.email-verification-verify-btn {
  font-size: 0.9vw;
  padding: 0.5vh 0.8vw;
  border-radius: 4px;
  color: white;
  background: var(--color-blue);
  cursor: pointer;
}

.signup-overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
}

.email-verification-overlay-container {
  width: 28vw;
  height: 37vh;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1vw;
  z-index: 1001;
}
.email-verification-upper-heading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.email-verification-upper-heading-container svg {
  font-size: 1.8vw;
  padding: 0.2vw;
  border-radius: 33px;
  background: var(--color-green);
  color: white;
}
.email-verification-upper-heading-span {
  font-size: 0.9vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  margin: 0.5vh 0;
}
.email-verification-details-text {
  width: 100%;
  text-align: center;
  font-size: 1vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  margin: 0.5vh 0 1.5vh 0;
  line-height: 1.5;
}
.email-verification-otp-enter-input-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.email-otp-input-legend {
  text-align: center;
  font-size: 0.9vw;
  color: (--color-blue);
  font-weight: 500;
  margin: 0 0 0 0;
}

.email-verification-otp-input-boxes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-verification-otp-input-boxes input {
  width: 2vw;
  height: 5vh;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 1.5vh 0;
  margin-right: 1.2vw;
  text-align: center;
}
.email-verification-resend-otp {
  width: 65%;
  text-align: end;
  font-size: 0.9vw;
  color: var(--color-purple);
  cursor: pointer;
}
.email-verification-cancel-btn {
  position: absolute;
  right: 0.5vw;
  top: 1vh;
  cursor: pointer;
  padding: 0.8vh 0.8vw;
  font-size: 0.9vw;
}
.email-verification-confirm-btn {
  position: absolute;
  right: 41%;
  bottom: 1vw;
  color: white;
  background: var(--color-green);
  border-radius: 4px;
  padding: 0.8vh 0.8vw;
  font-size: 0.9vw;
  letter-spacing: 0.3px;
  cursor: pointer;
}

.receive-update-via-whatsapp {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 3vh 0;
}
.receive-update-via-whatsapp input[type="checkbox"] {
  width: 1vw;
  height: 1vw;
  margin: 0;
  margin-right: 0.3vw;
  cursor: pointer;
}
.receive-update-whatsapp-span {
  font-size: 0.7vw;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

.input-fieldset {
  width: 20vw;
  height: 7vh;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  margin: 3vh 0 1vh 0;
  position: relative;
}
.input-legend {
  font-size: 0.9vw;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
}
.input-legend b {
  position: absolute;
  top: -0.6vh;
  right: 0;
  font-size: 1.2vw;
  color: rgba(0, 0, 0, 0.8);
}
.input-type-write {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 2vh 1vw;
}
.select-state-select {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 1vw;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .contact-info-container {
    padding: 10px;
  }
  .contact-info-heading {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .contact-info-text {
    font-size: 13px;
    margin-bottom: 15px;
  }
  .contact-info-prerequisites-info {
    padding: 15px 10px 10px 10px;
    margin: 15px 0 25px 0;
  }
  .contact-info-prerequisites-heading {
    font-size: 13px;
  }
  .contact-info-prerequisites-ul {
    font-size: 12px;
    margin: 15px 0 10px 0;
  }
  .contact-info-each-li {
    margin-right: 15px;
  }
  .contact-info-each-img {
    width: 25px;
    margin-right: 5px;
  }
  .contact-info-each-span {
    font-size: 10px;
  }
  .phone-number-verified-check {
    width: 50vw;
  }
  .phone-number-verified-check svg {
    font-size: 13px;
    margin-right: 5px;
    padding: 2px;
  }
  .phone-number-verified-span {
    font-size: 13px;
  }
  .email-verification-container {
    width: 50vw;
  }

  .verify-symbol {
    font-size: 10px;
    padding: 3px;
    margin-right: 3px;
    width: 12px;
    height: 12px;
  }
  .email-verification-text {
    font-size: 12px;
  }
  .email-verification-verify-btn {
    font-size: 13px;
    padding: 5px 10px;
  }

  .email-verification-overlay-container {
    width: 400px;
    height: 300px;
    padding: 15px;
  }

  .email-verification-upper-heading-container svg {
    font-size: 30px;
    padding: 5px;
  }
  .email-verification-upper-heading-span {
    font-size: 13px;
    margin: 6px 0;
  }
  .email-verification-details-text {
    font-size: 14px;
    margin: 6px 0 12px 0;
  }

  .email-otp-input-legend {
    font-size: 15px;
    margin: 0 0 0 0;
  }

  .email-verification-otp-input-boxes input {
    width: 38px;
    height: 48px;
    margin: 15px 0;
    margin-right: 15px;
  }
  .email-verification-resend-otp {
    width: 80%;
    font-size: 14px;
  }
  .email-verification-cancel-btn {
    right: 10px;
    top: 10px;
    font-size: 18px;
  }
  .email-verification-confirm-btn {
    font-size: 14px;
    padding: 8px 10px;
    bottom: 10px;
  }

  .receive-update-via-whatsapp {
    margin: 15px 0;
  }
  .receive-update-via-whatsapp input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .receive-update-whatsapp-span {
    font-size: 13px;
  }

  .input-fieldset {
    width: 50vw;
    height: 50px;
    margin: 15px 0 10px 0;
  }
  .input-legend {
    font-size: 13px;
  }
  .input-legend b {
    font-size: 18px;
  }
  .input-type-write {
    padding: 13px 10px;
  }
  .select-state-select {
    padding: 0 10px;
  }
}

@media screen and (max-width: 500px) {
  .input-fieldset {
    width: 100%;
    height: 5vh;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    margin: 2vh 0 1vh 0;
    position: relative;
  }
  .input-legend {
    font-size: 4vw;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
  }
  .input-legend b {
    position: absolute;
    top: -0.6vh;
    right: 0;
    font-size: 4.5vw;
    color: rgba(0, 0, 0, 0.8);
  }
  .input-type-write {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    position: absolute;
    left: 0;
    top: 0;
    padding: 2vh 1vw;
  }
  .select-state-select {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 1vw;
  }
}
