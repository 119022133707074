@media (max-width: 500px) {
  .mobile-my-wallet-container {
    width: 100vw;
    min-height: 60vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6vh;
  }
  .mobile-my-wallet-header-container {
    width: 100%;
    background: white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
  }
  .mobile-my-wallet-header-left-container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-my-wallet-header-left-container svg {
    font-size: 6vw;
    color: rgba(0, 0, 0, 0.8);
    margin-right: 0vw;
  }
  .mobile-my-wallet-header-left-span {
    font-size: 4vw;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .mobile-my-wallet-header-right-container {
    height: 100%;
  }
  .mobile-my-wallet-header-right-container svg {
    font-size: 6vw;
    color: rgba(0, 0, 0, 0.8);
  }

  .mobile-my-wallet-top-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20vh;
    border-radius: 8px;
    background: linear-gradient(to right, var(--color-blue), #5a8af3);
    position: relative;
    padding: 1.2vh;
  }
  .mobile-my-wallet-top-header {
    position: absolute;
    top: 42%;
    transform: translateY(-58%);
    left: 32vw;
    font-size: 4vw;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .mobile-my-wallet-my-cashback-price {
    position: absolute;
    top: 58%;
    transform: translateY(-42%);
    left: 32vw;
    font-size: 6vw;
    font-weight: 700;
  }
  .mobile-my-wallet-cashback-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 2.5vh;
    width: 22vw;
    height: auto;
  }
  .mobile-my-wallet-recent-transaction-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    justify-content: flex-start;
    /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    padding: 2vh 3vw;
  }
  .mobile-wallet-recent-transaction-heading {
    font-size: 4.2vw;
    color: black;
    font-weight: 700;
  }
  .mobile-wallet-recent-transaction-main-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .wallet-recent-transaction-tabs-container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2vh 0;
  }
  .mobile-wallet-recent-transaction-each-tabs {
    font-size: 3.8vw;
    font-weight: 700;
    padding: 0.5vh 1.5vh;
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
    margin-right: 3vw;
    cursor: pointer;
  }
  .mobile-wallet-recent-transaction-each-transaction-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    margin: 1vh 0;
  }
  .mobile-wallet-recent-transaction-each-transaction-date {
    font-size: 3.8vw;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
  }
  .mobile-wallet-recent-transaction-each-transaction-main-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1vh;
  }
  .mobile-wallet-recent-transaction-each-transaction-amount {
    position: absolute;
    right: 0.5vw;
    top: 60%;
    transform: translateY(-40%);
    color: var(--color-green);
    font-size: 4vw;
  }
  .mobile-wallet-recent-transaction-each-transaction-img {
    width: 8vw;
    height: 8vw;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 0.5vh;
    margin-top: 1vh;
  }
  .mobile-wallet-recent-transaction-each-transaction-details-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 2vw;
  }
  .mobile-wallet-recent-transaction-details-head {
    font-size: 3.8vw;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 700;
  }
  .mobile-wallet-recent-transaction-details-p {
    font-size: 3vw;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    width: 70%;
    margin-top: 0.5vh;
  }

  .mobile-wallet-information-detail-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5vh 3vw;
  }
  .mobile-wallet-information-detail-heading {
    font-size: 4.2vw;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: black;
    margin-bottom: 1vh;
  }
  .mobile-support-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mobile-support-each-details-container {
    width: 100%;
    background: white;
    border-radius: 4px;
  }
  .mobile-support-each-details-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 3vw;
  }
  .mobile-support-each-details-header-container svg {
    font-size: 5vw;
    color: rgba(0, 0, 0, 0.8);
  }
  .mobile-support-each-details-header-text {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    font-size: 4vw;
  }
  .mobile-support-each-details-main-content {
    font-size: 3.5vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    padding: 1vh 3.5vw;
  }

.mobile-wallet-recent-transaction-not-available-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  padding: 1vh 3vw;

}
.mobile-wallet-recent-transaction-not-available-img{
  width: 15vw;
  height:15vw;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  padding: 1.5vh;
}
.mobile-wallet-recent-transaction-head{
  font-size: 3.8vw;
  color: rgba(0, 0, 0, 0.9);
  margin: 1vh 0;
  font-weight: 600;
}
.mobile-wallet-recent-transaction-detail{
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}

  .mobile-wallet-tab-active {
    background: var(--color-green);
    color: white;
    padding: 0.7vh 1.7vh;
    border: none;
  }
}
