.via-phone-phone-email-change-container {
  width: 100%;
}
.phone-and-email-change-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.phone-change-main-container {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.phone-change-img-address {
  position: relative !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
  background: none !important;
  width: 30px;
  height: 30px;
}
.phone-change-name {
  font-size: 16px !important;
  font-weight: 800 !important;
  color: black;
}
.phone-change-phone-number {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.8);
}
.phone-change-btn {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  color: white !important;
  background: #819ef5;
  cursor: pointer;
}
.email-change-main-container {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.email-change-img-address {
  position: relative !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0 !important;
  background: none !important;
  width: 30px;
  height: auto;
}
.email-change-email-address {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.8);
}
.email-change-name {
  font-size: 16px !important;
  font-weight: 800 !important;
  color: black;
}
.email-change-btn {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  color: white !important;
  background: #819ef5;
  cursor: pointer;
}
