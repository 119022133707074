.sideNav-container {
  width: 250px;
  height: auto;
  border-radius: 15px;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0 5px 8px 2px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 100px;
  height: 100vh;
  overflow-y: auto;
  z-index: 2;
}
.sidenav-item-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 10px;
  cursor: pointer;
}
.sidenav-item-div:first-child {
  border-radius: 14px 14px 0 0;
}

.sidenav-item-div:last-child {
  border-radius: 0;
  color: var(--color-peach);
}
.sidenav-item-div:last-child:hover {
  background: var(--color-peach);
  color: white;
}
.sidenav-item-div:hover {
  background: rgba(0, 0, 0, 0.1);
}
.sidenav-item-div svg {
  font-size: 25px;
  margin-right: 8px;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .sideNav-container {
    min-width: 180px !important;
    width: 180px !important;
  }
}
