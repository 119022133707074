.mobile-servicePromices-container {
    width: 100vw;
    height: auto;
    padding: 0 3vw;
    padding-bottom: 2vh;
    border-bottom: 2px solid rgba(0,0,0,0.3);
  }
  .mobile-servicePromices {
    width: 100% !important;
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 1vh 1vw;
    background: white;
    border-radius: 8px;
    color: black;
    text-transform: none;
  }
  .mobile-servicePromices img {
    width: 6vh;
    height: 6vh;
    margin-left: 1vw;
  }
  .mobile-servicePromices-text {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 4vw;
  }
  .mobile-servicePromices-heading {
    font-size: 4.2vw;
    font-weight: 700;
    margin-bottom:2vw;
    color: var(--color-blue);
  }
  .mobile-servicePromices-desc {
    width: 100%;
    font-size: 3.2vw;
    text-align: start;
    color: #474545;
    font-weight: 500;
  }
  .mobile-servicePromices .slick-dots li button:before {
    font-size: 0.6vw !important;
    color: var(--color-blue);
  }
  .mobile-servicePromices .slick-dots .slick-active button:before {
    font-size: 0.8vw !important;
    color: var(--color-blue);
  }