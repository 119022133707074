.mobile-varient-selection-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2vh;
}

.mobile-size-selection-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1vh;
}
.mobile-size-selection-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 3vw;

  background: white;
}
.mobile-size-selection-heading-container svg {
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.9);
}
.mobile-size-selection-heading-span {
  font-size: 4.2vw;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-size-selected-size-span {
  font-size: 4.3vw;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.9);
  font-family:Space Grotesk, sans-serif;
}
.mobile-size-selection-option-container {
  width: 100%;
  background: rgb(251, 224, 224);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5vh 3vw;
  overflow-x: scroll;
}
.mobile-size-each-selection-option {
  font-size: 3.5vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 0.8vh 1.5vw;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  margin-right: 3vw;
}

.mobile-color-selection-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1vh;
}
.mobile-color-selection-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 3vw;

  background: white;
}
.mobile-color-selection-heading-container svg {
  font-size: 6vw;
  color: rgba(0, 0, 0, 0.9);
}
.mobile-color-selection-heading-span {
  font-size: 4.2vw;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-color-selected-size-span {
  font-size: 4.3vw;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.9);
}
.mobile-color-selection-option-container {
  width: 100%;
  background: rgb(251, 224, 224);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5vh 3vw;
  overflow-x: scroll;
}
.mobile-color-each-selection-option {
  width: 4.5vh;
  height: 4.5vh;
  border-radius: 50px;
  margin-right: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-color-each-selection-option button {
  width: 4vh;
  height: 4vh;
  border-radius: 50px;
}
.mobile-size-active{
    background: var(--color-blue);
    color: white;
}
