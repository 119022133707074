.garrari-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 10px;
}

.garrari-brand-info p {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
  color: #333;
}

.garrari-highlights {
  font-size: 16px;
}

.garrari-highlights h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  margin-bottom: 10px;
}

.garrari-highlights ul {
  list-style-type: none;
  padding: 0;
}

.garrari-highlights li {
  margin-bottom: 10px;
  color: #555;
  font-size: 14px;
  line-height: 1.4;
}

.garrari-highlights li strong {
  font-weight: bold;
  color: #222;
}
