 @media screen and (max-width: 500px) {
    .mobile-product-all-info {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      color: black;
    }
    .mobile-product-description-tab-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1px 0;
    }
    .mobile-product-description-header-div {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2vh 3vw;
      background: rgb(213, 241, 254);
    }
    .mobile-product-description-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .mobile-product-description-heading img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  
    .mobile-product-description-heading span {
      font-size: 0.9rem;
    }

    .mobile-product-insight-tab-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 1px 0;
      }
      .mobile-product-insight-header-div {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2vh 3vw;
        background: rgb(248, 224, 250);
      }
      .mobile-product-insight-heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .mobile-product-insight-heading img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    
      .mobile-product-insight-heading span {
        font-size: 0.9rem;
      }
  
    .mobile-technical-data-tab-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1px 0;
    }
  
    .mobile-technical-data-header-div {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2vh 3vw;
      background: #28a4471a;
    }
  
    .mobile-technical-data-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  
    .mobile-technical-data-heading img {
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }



    .mobile-about-brand-tab-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1px 0;
    }
  
    .mobile-about-brand-header-div {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2vh 3vw;
      background: #28a4471a;
    }
  
    .mobile-about-brand-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  
    .mobile-about-brand-heading img {
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }


  
    .mobile-how-to-tab-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1px 0;
    }
  
    .mobile-how-to-header-div {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2vh 3vw;
      background: #ba37371a;
    }
  
    .mobile-how-to-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  
    .mobile-how-to-heading img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
  
    .mobile-review-tab-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 1px 0;
    }
  
    .mobile-review-header-div {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2vh 3vw;
      background: #7203721a;
    }
  
    .mobile-review-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  
    .mobile-review-heading img {
      width: 26px;
      height: 26px;
      margin-right: 6px;
    }
  
    .mobile-product-description-details-container {
      width: 100%;
      height: auto;
      background: white;
    }
  
    .mobile-technical-data-details-container {
      width: 100%;
      height: auto;
      background: white;
    }
  
    .mobile-how-to-details-container {
      width: 100%;
      height: auto;
      background: white;
    }
  
    .mobile-review-details-container {
      width: 100%;
      height: auto;
    }
  }
  