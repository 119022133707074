.business-registration-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2vw;
}
.business-registration-heading {
  font-size: 1.2vw;
  color: black;
  font-weight: 600;
  margin-bottom: 1.5vh;
}
.business-registration-desc {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-bottom: 1.5vh;
}

.show-GST-details-after-verify {
  position: fixed;
  top: 30%;
  right: 5%;
  width: 40vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1vw;
  border-radius: 5px;
  background: white;
  z-index: 9999;
  background: #f7f7f7;
  border: 1px solid #ddd;
}
.show-GST-details-after-verify svg {
  background: var(--color-green);
  color: white;
  border-radius: 50%;
  font-size: 1.1vw;
  width: 2.5vw;
  padding: 0.3vw;
  height: 2.5vw;
}
.show-GST-details-after-verify-heading {
  font-size: 1vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  margin: 0.5vw 0;
}

.show-GST-details-after-verify-main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0.5vw 0.5vw;
}
.show-GST-details-after-verify-each-attribute {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0.3vw 0;
}
.show-GST-details-after-verify-each-attribute-key {
  width: 35%;
  text-align: start;
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
}
.show-GST-details-after-verify-each-attribute-value {
  width: 65%;
  text-align: start;
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.65);
}

.show-GST-details-after-verify-close-btn {
  background: var(--color-purple);
  color: white;
  border-radius: 25px;
  padding: 0.3vw 1.2vw;
  font-size: 0.7vw;
  margin-top: 0.4vw;
  cursor: pointer;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .business-registration-container {
    padding: 15px;
  }
  .business-registration-heading {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .business-registration-desc {
    font-size: 13px;
    margin-bottom: 15px;
  }

  .show-GST-details-after-verify {
    font-size: 15px;
    top: 100%;
    right: 50%;
    transform: translate(50%, 0%);
    width: 65vw;
    position: relative;
  }
  .show-GST-details-after-verify svg {
    font-size: 16px;
    width: 25px;
    height: 25px;
    padding: 3px;
  }
  .show-GST-details-after-verify-heading {
    font-size: 15px;
    margin: 6px 0;
  }

  .show-GST-details-after-verify-main-container {
    padding: 6px;
  }
  .show-GST-details-after-verify-each-attribute {
    margin: 4px 0;
  }
  .show-GST-details-after-verify-each-attribute-key {
    font-size: 12px;
  }
  .show-GST-details-after-verify-each-attribute-value {
    font-size: 12px;
  }
}
