.created-user-detail-container {
  position: fixed;
  top: 180px;
  right: 100px;
  width: 45%;
  height: auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0/20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}
.form-formheading-top{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.created-user-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.created-user-main-details {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.created-user-left-detail {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
}
.created-user-each-left-detail {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 10px 0;
  /* white-space: nowrap; */
}
.created-user-right-detail {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0;
}
.created-user-each-right-detail {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 10px 0;
  /* white-space: nowrap; */
}
.submitted-container {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.submitted-gif {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}
.submitted-text {
  font-size: 20px;
  color: var(--color-green);
  font-weight: 600;
  letter-spacing: 1px;
}
.typewrite-container {
  position: fixed;
  top: 40px;
  left: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Raleway", sans-serif;
  white-space: nowrap;
  color: #fff;
}

.Typewriter {
  text-align: center;
  width: 100%;
  height: auto;
  color: #fff;
  /* position: fixed;
  top: 50px;
  left: 48%; */
  overflow: hidden;
  animation: typing 5s steps(45), cursor 0.8s step-end infinite alternate;
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 5px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}
.Typewriter-1 {
  text-align: center;
  width: 100%;
  height: auto;
  color: #fff;
  overflow: hidden;
  font-size: 22px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 4px;
  white-space: nowrap;
  animation-delay: 5s;
  padding: 10px;
  margin: 0;
}
.typewriter-li-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.typewriter-each-li-1{
  text-align: center;
  height: auto;
  display: flex;
  align-items: center;
}
.typewriter-each-li-2{
  text-align: center;
  height: auto;
  display: flex;
  align-items: center;
}
.typewriter-each-li-3{
  text-align: center;
  height: auto;
  display: flex;
  align-items: center;
}
.typewriter-each-li-4{
  text-align: center;
  height: auto;
  display: flex;
  align-items: center;

}
.typewriter-each-li-1::before{
  content: "•";
  font-size: 25px;
  margin-right: 3px;
}
.typewriter-each-li-2::before{
  content: "•";
  font-size: 25px;
  margin-right: 3px;
}
.typewriter-each-li-3::before{
  content: "•";
  font-size: 25px;
  margin-right: 3px;
}
.typewriter-each-li-4::before{
  content: "•";
  font-size: 25px;
  margin-right: 3px;
}

.mobile-view-portal {
  width: 100%;
  font-size: 12px;
  color: var(--color-peach);
  margin-left: 20px;
  padding-top: 10px;
  white-space: nowrap;
}
.button-grid-v3{
  position: sticky;
  bottom: 0;
  left: 20px;
  z-index: 999;
}
@keyframes cursor {
  50% {
    border-color: transparent;
  }
}
.mobile-ver{
  z-index: 999;
}

@media (max-width: 500px) {
  .typewrite-container{
    display: none;
  }
  .Typewriter {
    display: none;
  }
  .mobile-view-portal svg {
    font-size: 18px;
  }
  .mobile-verify-btn {
    font-size: 14px;
    padding: 5px 10px;
  }
  .mobile-verify-symbo {
    width: 15px;
    height: 15px;
    font-size: 14px;
    margin-right: 2px;
  }
  .mobile-verification-t {
    font-size: 14px;
  }
  .mobile-otp-f {
    width: 90%;
    height: 300px;
    padding: 20px;
  }
  .mobile-verif svg {
    font-size: 20px;
  }
  .moible-mo-v {
    font-size: 14px;
  }
  .mobile-tt {
    font-size: 13px;
  }
  .mobile-inp input {
    width: 40px;
  }
  .mobile-ttt {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .mobile-ver {
    display: none;
  }

  .mobil-overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
  }
  .button-grid-v3{
    width: calc(100% - 20px);
    position: fixed;
    bottom: 10px;
    left: 2px;
    right: 0;
    /* transform: translateX(-50%); */
    z-index: 999;
  }

  .created-user-detail-container {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 90vw;
    height: auto;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0/20);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    z-index: 1000;
  }
  .created-user-profile-img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .created-user-main-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .created-user-left-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
  }
  .created-user-each-left-detail {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 10px 0;
    white-space: nowrap;
  }
  .created-user-right-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 0;
  }
  .created-user-each-right-detail {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 10px 0;
    /* white-space: nowrap; */
  }
}
