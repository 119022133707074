.my-profile-security-container {
  width: 45vw;
  height: auto;
}
.security-verification-sign-up-main-container {
  width: 100%;
  height: 220px;
  
}
.security-basic-detail-phone-email-verfication {
  width: 100%;
  height: 200px;
  border-radius: 7px;
  background: white;
  /* margin-bottom: 25px; */
  padding: 0;
}
.security-basic-detial-phone-em-verification-main-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.security-basic-detail-phone-email-verfication legend {
  font-size: 16px;
  color: black;
  color: var(--color-blue);
  font-weight: 600;
  margin-left: 10px;
}
.security-basic-detail-phone-email-verfication span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
}

.security-basic-detail-phone-email-verfication-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}
.security-basic-detail-phone-email-verfication-main-div span {
  padding: 10px;
  font-size: 1rem;
}
.security-basic-detail-phone-email-verification-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 15%;
}
.security-basic-detail-phone-email-verification-btn a {
  width: 140px;
  padding: 8px 15px;
  background: #819ef5;
  color: white;
  border-radius: 25px;
  font-size: 0.9rem;
  text-align: center;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .my-profile-security-container {
    width: 100%;
  }

  .security-basic-detail-phone-email-verification-btn {
    padding: 0 10%;
  }
}
