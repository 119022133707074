body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.App {
  position: relative;
}
.move-top-btn {
  position: fixed;
  right: 2.3vw;
  bottom: 4vh;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--color-blue);
  padding: 0.2vw;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  transition: bottom 0.1s ease;
}
.move-top-arrow {
  color: white;
  font-size: 20px;
  margin: 0;
  padding: 0;
}
.move-top-text {
  position: relative;
  bottom: 15%;
  font-size: 10px;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0.1vh 0;
}

.whatapp_button_link_to{
  position: fixed;
  right: 1.8vw;
  bottom: 12vh;
  /* transform: (translateY(-70%)); */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--color-blue);
  padding: 0.2vw;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  transition: background 0.5s ease;
}
.whatapp_button_link_to:hover{
  background: var(--color-peach);
}
.whatapp_button_link_to img{
  width: 40px;
  transition: width 0.1s ease;
}
.whatapp_button_link_to img:hover{
  width: 35px;
}
@media screen and (min-width: 501px) and (max-width: 1000px) {
  .move-top-btn {
    width: 40px;
    height: 40px;
    cursor: none;
  }
  .move-top-arrow {
    font-size: 20px;
  }
  .move-top-text {
    font-size: 10px;
  }

  .whatapp_button_link_to{
    width: 50px;
    height: 50px;
    padding: 3px;
    cursor: none;
  }
  .whatapp_button_link_to img{
    width: 30px;
  }
  .whatapp_button_link_to img:hover{
    width: 25px;
  }
  
}



@media (max-width: 500px) {
  .move-top-btn-mobile {
    position: fixed;
    right: -12vw;
    bottom: 10vh;
    width: 11vw;
    height: 11vw;
    border-radius: 0;
    background: var(--color-blue);
    padding: 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: none;
    z-index: 990;
    transition: right 0.5s ease;
  }
  .scrolled{
    right: 0;
  }
  .whatapp_button_link_to{
    position: fixed;
    right: 5vw;
    bottom: 12vh;
    width: 13.5vw;
    height: 13.5vw;
    border-radius: 50%;
    background: var(--color-blue);
    padding: 0.5vh;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: none;
    z-index: 999;
    transition: bottom 0.2s ease;
  }
  .whatapp_button_link_to img{
    width: 9vw;
    height: 9vw;
  }
  .whatapp_button_link_to img:hover{
    width: 8vw;
    height: 8vw;
  }
  #move-top-arrow-mobile {
    color: white;
    font-size: 9vw;
    margin: 0;
    padding: 0;
  }
  .move-top-text-mobile {
    position: relative;
    bottom: 15%;
    font-size: 2.5vw;
    color: white;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0.1vh 0;
    display: none;
  }
}