.change-phone-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 9999;
  border-radius: 15px;
  background: white;
  padding: 10px 15px;
}
.change-phone-heading {
  font-size: 16px !important;
  color: black;
  font-weight: 800 !important;
  margin-bottom: 4px !important;
}
.change-phone-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.change-phone-main-container input {
  width: 100%;
  outline: none;
  color: rgba(0, 0, 0, 0.6);
  border: 2px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
  padding: 5px;
  margin: 3px 0 5px 0 !important;
}
.change-phone-main-container input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 13px;
  font-weight: 600;
}
.change-phone-label {
  font-size: 13px;
  font-weight: 600;
  color: black;
  margin-bottom: 2px !important;
}
.change-phone-send-otp-btn {
  width: 100%;
  height: 30px;
  color: white !important;
  background: #819ef5;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 !important;
}
.change-phone-cancel-btn {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.2vw 0 !important;
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 6px;
}

/* OTP COMPONENT */
.change-phone-enter-otp-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
  border-radius: 15px;
  background: white;
  padding: 10px 15px;
}
.change-phone-enter-otp-top-heading {
  font-size: 16px !important;
  color: black;
  font-weight: 800 !important;
}
.change-phone-enter-otp-next-heading {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600 !important;
  text-align: center;
}
.change-phone-enter-otp-phone-number {
  font-size: 13px !important;
  color: #819ef5 !important;
  font-weight: 600;
}
.change-phone-enter-otp-phone-number-otp-container {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-phone-enter-otp-verify-btn {
  width: 100%;
  height: 30px;
  background: #819ef5;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}
.change-phone-enter-otp-dont-receive-otp-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.change-phone-enter-otp-dont-receive-text {
  font-size: 12px !important;
  font-weight: 600;
  color: black;
}
.change-phone-enter-otp-send-again-btn {
  font-size: 12px;
  font-weight: 600;
  color: #819ef5 !important;
  margin-left: 0.4vw !important;
  cursor: pointer;
}

.change-phone-otp-input-boxes {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px auto;
}
/* Apply this style to the input element */
.change-phone-otp-input-boxes input {
  width: 30px;
  height: 40px;
  background: transparent;
  border-radius: 6px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s; /* Add a transition for smoother border color change */
}

/* Add this CSS rule */
.change-phone-otp-input-boxes input.filled {
  border: 2.2px solid rgba(0, 0, 0, 0.5);
}

.change-phone-otp-recovery-boxes [type="number"]::-webkit-inner-spin-button,
.change-phone-otp-recovery-boxes [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.change-phone-otp-recovery-boxes [type="number"] {
  -moz-appearance: textfield;
}
.form-container [type="number"]::-webkit-inner-spin-button,
.form-container [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.form-container [type="number"] {
  -moz-appearance: textfield;
}
.change-phone-otp-input-boxes [type="number"]::-webkit-inner-spin-button,
.change-phone-otp-input-boxes [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.change-phone-otp-input-boxes [type="number"] {
  -moz-appearance: textfield;
}
/* .check-pincode-availability [type="number"]::-webkit-inner-spin-button,
.check-pincode-availability [type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* For Firefox */
/* .check-pincode-availability [type="number"] {
  -moz-appearance: textfield;
} */

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .change-phone-container {
    width: 50vw;
    height: auto;
  }

  /* OTP COMPONENT */
  .change-phone-enter-otp-container {
    width: 50vw;
  }

  .change-phone-enter-otp-phone-number-otp-container {
    height: 60px;
  }
}
