.promotions-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) inset;
}
.promotions-navigation-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 2vh 0;
  padding-bottom: 2vh;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
}
.promotions-navigation-what-navigate {
  width: 120px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}
.promotions-navigation-recommend-navigate {
  width: 120px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}
.promotions-navigation-hot-navigate {
  width: 120px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}
.promotions-navigation-wishlist-navigate {
  width: 120px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
}
.promotions-navigation-what-navigate-span {
  width: auto;
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 700;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
}
.promotions-navigation-recommend-navigate-span {
  width: auto;
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 700;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
}
.promotions-navigation-hot-navigate-span {
  width: auto;
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 700;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
}
.promotions-navigation-wishlist-navigate-span {
  width: auto;
  font-size: 15px;
  color: var(--color-blue);
  font-weight: 700;
  letter-spacing: 0.5px;
  word-spacing: 2px;
  cursor: pointer;
  position: relative;
  padding: 1vh 0;
}

.what-navigation-active .promotions-navigation-what-navigate-span::before {
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border: 3px solid var(--color-blue);
  opacity: 0.8;
  border-radius: 50% 0 50% 0 / 150px 0 150px 0;
  transform: skewX(-45deg);
}

.recommend-navigation-active
  .promotions-navigation-recommend-navigate-span::before {
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border: 3px solid var(--color-green);
  opacity: 0.8;
  border-radius: 50% 0 50% 0 / 150px 0 150px 0;
  transform: skewX(-45deg);
}
.hot-navigation-active .promotions-navigation-hot-navigate-span::before {
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border: 3px solid var(--color-peach);
  opacity: 0.8;
  border-radius: 50% 0 50% 0 / 150px 0 150px 0;
  transform: skewX(-45deg);
}
.wishlist-navigation-active
  .promotions-navigation-wishlist-navigate-span::before {
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  border: 3px solid var(--color-purple);
  opacity: 0.8;
  border-radius: 50% 0 50% 0 / 150px 0 150px 0;
  transform: skewX(-45deg);
}

.what-navigation-active .promotions-navigation-what-navigate-span {
  color: var(--color-blue);
}
.recommend-navigation-active .promotions-navigation-recommend-navigate-span {
  color: var(--color-green);
}
.hot-navigation-active .promotions-navigation-hot-navigate-span {
  color: var(--color-peach);
}
.wishlist-navigation-active .promotions-navigation-wishlist-navigate-span {
  color: var(--color-purple);
}
/* .promotions-navigation-each-navigate-span:hover{
    color:var(--color-green);
}
.promotions-navigation-each-navigate-span:hover::before{
    width: 100%;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border: 2px solid var(--color-green);
    opacity: 0.8;
} */
.promotions-product-sliders-container {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0 2vw;
}

.promotion-bottom-white-space {
  width: 100%;
  height: auto;
  padding: 2.5vh 0;
  background: white;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .promotions-navigation-container {
    padding-bottom: 5px;
  }

  .promotion-bottom-white-space {
    padding: 15px 0;
  }
}
