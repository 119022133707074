.brand-banner-container {
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 0;
}

.brand-banner-slider {
  width: 100%;
  /* height: 35vh; */
  height: auto;
  border-radius: 5px;
}
.brand-banner-slider .slick-list {
  height: 100% !important;
  border-radius: 15px !important;
}
.brand-banner-slider .slick-next {
  right: 1.5vw;
  transition: 0.7s all ease;
}
.brand-banner-slider .slick-next:before {
  font-size: 3vw;
  color: #eeeeee;
  opacity: 1;
}
.brand-banner-slider .slick-next:hover {
  opacity: 0.8;
}
.brand-banner-slider .slick-prev {
  left: 1.5vw;
  transition: 0.7s all ease;
}
.brand-banner-slider .slick-prev:before {
  font-size: 3vw;
  color: #eeeeee;
  opacity: 1;
}
.brand-banner-slider .slick-prev:hover {
  opacity: 0.8;
}
.brand-banner-slider button {
  z-index: 1;
}
.brand-banner-img {
  width: auto;
  height: 100%;
  border-radius: 5px;
  z-index: 1;
}


.brand-below-about-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2vh;
}
.brand-below-about-heading{
    font-size: 16px;
    color: var(--color-blue);
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 5px;
}
.brand-below-about-para{
    font-size: 1vw;
    color: rgba(0,0,0,0.7);
    font-weight: 600;
    margin: 1.5vh 0 2vh 0;
}