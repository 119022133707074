.allFilterComponent-container {
  width: 100%;
  height: 100%-1.1vw;
  color: black;
  text-transform: none;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* padding: 0 0px 0.55vw 0; */
  /* border-right: 1px solid rgba(0,0,0,0.1); */
  position: sticky;
  top: 155px;
  margin: 0;
  margin-top: 3.1vh;
}
.allFilterComponent-scroll-div {
  width: 100%;
  height: 96vh;
  /* overflow-y: scroll; */
}
/* For Webkit browsers (Chrome, Safari, etc.) */
/* .allFilterComponent-scroll-div {
  scrollbar-width: thin;
  scrollbar-color: #6391b1 #f1f1f1;
} */

.allFilterComponent-scroll-div:hover {
  scrollbar-color: #476a81 #f1f1f1; /* Thumb color on hover */
}

.allFilterComponent-scroll-div::-webkit-scrollbar {
  display: block;
  width: 0.7vw; /* Width of the entire scrollbar */
  /* height: 20px; */
}

.allFilterComponent-scroll-div::-webkit-scrollbar-track {
  background: white; /* Track color */
}

.allFilterComponent-scroll-div::-webkit-scrollbar-thumb {
  background: var(--color-blue);
  background-clip: content-box;
  border: 4px solid transparent;
  transition: all ease 0.8s;
  border-radius: 10px;
}
.allFilterComponent-scroll-div::-webkit-scrollbar-thumb:hover {
  background: var(--color-blue);
  background-clip: content-box;
  border: 3px solid transparent;
  transition: all ease 1s;
  border-radius: 10px;
}

.allFilterComponent-filtered-container {
  width: 100%;
  height: 20vh;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}
.allFilterComponent-name-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.55vw 0.55vw 0.55vw 0px;
}
.allFilterComponent-name-div .allFilterComponent-heading {
  font-size: 1.2vw;
}
.allFilterComponent-name-div .allFilterComponent-clearAll {
  color: #fd0025;
  cursor: pointer;
}
.allFilterComponent-selected-filtered-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 0.55vw;
}
.info-shippingInfoMode-container {
  position: relative;
}
.allFilterComponent-selected-filtered-container span {
  background: var(--color-blue);
  height: 38px;
  padding: 0.35vw;
  margin: 0.3vw 0;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: white;
  font-size: 0.95vw;
  font-weight: 500;
}
.allFilterComponent-selected-filtered-container span svg {
  font-size: 0.9vw;
  margin-left: 0.3vw;
  /* margin-top: 2px; */
  cursor: pointer;
}

.allFilterComponent-shipping-mode-container {
  width: 100%;
  height: auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-right: 0.55vw;
}
.shipping-mode-disclaimer p {
  font-size: 0.78vw;
  color: #6e6c6c;
  font-weight: 500;
}
.shipping-mode-disclaimer p b {
  font-size: 0.85vw;
  color: #ba3737;
  font-weight: 600;
}
.shipping-mode-filtered-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.55vw 0.55vw 0.55vw 0px;
  font-size: 1.2vw;
}
.shipping-mode-filtered-heading svg {
  color: #9e3dcc;
  margin-left: 0.45vw;
  font-size: 1.2vw;
  cursor: pointer;
  display: none;
}
.allFilterComponent-product-shipping-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.allFilterComponent-product-shipping-div input[type="radio"] {
  margin-right: 0.5vw;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 22px; /* Adjust the width of the custom checkbox */
  height: 22px; /* Adjust the height of the custom checkbox */
  border-radius: 50%; /* Make it round */
  border: 1px solid #6e6c6c; /* Add a border for visibility */
  outline: none; /* Remove the default outline */
  cursor: pointer;
  margin-left: 0;
}

/* Style the custom checkbox when checked var(--color-blue)  */
.allFilterComponent-product-shipping-div input[type="radio"]:checked {
  background-color: var(--color-green);
  /* border: none; */
  box-shadow: inset 0px 0px 0px 2.5px white;
  /* margin-left: 3.5px; */
}
.allFilterComponent-product-shipping-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.35vw 0;
}
.allFilterComponent-product-shipping-div div img {
  width: 1.2vw;
  margin-right: 0.5vw;
}

/*PRICE RANGE*/

.allFilterComponent-price-range-container {
  width: 100%;
  height: auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.55vh 0;
  padding-left: 1.5vw;
}
.allFilterComponent-price-range-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.55vh 0.5vw 0.5vh 0;
}
.allFilterComponent-price-range-heading-container svg {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  margin-right: 0.5vw;
}
.allFilterComponent-price-range-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
}

.allFilterComponent-price-range-filter-select-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2vh 0 1vh 0;
}

/* CUSTOMER RATING*/
.allFilterComponent-customer-ratings {
  width: 100%;
  height: auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.55vh 0;
  padding-left: 1.5vw;
}
.allFilterComponent-customer-ratings-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.55vh 0.5vw 0.5vh 0;
}
.allFilterComponent-customer-ratings-heading-container svg {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  margin-right: 0.5vw;
}
.allFilterComponent-customer-ratings input[type="radio"] {
  margin-right: 8px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px; /* Adjust the width of the custom checkbox */
  height: 18px; /* Adjust the height of the custom checkbox */ /* Make it round */
  border: 1px solid rgba(0, 0, 0, 0.8); /* Add a border for visibility */
  outline: none; /* Remove the default outline */
  cursor: pointer;
}

/* Style the custom checkbox when checked var(--color-blue)  */
.allFilterComponent-customer-ratings input[type="radio"]:checked {
  background-color: var(--color-blue);
  /* border: none; */
  box-shadow: inset 0px 0px 0px 2px white;
  border: 1px solid var(--color-blue);
  /* margin-left: 3.5px; */
}
.allFilterComponent-customer-ratings-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
}
.allFilterComponent-customer-ratings-div-container {
  width: 100%;
  height: auto;
  /* border-bottom: 2px solid var(--border-color); */
  padding: 1vh 0 1vh 0;
}
.allFilterComponent-customer-rating-div-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.3vw 0;
}
.allFilterComponent-customer-rating-div-row input {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  cursor: pointer;
}
.allFilterComponent-customer-rating-div-row input:checked {
  width: 18px;
  height: 18px;
  border-radius: 2px;
}
.allFilterComponent-customer-rating-div-row span {
  text-align: center;
  margin: 0 0 0 4px;
  font-weight: lighter;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
}

.input-star-div-container {
  min-width: 2.2vw;
  display: flex;
  align-items: center;
  background: var(--color-purple);
  border-radius: 2px;
  padding: 0.2vh 0.4vw;
  color: white;
  font-size: 12px;
}
.input-star-div-container svg {
  font-size: 14px;
  color: gold;
}
.allFilterComponent-dealer-margin-container {
  width: 100%;
  height: auto;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.55vh 0;
  padding-left: 1.5vw;
}
.allFilterComponent-dealer-margin-heading-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.55vh 0.5vw 0.5vh 0;
}
.allFilterComponent-dealer-margin-heading-container svg {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  cursor: pointer;
  margin-right: 0.5vw;
}
.allFilterComponent-dealer-margin-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
}
.allFilterComponent-dealer-margin-div-container {
  width: 100%;
  height: auto;
  /* border-bottom: 2px solid var(--border-color); */
  padding: 1vh 0 1vh 0;
}
.allFilterComponent-dealer-margin-div-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.3vw 0;
}
.allFilterComponent-dealer-margin-div-row input {
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  margin-bottom: 0.3vw;
  cursor: pointer;
}
.allFilterComponent-dealer-margin-div-row input:checked {
  width: 18px;
  height: 18px;
  border: 1px solid var(--color-blue);
  background: var(--color-blue);
  border-radius: 2px;
}
.allFilterComponent-dealer-margin-div-row span {
  text-align: center;
  margin: 0 0 0.05vw 0.1vw;
  font-weight: lighter;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
}
.selected {
  background-color: var(--color-blue) !important;
  color: white;
  /* @media (max-width: 500px) {
    background-color: transparent !important;
    color: black;
  } */
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .allFilterComponent-container {
    margin-top: 18px;
  }
  .allFilterComponent-customer-ratings-div-container {
    padding: 5px 0;
  }
}

@media screen and (max-width: 500px) {
  .allFilterComponent-container {
    display: none;
  }

  .allFilterComponent-customer-ratings-div-container {
    padding: 1vh 2vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .allFilterComponent-customer-ratings-div-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1vh 0;
  }
  .allFilterComponent-customer-ratings-div-row span {
    font-size: 3.8vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 0.5px;
  }
  .allFilterComponent-customer-ratings-div-row input {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0.4);
  }
  .allFilterComponent-customer-ratings-div-row input:checked {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }
  .input-star-div-container {
    font-size: 4vw;
    margin-right: 1vh;
    padding: 0.2vh 2vw 0.5vh 2vw;
    display: flex;
    align-items: center;
    margin-top: 0.4vh;
  }
  .allFilterComponent-dealer-margin-div-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .allFilterComponent-dealer-margin-div-row {
    width: auto;
    display: flex;
    margin: 1vh 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 2vh;
  }
  .allFilterComponent-dealer-margin-div-row span {
    font-size: 3.8vw;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: 0.5px;
  }
  .allFilterComponent-dealer-margin-div-row input {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0.4);
  }
  .allFilterComponent-dealer-margin-div-row input:checked {
    width: 20px;
    height: 20px;
    border-radius: 3px;
  }
  .input-star-div-container svg {
    font-size: 5vw;
    margin: 0 1px;
  }
  .allFilterComponent-price-range-filter-select-container {
    margin-top: 30px;
  }
}
