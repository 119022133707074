.mobile-price-breakdown-container {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 2vh 0;
  padding: 1vh 0;
  background: rgb(244, 250, 252);
}
.mobile-price-breakdown-top-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mobile-product-pricing-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vh 3vw;
  position: relative;
}
.mobile-product-pricing-p {
  font-size: 3.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  letter-spacing: 0.5px;
}
.mobile-product-pricing-span {
  font-size: 3.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: Space Grotesk, sans-serif;
}
.mobile-product-pricing-hr {
  position: absolute;
}
.mobile-product-estimated-date-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vh 0;
}
.mobile-product-estimated-date-container svg {
  font-size: 5vw;
  color: var(--color-green);
  margin-right: 2vw;
}
.mobile-estimated-data-span {
  font-size: 3.5vw;
  color: var(--color-green);
  font-weight: 600;
  font-family: Space Grotesk, sans-serif;
}
.mobile-product-stock-price-counter-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1vh 3vw;
}
.mobile-instock-total-price-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2vh 0 1vh 0;
}
.mobile-products-carts-instock-container {
  width: auto;
}
.mobile-products-carts-quantity-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mobile-products-carts-quantity-container svg {
  width: 4vh;
  height: 4vh;
  border-radius: 50px;
  padding: 0.2vh;
  font-size: 3vw;
}
.mobile-products-carts-qty-input {
  width: 4vh;
  height: 4vh;
  border-radius: 50px;
  background: white;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  text-align: center;
  margin: 0 3vw;
}
.mobile-products-totals-price {
  font-size: 4vw;
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.5px;
  font-weight: 700;
  font-family: Space Grotesk, sans-serif;
}
.mobile-product-inStock {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5vh;
}
.mobile-product-inStock svg {
  font-size: 5vw;
  color: var(--color-green);
  margin-right: 1vw;
}
.mobile-product-inStock span {
  font-size: 3.5vw;
  color: var(--color-green);
  font-weight: 600;
}
.mobile-product-request-more {
  background: var(--color-peach);
  border-radius: 25px;
  padding: 0.5vh 1.2vw;
  font-size: 2.5vw;
  color: white;
  margin-left: 2vw;
}
.mobile-max-inventery-count-request {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5vh;
}
.mobile-max-inventery-count {
  font-size: 2.8vw;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  letter-spacing: 0.2px;
}
.mobile-price-breakdown-add-cart-buy-container {
  width: 100vw;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: white;
  box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5vh 5vw;
}
.mobile-price-breakdown-cart-combination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-price-breakdown-select-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: var(--color-peach);
  font-size: 12px;
  margin-bottom: 1vh;
}
.mobile-price-breakdown-select-message sub{
    margin-top: -8px;
}
.mobile-price-breakdown-add-to-cart-btn {
  width: 48%;
  height: 6vh;
  background: white;
  border-radius: 4px;
  border: 2px solid var(--color-green);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5vh 1.5vw;
}
.mobile-price-breakdown-add-to-cart-main-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-price-breakdown-add-to-cart-btn svg {
  font-size: 5vw;
  color: var(--color-green);
  margin-right: 2vw;
}
.mobile-price-breakdown-add-to-cart-span {
  color: var(--color-green);
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 4vw;
}
.mobile-price-breakdown-buy-now-btn {
  width: 48%;
  height: 6vh;
  border-radius: 4px;
  background: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5vh 1.5vw;
}
.mobile-price-breakdown-buy-now-span {
  color: white;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 4vw;
}
