.listing-header-main-add-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.listing-header-page-left-container {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 0.5vw 1.5vh 1.5vw;
  background: white;
  position: relative;
}
.listing-header-page-left-heading {
  font-size: 18px;
  color: var(--color-blue);
  font-weight: 600;
}
.listing-header-page-left-clear-all {
  font-size: 0.9vw;
  color: var(--color-red);
  font-weight: 600;
  cursor: pointer;
}
.listing-header-page-merge-filter {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 0;
  z-index: 9999;
  background: white;
}
.listing-header-page-concatinate {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: height 0.5s;
}
.all-filter-added-component {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding: 0vh 2.5vw;
  padding-bottom: 10px;
  color: black;
  background: white;
}

/* Hide the scrollbar for .all-filter-added-component */
.all-filter-added-component::-webkit-scrollbar {
  display: none;
}
.all-filter-added-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow-x: scroll;
}
.all-filter-added-main-container::-webkit-scrollbar {
  display: none;
}

.all-filter-shipping-mode-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 4px;
  border-radius: 7px;
  margin-right: 5px;
  background: var(--color-blue);
  color: white;
  white-space: nowrap;
}
.all-filter-shipping-mode-container svg {
  font-size: 14px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: -0.1vw;
}
.all-filter-price-range-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 4px;
  border-radius: 25px;
  margin-right: 5px;
  border: 1px solid var(--color-blue);
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  white-space: nowrap;
}
.all-filter-price-range-container svg {
  font-size: 14px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: -0.1vw;
}
.all-filter-rating-range-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 4px;
  border-radius: 25px;
  margin-right: 5px;
  border: 1px solid var(--color-blue);
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  white-space: nowrap;
}
.all-filter-rating-range-container svg {
  font-size: 14px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: -0.1vw;
}
.all-filter-brands-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}
.all-filter-brand-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 4px;
  border-radius: 25px;
  margin-right: 5px;
  border: 1px solid var(--color-blue);
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  white-space: nowrap;
}
.all-filter-brand-container:hover {
  /* background: #f5f2f2; */
}
.all-filter-brand-container svg {
  font-size: 14px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: -0.1vw;
}

.all-filter-materials-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}
.all-filter-material-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 4px;
  border-radius: 25px;
  margin-right: 5px;
  border: 1px solid var(--color-blue);
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  white-space: nowrap;
}
.all-filter-material-container svg {
  font-size: 14px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: -0.1vw;
}
.all-filter-clear-container span {
  color: var(--color-red);
  font-size: 12px;
  opacity: 1;
  cursor: pointer;
  margin-left: 0.5vw;
  white-space: nowrap;
}
.all-filter-clear-container span:hover {
  opacity: 0.8;
}

.listing-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  color: black;
  text-transform: none;
  padding: 1vw 2vw;
  padding: 0 2vw;
  padding-top: 0;
  /* margin-top: 50px; */
}
.listing-header-filter-container {
  width: auto;
  margin-bottom: 1.1vw;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-div {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1.5px solid var(--border-color); */
  border-radius: 0px;
  padding: 0.3vw 0.35vw;
  cursor: pointer;
  font-size: 15px;
  margin-right: 35px;
  color: #5d5e5f;
  position: relative;
  /* border: 2px solid rgba(0,0,0,0.1); */
}
.filter-div svg {
  font-size: 20px;
  margin-left: 8px;
}

.sortby-container-add {
  width: 16vw;
  height: auto;
  position: relative;
  z-index: 9;
}
.brand-container-add {
  position: relative;
}
.bikes-container-add {
  position: relative;
}
.filter-container-add {
  position: relative;
}
.manufacture-container-add {
  position: relative;
}
.material-container-add {
  position: relative;
}
input[type="checkbox"] {
  accent-color: var(--color-green);
}

/*MOBILE VIEW CSS*/
.mobile-filter-sortby-upper-container {
  display: none;
}

.filter-added-number {
  background: var(--color-blue);
  border-radius: 5px;
  color: white;
  padding: 2px 4px;
}
.mobile-all-filter-added-component {
  display: none;
}

.mobile-all-filter-brand-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  padding: 6px;
  border-radius: 7px;
  margin-right: 5px;
  font-size: 0.85rem;
  background: var(--color-blue);
  color: white;
}

.mobile-all-filter-clear-container span {
  color: var(--color-red);
  cursor: pointer;
  margin-left: 8px;
  font-size: 0.85rem;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .listing-header-page-left-container {
    padding: 10px;
    width: 25%;
  }

  .listing-header-page-concatinate {
    width: 75%;
  }

  .filter-div {
    height: 40px;
  }
}

@media screen and (max-width: 500px) {
  .listing-header-main-add-container {
    display: none;
  }
  .listing-header-page-merge-filter {
    display: none;
  }
  .all-filter-added-component {
    overflow-x: scroll;
    padding: 0 0.5rem;
  }

  .all-filter-brand-container {
    width: auto;
  }

  .all-filter-brand-container span {
    display: block;
    width: auto;
    font-size: 0.9rem;
  }

  .all-filter-clear-container span {
    margin-left: 0;
  }
  .allFilterComponent-clearAll {
    display: block;
    width: auto;
  }

  .listing-header-container {
    padding: 1rem 0.5rem;
  }
  .listing-header-filter-container {
    /* display: none; */
    width: auto;
    justify-content: flex-start;
  }
  .filter-div {
    width: auto;
    margin-right: 10px;
    padding: 3px;
    font-size: 0.9rem;
  }
  .listing-header-sortBy {
    width: auto;
    margin-right: 5px;
    padding: 3px;
    font-size: 0.8rem;
  }

  /*MOBILE VIEW CSS*/
  .mobile-filter-sortby-upper-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: black;
    padding: 15px 15px;
    padding-top: 20px;
    background: white;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
    order: 0;
    position: relative;
    z-index: 10;
  }
  .mobile-filter-seperate-line {
    margin: 0 10px;
    color: gray;
  }
  .mobile-sortby-upper-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  .mobile-sortby-upper-div span {
    margin-left: 5px;
  }
  .mobile-filter-upper-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  .mobile-filter-upper-div span {
    margin-left: 5px;
  }

  .mobile-all-filter-added-component {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-x: scroll;
    padding: 15px 20px;
    /* margin-bottom: 5px; */
    background: white;
    order: 1;
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1) inset;
  }

  .mobile-all-filter-added-main-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow-x: scroll;
  }
  .mobile-all-filter-shipping-mode-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    border-radius: 7px;
    margin-right: 5px;
    font-size: 0.85rem;
    background: var(--color-blue);
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mobile-all-filter-shipping-mode-container svg {
    font-size: 1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-bottom: -2px;
  }
  .mobile-all-filter-price-range-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 6px;
    border-radius: 7px;
    margin-right: 5px;
    font-size: 0.85rem;
    background: var(--color-blue);
    color: white;
  }
  .mobile-all-filter-price-range-container svg {
    font-size: 1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-bottom: -2px;
  }
  .mobile-all-filter-rating-range-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;

    padding: 6px;
    border-radius: 7px;
    margin-right: 5px;
    font-size: 0.85rem;
    background: var(--color-blue);
    color: white;
  }
  .mobile-all-filter-rating-range-container svg {
    font-size: 1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-bottom: -2px;
  }
  .mobile-all-filter-brands-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 0.85rem;
  }
  .mobile-all-filter-brand-container {
    /* width: auto; */
  }
  .mobile-all-filter-brand-container svg {
    font-size: 1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-bottom: -2px;
  }
  .mobile-all-filter-brand-container span {
    display: block;

    font-size: 0.85rem;
  }
  .mobile-all-filter-materials-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mobile-all-filter-material-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    border-radius: 7px;
    margin-right: 5px;
    font-size: 0.85rem;
    background: var(--color-blue);
    color: white;
  }
  .mobile-all-filter-material-container svg {
    font-size: 1rem;
    cursor: pointer;
    margin-left: 6px;
    margin-bottom: -2px;
  }

  .mobile-all-filter-clear-container span {
    margin-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mobile-allFilterComponent-clearAll {
    display: block;
  }
}
