.view-order-detail-container {
  width: 100%;
  min-height: 50vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
}
.view-order-items-brands-logo {
  width: 60px;
  height: auto;
}
.view-order-detail-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}
.view-order-detail-header-left-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.view-order-detail-header-left-div svg {
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
}
.view-order-detail-header-orderID {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}
.view-order-detail-header-right-div {
  width: 150px;
  height: auto;

}
.view-order-detail-header-right-invoice-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  border-radius: 25px;
  border: 2px solid var(--color-blue);
  padding: 6px 12px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.view-order-detail-header-right-invoice-div svg {
  font-size: 1.2rem;
  color: var(--color-blue);
  margin-right: 5px;
}
.view-order-detail-header-right-invoice-div span {
  font-size: 1rem;
  color: var(--color-blue);
}
.view-order-order-tracking-detail-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 30px;
}
.view-order-order-tracking-date-delivery-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: 0.8rem;
}
.view-order-order-date-span {
  font-size: 0.8rem;
  font-weight: 500;
  color: black;
}
.view-order-order-date-span b {
  font-size: 0.9rem;
  font-weight: 700;
  color: black;
  margin-right: 10px;
}
.view-order-order-estimated-delivery-span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8rem;
  color: var(--color-green);
}
.view-order-order-estimated-delivery-span svg {
  font-size: 1.1rem;
  margin-left: 10px;
  margin-right: 5px;
}

.view-order-order-tracking-visual-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.view-order-order-tracking-visual-order-confirm-breadcrum-container {
  width: 100%;
  height: auto;
  padding: 15px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(65, 63, 63);
  background: white;
  display: flex;
}
/* .step-item */
.view-order-order-tracking-visual-order-confirm-step-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

/* .step-item:not(:first-child):before */
.view-order-order-tracking-visual-order-confirm-step-item:not(
    :first-child
  )::before {
  content: "";
  background-color: #cbd5e0;
  position: absolute;
  width: 100%;
  height: 5px;
  right: 50%;
  top: 15%;
  transform: translateY(-50%);
}

/* .step */
.view-order-order-tracking-visual-order-confirm-step {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  background-color: #cbd5e0;
  border-radius: 9999px;
  font-weight: 600;
  color: #fff;
}

.view-order-order-tracking-visual-order-confirm-step svg {
  color: black;
  font-size: 1.6rem;
}

.active .view-order-order-tracking-visual-order-confirm-step svg {
  color: white;
  font-size: 1.6rem;
}

/* .active .step */
.active .view-order-order-tracking-visual-order-confirm-step {
  background-color: var(--color-green);
}
.active .view-order-order-tracking-visual-order-confirm-text-gray {
  color: black;
  font-weight: 600;
  margin: 0;
  margin: 10px 0;
  font-size: 1rem;
}
.view-order-order-tracking-visual-order-confirm-text-date {
  color: rgb(105, 104, 104);
  font-weight: 500;
  font-size: 0.9rem;
  margin: 6px 0;
  margin-top: 0;
}
.active .view-order-order-tracking-visual-order-confirm-step svg {
  color: white;
  font-size: 1.5rem;
}

/* .complete .step */
.view-order-order-tracking-visual-order-confirm-complete
  .view-order-order-tracking-visual-order-confirm-step {
  background-color: var(--color-green);
}

.view-order-order-tracking-visual-order-confirm-text-gray {
  width: 80px;
  text-align: center;
  color: rgb(104, 103, 103);
  font-weight: 500;
  font-size: 1rem;
  margin: 10px 0;
}

/* .complete p */
.view-order-order-tracking-visual-order-confirm-complete p {
  color: rgb(104, 103, 103);
}
.view-order-order-tracking-visual-order-confirm-complete svg {
  color: white;
}

/* .complete:not(:first-child):before, .active:not(:first-child):before */
.view-order-order-tracking-visual-order-confirm-complete:not(
    :first-child
  )::before,
.active:not(:first-child)::before {
  background-color: var(--color-blue);
}

.view-order-items-and-payment-summary-detail-container {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-order-items-and-address-left-container {
  width: 62%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px;
}
.view-order-items-main-container {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.2);
}
.view-order-items-main-header-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 15px 15px 0 0;
  background: rgb(215, 215, 215);
}
.view-order-items-number {
  font-size: 0.9rem;
  font-weight: 700;
  color: black;
}
.view-order-items-order-again {
  background: var(--color-blue);
  color: white;
  padding: 6px 12px;
  border-radius: 25px;
  font-size: 0.88rem;
  cursor: pointer;
}
.view-order-items-each-orders-div {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 10px 20px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
.view-order-items-each-orders-div:last-child {
  border-bottom: none;
}
.view-order-items-each-orders-left-div {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.view-order-items-img-div {
  width: auto;
  height: auto;
  padding: 20px 0;
}
.view-order-items-img-div img {
  width: 100px;
  max-height: 120px;
  height: auto;
}
.view-order-items-product-details-div {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
}
.view-order-items-product-details-div img {
  width: 80px;
  height: auto;
}
.view-order-items-product-title-span {
  font-size: 0.88rem;
  font-weight: 700;
  color: black;
  width: 250px;
}
.track-order-btn {
  background: var(--color-blue);
  border-radius: 25px;
  padding: 0.8vh 0.8vw;
  color: white;
  text-decoration: none;
  margin-left: 8px;
}
.view-order-items-product-desc-span {
  font-size: 0.76rem;
  font-weight: 500;
  color: black;
  width: 250px;
  margin-top: 3px;
}

.view-order-items-each-product-variant-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 50%;
  transform: translateX(-20%);
}
.view-order-items-each-product-qty {
  font-size: 0.78rem;
  font-weight: 500;
  color: black;
  margin: 3px 0;
}
.view-order-items-each-product-qty b {
  font-size: 0.88rem;
  font-weight: 700;
  color: black;
}

.view-order-items-each-product-size {
  font-size: 0.78rem;
  font-weight: 500;
  color: black;
  margin: 3px 0;
}

.view-order-items-each-product-size b {
  font-size: 0.88rem;
  font-weight: 700;
  color: black;
}

.view-order-items-each-product-color {
  font-size: 0.78rem;
  font-weight: 500;
  color: black;
  margin: 3px 0;
}
.view-order-items-each-product-color b {
  font-size: 0.88rem;
  font-weight: 700;
  color: black;
}

.view-order-items-each-product-variant {
  font-size: 0.78rem;
  font-weight: 500;
  color: black;
  margin: 3px 0;
}
.view-order-items-each-product-variant b {
  font-size: 0.88rem;
  font-weight: 700;
  color: black;
}

.view-order-items-each-product-write-review-return-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.view-order-items-each-product-write-review-span {
  width: 110px;
  height: 28px;
  background: var(--color-purple);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  margin: 5px 0;
  font-size: 0.7rem;
  cursor: pointer;
}

.view-order-items-each-product-return-item-span {
  width: 110px;
  height: 28px;
  border: 2px solid var(--color-blue);
  color: var(--color-blue);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  font-size: 0.7rem;
  cursor: pointer;
  white-space: nowrap;
}

.view-order-items-address-div {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px;
}

.view-order-items-booking-address-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 20px;
}
.view-order-items-booking-address-div h3 {
  color: black;
  margin: 5px 0;
}
.view-order-items-booking-address-main-div {
  color: rgb(80, 79, 79);
  width: 250px;
  font-size: 0.9rem;
  line-height: 1.5;
}

.view-order-items-Delivery-address-div {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 20px;
}
.view-order-items-Delivery-address-div h3 {
  color: black;
  margin: 5px 0;
}
.view-order-items-Delivery-address-main-div {
  color: rgb(80, 79, 79);
  width: 250px;
  font-size: 0.9rem;
  line-height: 1.5;
}

.view-order-items-FAQ-questions-container {
  width: 100%;
}

.view-order-need-help-faq-container {
  width: 100%;
  padding: 0 40px;
}
.view-order-need-help-faq-container h3 {
  color: black;
}

.view-order-payment-and-order-summary-right-container {
  width: 38%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px;
}

.view-order-payment-main-div {
  width: 100%;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin: 10px 0;
  padding: 10px 20px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.view-order-payment-main-div h3 {
  color: black;
}
.view-order-payment-main-div img {
  width: 80px;
  height: auto;
}

.view-order-order-summary-right-container {
  width: 100%;
  padding: 10px 20px;
  border-radius: 15px;
  background: white;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}
.view-order-order-summary-right-container h3 {
  color: black;
}

.view-order-order-summary-right-container hr {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.view-order-summary-right-each-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(51, 51, 51);
  margin: 15px 0;
}

.have-question-about-your-order-div {
  width: 100%;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.have-question-about-your-order-div h3 {
  color: black;
  font-weight: 700;
  margin: 10px 0;
  font-size: 1.4rem;
}
.supporting-text {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(77, 77, 77);
  text-align: center;
  width: 60%;
  margin: 10px 0;
}

.contact-support-span {
  font-size: 1.3rem;
  font-weight: 700;
  color: black;
  margin: 10px 0;
  cursor: pointer;
}
.contact-support-span:hover {
  text-decoration: underline;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .view-order-items-and-payment-summary-detail-container {
    flex-direction: column;
  }
  .view-order-items-and-address-left-container {
    width: 100%;
  }

  .view-order-items-order-again {
    padding: 5px 10px;
    font-size: 12px;
  }
  .view-order-items-each-orders-div {
    padding: 5px 15px;
  }

  .view-order-items-img-div {
    padding: 10px 0;
  }
  .view-order-items-img-div img {
    width: 80px;
  }
  .view-order-items-product-details-div {
    margin-left: 15px;
  }
  .view-order-items-product-details-div img {
    width: 60px;
  }
  .view-order-items-product-title-span {
    width: 200px;
    font-size: 13px;
  }
  .track-order-btn {
    padding: 5px 10px;
    font-size: 12px;
  }
  .view-order-items-product-desc-span {
    width: 200px;
    font-size: 12px;
  }

  .view-order-items-each-product-variant-div {
    left: 55%;
  }
  .view-order-items-each-product-qty {
    font-size: 10px;
  }
  .view-order-items-each-product-qty b {
    font-size: 12px;
  }

  .view-order-items-each-product-size {
    font-size: 10px;
    width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .view-order-items-each-product-size b {
    font-size: 12px;
  }

  .view-order-items-each-product-color {
    font-size: 10px;
    width: 100px;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .view-order-items-each-product-color b {
    font-size: 12px;
  }

  .view-order-items-each-product-variant {
    font-size: 10px;
  }
  .view-order-items-each-product-variant b {
    font-size: 12px;
  }

  .view-order-items-each-product-write-review-return-div {
    margin-right: 15px;
  }
  .view-order-items-each-product-write-review-span {
    width: 100px;
    height: 25px;
    font-size: 10px;
  }

  .view-order-items-each-product-return-item-span {
    width: 100px;
    height: 25px;
    font-size: 10px;
  }

  .view-order-items-address-div {
    justify-content: space-between;
  }

  .view-order-items-FAQ-questions-container {
    display: none;
  }

  .view-order-payment-and-order-summary-right-container {
    width: 100%;
  }
}
