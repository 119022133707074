.listingpages-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
}
.listingpages-breadcrumb-container {
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}
.listingpages-cat-heading-container {
  width: auto;
  min-height: 6vh;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2vw;
}
.listingpages-cat-heading-span {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 800;
  letter-spacing: 1px;
}
.listingpages-search-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-result-for {
  font-size: 16px;
}
.search-result-for b {
  color: var(--color-peach);
}

.listingpages-search-cat-sub-cat-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 4vh;
}
.listingpages-search-cat-sub-cat-heading {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 1vw;
}

.listingpages-search-cat-sub-cat-main-container {
  width: 85vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 1;
}
.listingpages-search-cat-sub-cat-each-container {
  width: auto;
  height: auto;
  border-radius: 25px;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.2vw;
  cursor: pointer;
  margin: 0 1vw;
  margin-bottom: 2vh;
}
.listingpages-search-cat-sub-cat-each-container:hover {
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.3);
}

.listingpages-search-cat-sub-cat-img {
  width: 25px;
  height: 25px;
  padding: 0.1vw;
  border-radius: 50%;
  background: white;
}

.listingpages-search-cat-sub-cat-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  padding: 0 1vw 0 0.5vw;
}

.listingpages-store-heading-container {
  width: auto;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2vw;
}
.listingpages-sub-categories-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.listingpages-filter-and-product-container {
  width: 100%;
  height: auto;
  display: flex;
  /* align-items: start; */
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1);
  background: #f3f3f3;
}
.listingpages-side-filter-container {
  width: 15%;
  height: auto;
  /* position: sticky;
    top: 20vh; */
}
.listingpages-product-container {
  width: 85%;
  height: auto;
}
.products-headers-filter-container {
  width: 100%;
  /* height: 45px; */
  position: sticky;
  top: 110px;
  z-index: 999;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .listingpages-cat-heading-container {
    min-height: 30px;
  }

  .listingpages-search-cat-sub-cat-container {
    margin-top: 20px;
  }

  .listingpages-side-filter-container {
    width: 25%;
  }
  .listingpages-product-container {
    width: 75%;
  }
}
