.compatibility-container {
  color: black;
  text-transform: none;
  margin: 0 0;
  @media screen and (min-width: 501px) and (max-width: 900px) {
    margin: 0 15px;
  }
}
.compatibility-main-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.view-guide-div{
  width: auto;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-peach);
}
.view-guide-div:hover{
  background: var(--color-peach);
}
.view-guide-div:hover svg{
  color: white;
}
.view-guide-div:hover .view-guide{
  color:white;
}
.close-guide-div{
  width: auto;
  padding: 5px 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:var(--color-peach);
}
.close-guide{
  font-size: 13px;
  color: white;
}
.view-guide-div svg{
  font-size: 15px;
  color: var(--color-peach);
  margin-right: 4px;
}
.view-guide{
  font-size: 13px;
  color: var(--color-peach);
}

.compatibility-table-container {
  width: 100%;
  box-sizing: border-box;
  margin-top: 0;
  border-top: 0.0625rem solid var(--border-color);
}

.compatibility-container-row-odd {
  background-color: var(--color-blue);
}
.compatibility-container-row-odd td {
  font-size: 1.1rem;
  color: white;
  font-weight: 600;
}

.compatibility-container-data-key {
  color: var(--color-blue);
  width: 15rem;
  padding: 0.625rem 0.9375rem;
  border-right: 0.0625rem solid #dee1e7;
  /* font-family: 'Courier New', Courier, monospace; */
  font-weight: 700;
  font-size: 0.9rem;
}
.compatibility-container-data {
  padding: 0 0.9375rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: var(--color-blue);
}

@media screen and (max-width: 500px) {
  .compatibility-container {
    margin: 0;
    padding: 1vh 0;
  }
  .compatibility-container-h2 {
    text-align: start;
    font-size: 3.2vw;
    margin-left: 3vw;
    display: flex;
    align-items: center;
  }

  .compatibility-container-row-odd td {
    font-size: 0.8rem;
  }

  .compatibility-container-data-key {
    width: 50%;
    font-size: 0.9rem;
  }
  .compatibility-container-data {
    width: 50%;
    font-size: 0.9rem;
  }
  .discover-click-here{
    margin-right: 3vw;
  }
  .view-guide-div{
    padding: 0.3vh 1.5vw;
  }
  .view-guide-div svg{
    font-size: 4.5vw;
    margin-right: 1vw;
  }
  .view-guide{
    font-size: 2.5vw;
  }
  .close-guide-div{
    padding: 0.8vh 1.5vw;
  }
  .close-guide{
    font-size: 2.5vw;
  }
}
