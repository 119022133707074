@media (max-width: 500px) {
   .my-profile-mobile-security-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
   }
   .mobile-security-basic-detail-phone-email-verfication{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2vh 3vw;
    margin-top: 2vh;
   }
   .mobile-security-basic-detial-phone-em-verification-main-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
   }
   .mobile-security-basic-detail-heading{
    font-size: 5vw;
    color: rgba(0,0,0,0.8);
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 1vh;
   }
   .mobile-security-basic-detail-text{
    font-size: 3.8vw;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: rgba(0,0,0,0.8);
    margin: 0.5vh 0;
   }
  .mobile-security-basic-detail-phone-email-verification-btn{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2vh;
  }
  .mobile-security-basic-detail-phone-email-verification-btn a{
    width: 100%;
    padding: 1.5vh 2vw;
    background: var(--color-blue);
    color: white;
    font-size: 4vw;
    letter-spacing: 0.5px;
    font-weight: 700;
    text-align: center;
    border-radius: 4px;
  }
  .mobile-security-divider{
    margin: 1vh 0;
    color: rgba(0,0,0,0.8);
  }
  }