.wishlist-slider-container {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: #f3f3f3;
}
.wishlist-slider-main-container {
  width: 100%;
  min-height: 56vh;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1vw;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.5vw;
  overflow-x: scroll;
}
.wishlist-slider-card-container {
  min-width: 240px;
  /* width: 15vw; */
  max-width: 240px;
  height: auto;
  /* max-height: 50vh; */
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3 all ease;
  /* border: 1px solid #a5a5a5; */
  position: relative;
  /* margin: 1vh 1vw 0 1vw; */
  margin-bottom: 1vh;
  transition: transform 0.1s;
  overflow: hidden;
}
.wishlist-slider-card-product-badges-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 1;
  width: auto;
  height: auto;
}
.product-slider-card-product-badges {
  width: 20px;
  height: auto;
}
.wishlist-slider-card-favourite-container {
  position: absolute;
  top: 20px;
  right: -10px;
  z-index: 5;
  opacity: 0;
  transition: right 0.2s;
}
#product-slider-card-favourite-svg {
  width: 22px;
  /* font-size: 1.8vw;
    color: var(--color-peach); */
}
.wishlist-slider-card-container:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
}
.wishlist-slider-card-container:hover
  .wishlist-slider-card-favourite-container {
  right: 10px;
  opacity: 1;
}

.wishlist-slider-card-image-container {
  width: 100%;
  height: 265px;
  overflow: hidden;
}
.wishlist-slider-card-product-details {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px 8px 0 10px;
  /* padding-bottom: 1rem; */
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
}
.wishlist-slider-card-product-ratings-container {
  display: flex;
  align-items: center;
  background: rgb(244, 243, 243);
  padding: 2px 10px;
  border-radius: 4px;
  font-weight: 700;
  /* margin-bottom: 0.9vh; */
  justify-content: space-between;
  position: absolute;
  top: -8.5px;
  left: 10px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.wishlist-slider-card-product-rating {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
}
#product-slider-card-star-svg {
  color: rgb(225, 193, 8);
  font-size: 12px;
}
.wishlist-slider-card-product-rating-divider {
  position: relative;
  bottom: 0;
  /* margin-bottom: 5px; */
  font-size: 10px;
  font-weight: 500;
  margin: 0 1px;
  color: rgba(0, 0, 0, 0.8);
}
.wishlist-slider-card-product-no-of-rating {
  font-size: 12px;
  margin-left: 2px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}
.wishlist-slider-card-product-cart-container {
  position: absolute;
  right: 15px;
  top: -10px;
  opacity: 1;
  transition: right 0.2s;
}
#product-slider-card-cart-svg {
  font-size: 22px;
  color: white;
  background: var(--color-green);
  border-radius: 2px;
  padding: 2px;
}
/* .wishlist-slider-card-container:hover
    .wishlist-slider-card-product-cart-container {
    right: 1.4vw;
    opacity: 1;
  } */
.wishlist-slider-card-product-brand-img {
  width: 80px;
  height: 35px;
  /* margin-top: 5px; */
}
.wishlist-slider-card-product-brand-name {
  width: auto;
  height: 35px;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
}
.wishlist-slider-card-product-name {
  width: auto;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.wishlist-slider-card-product-name-span {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  color: var(--color-blue);
  text-transform: capitalize;
}
.wishlist-slider-card-product-price-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  /* padding-bottom: 0.5vh; */
  /* font-family:Space Grotesk, sans-serif; */
}
.wishlist-slider-card-product-current-price {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wishlist-slider-card-product-current-price-span {
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.8);
}
.current-price-b {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}
.wishlist-slider-card-product-offer-price {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 5px;
  color: var(--color-green);
}
.wishlist-slider-card-product-original-price {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}
.wishlist-slider-card-product-original-price-span {
  color: gray;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 12px;
}
.wishlist-slider-card-product-discount {
  background: #ba3737;
  color: white;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 8px;
  position: absolute;
  right: 8px;
  top: 10%;
  box-shadow: 1px 1px 2px 0 #bdbcbc;
}

.wishlist-slider-card-product-discount sup {
  font-size: 5px;
}
.product-slider-card-product-dealer-margin-ribbon {
  width: 100%;
  height: auto;
  /* position: absolute;
    top: calc(100% + 0.2vh);
    left: -0.8vw; */
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--color-blue);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  /* background-color: #007bff;
    color: #ffffff; */
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: letter-spacing 1s;
  padding: 6px 0;
}

.product-slider-card-product-dealer-margin-ribbon svg {
  font-size: 12px;
  margin-right: 3px;
}

.product-slider-card-product-dealer-margin-offer-ribbon {
  font-size: 10px;
  color: var(--color-green);
  font-weight: 500;
  margin: 0 3px;
}

.wishlist-slider-card-container:hover
  .product-slider-card-product-dealer-margin-ribbon {
  color: #ba3737;
  letter-spacing: 2px;
}
.wishlist-slider-card-container:hover
  .product-slider-card-product-dealer-margin-offer-ribbon {
  color: #ba3737;
  letter-spacing: 2px;
}

.not-visible-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
.not-visible-container svg {
  color: var(--color-green);
  font-size: 14px;
}
.wishlist-slider-card-container:hover .not-visible-container svg {
  color: var(--color-peach);
}
.not-visible-container span {
  font-size: 12px;
}
.login-to-see-price {
  font-size: 8px;
  font-weight: 600;
  color: white;
  background: var(--color-green);
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border-radius: 3px;
  padding: 4px 5px;
  transition: background 0.3s;
}
.login-to-see-price:hover {
  background: var(--color-peach);
}

@media screen and (min-width: 951px) and (max-width: 1250px) {
  .wishlist-slider-main-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}
@media screen and (min-width: 601px) and (max-width: 950px) {
  .wishlist-slider-main-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
@media screen and (min-width: 501px) and (max-width: 600px) {
  .wishlist-slider-main-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
