.header-container {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--color-blue);
}

.header-sidebar-burger-icon {
  display: none;
}

.header-logo-container {
  width: 15vw;
  padding: 13px 0.5vw;
}
.header-logo-container img {
  width: 100%;
  height: auto;
  margin-left: 0.5vw;
  cursor: pointer;
}

.header-search-container {
  width: 46vw;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 30px;
  background: white;
  color: black;
  position: relative;
  margin-left: 5vw;
  /* padding: 2px; */
}
.header-select-search-container {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 6px 10px; */
  border-right: 2px solid darkgray;
  position: relative;
  background: #f5f8fe;
  border-radius: 30px 0 0 30px;
}
.selected-ctg {
  font-size: 14px;
  width: 100%;
  font-weight: 700;
  cursor: pointer;
  padding-left: 0.6vw;
  padding-right: 0.25vw;
  color: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* text-align: center; */
}
.selected-ctg svg {
  margin-top: 3px;
  font-size: 1.4vw;
}
.select-option-container {
  position: absolute;
  top: 150%;
  left: -30%;
  width: 13vw;
  padding: 0.5vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  border-radius: 10px;
  z-index: 20;
  box-shadow: 0 0 3px 0px rgb(140, 139, 139);
}
.select-option-container span {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 0.85vw 0.6vw;
  margin: 2px 0;
  cursor: pointer;
  border-radius: 10px;
  text-align: start;
}
.select-option-container span:hover {
  background: var(--color-blue);
  opacity: 0.95;
  color: white;
}
.selected-vertical {
  background: var(--color-blue);
  color: white;
}
.header-select-search-container select {
  font-size: 14px;
  width: 100%;
  border: none;
  outline: none;
  font-weight: 700;
}
.header-search-container input {
  width: 70%;
  padding: 8px 10px;
  outline: #720372;
  border: none;
  color: black;
  font-weight: 600;
}
.header-search-container input::placeholder {
  color: darkgray;
  font-weight: 600;
  font-size: 14px;
}
.header-search-icon {
  position: absolute;
  right: 0;
  width: 38px;
  height: 38px;
  margin: 0.1vw;
  border-radius: 50px;
  background: var(--color-green);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-search-icon svg {
  font-size: 1.8rem;
  font-weight: 600;
  right: 0;
  bottom: 0;
  color: white;
}

.header-login-container {
  width: 32vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header-login-user-address-container {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-login-user-address-container svg {
  font-size: 1.8vw;
  margin-right: 0.5vw;
}
.header-login-user-main-address-span-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
}
.header-login-user-main-address-span-div span {
  margin: 2.5px 0;
}
.user-name-address {
  font-size: 0.8vw;
  font-weight: 500;
}
.user-location-address {
  font-size: 1.1vw;
}
.user-update-location {
  font-size: 0.95vw;
  font-weight: 700;
  cursor: pointer;
}

.user-pick-location {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.user-choose-your-location {
  width: 18vw;
  height: auto;
  position: absolute;
  top: 130%;
  left: 0;
  background: white;
  border-radius: 10px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px !important;
  /* border: 1px solid rgb(148, 148, 148) !important; */
}
.user-choose-your-location-main {
  width: 100%;
  padding: 0.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.user-choose-your-location h3 {
  margin: 0;
  background: #efefef;
  padding: 0.7vw;
  border-radius: 10px 10px 0 0;
  font-size: 1vw;
}
.user-join-new-para {
  font-size: 0.9vw;
  line-height: 1.3;
  font-weight: 600;
  margin: 0.55vw 0;
  margin-top: 0;
  word-spacing: 5px;
}
.user-join-now-btn {
  width: 100%;
  /* height: 35px; */
  padding: 0.65vw 0;
  text-align: center;
  background: #ffd700;
  border-radius: 10px;
  font-weight: 700;
  margin: 0;
  cursor: pointer;
}

.header-signup-login-container {
}
.header-signup-login-container span {
  width: 100px;
  height: 18px;
  padding: 10px 18px;
  background: var(--color-green);
  color: white;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.header-not-login-container {
  width: auto;
  min-width: 39%;
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-not-login-container span {
  background: var(--color-green);
  color: white;
  padding: 0.25vw 0.55vw;
  border-radius: 5px;
  font-size: 1vw;
  cursor: pointer;
}

.header-loged-in-container {
  width: auto;
  min-width: 39%;
  position: absolute;
  right: 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-user-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.85vw;
  margin-right: 0;
  position: relative;
}
.header-user-profile img {
  width: 2.4vw;
  height: 2.4vw;
  border-radius: 50%;
  /* margin-right: 8px; */
  cursor: pointer;
  background: white;
}
.header-user-profile span {
  /* font-size: 1.1rem; */
  /* font-weight: 600; */
  /* letter-spacing: 0.3px; */
  /* cursor: pointer; */
  /* color: white; */
  /* margin-left: 8px; */
}

.header-wishlist-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 0.9vw;
  margin-left: 0;
  margin-bottom: 3px;
}
.header-wishlist-container svg {
  color: white;
  font-size: 2vw;
}
.header-cart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin: 0 0.85vw;
}
.header-cart-container img {
  width: 30px;
  height: 30px;
}
.header-cart-container sup {
  width: 1.5vw;
  height: 1.5vw;
  position: absolute;
  top: -6px;
  left: 75%;
  color: white;
  font-size: 1vw;
  /* background: var(--color-gold); */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-support-container {
  margin: 0 0.9vw;
}
.header-support-container svg {
  cursor: pointer;
  font-size: 2vw;
}
.header-support-container img {
  width: 2.1vw;
  height: 2.1vw;
  cursor: pointer;
}

.dummy-login-btn-click {
  margin-left: 5px;
  font-size: 1.1vw;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1000;
}
@media screen and (max-width: 500px) {
  .header-container {
    width: 100%;
    height: 12vh;
  }

  .header-sidebar-burger-icon {
    display: block;
    position: absolute;
    bottom: 1vh;
    left: 3vw;
    width: auto;
    /* order: 2; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .header-sidebar-burger-icon svg {
    font-size: 10.4vw;
    padding: 0;
    position: relative;
    margin: 0;
  }

  .header-logo-container {
    order: 0;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    margin-left: 0;
  }
  .header-logo-container img {
    width: 38vw;
    margin: 0;
  }

  .header-search-container {
    order: 3; /* Change the order of search container to be second in the column layout */
    position: absolute;
    bottom: 1.2vh;
    width: 81.5%;
    height: 4.3vh;
    right: 4%;
  }
  .header-select-search-container {
    width: 27vw;
    /* height: 30px; */
    /* padding: 10px; */
    padding-left: 0;
    border-right: 2px solid rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 0;
    margin: 0;
  }
  .selected-ctg {
    font-size: 3.1vw;
    width: 100%;
    font-weight: 700;
    cursor: pointer;
    padding: 1.2vw 1.8vw;
    padding-right: 0;
    color: var(--color-blue);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* text-align: center; */
  }
  .select-option-container {
    position: absolute;
    top: 120%;
    left: 0%;
    width: 36vw;
    padding: 2vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: white;
    border-radius: 10px;
    z-index: 20;
  }
  .select-option-container span {
    width: 100%;
    font-size: 3.5vw;
    font-weight: 600;
    padding: 2.2vw 1.8vw;
    margin: 1vw 0;
    cursor: pointer;
    border-radius: 10px;
    text-align: start;
  }
  .select-option-container span:hover {
    background: var(--color-blue);
    opacity: 0.95;
    color: white;
  }
  .selected-vertical {
    background: var(--color-blue);
    color: white;
  }
  .header-select-search-container select {
    font-size: 2vw;
    width: 100%;
    border: none;
    outline: none;
    font-weight: 700;
  }
  .header-search-container input {
    width: 58%;
    padding: 1.8vw;
    outline: #720372;
    border: none;
    border-radius: 30px;
    color: black;
    font-weight: 600;
  }
  .header-search-container input::placeholder {
    color: darkgray;
    font-weight: 600;
    font-size: 3.2vw;
  }
  .header-search-icon {
    position: absolute;
    right: 0.3vw;
    width: 8vw;
    height: 8vw;
    border-radius: 50px;
    background: var(--color-green);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .header-search-icon svg {
    font-size: 5.5vw;
    font-weight: 600;
    right: 0;
    bottom: 0;
    color: white;
  }

  .header-login-container {
    margin: 0;
    width: 40%;
    height: auto;
    order: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 4vw;
    position: absolute;
    right: 4%;
    margin-left: 2vw;
  }
  .header-login-user-address-container {
    display: none;
  }

  .header-loged-in-container {
    width: auto;
    min-width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    /* padding-left: 15%; */
  }

  .header-wishlist-container {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 2vw;
  }
  .header-wishlist-container svg {
    color: white;
    font-size: 6.2vw;
  }
  .header-cart-container {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin: 0 2vw;
  }
  .header-cart-container-img {
    width: 7vw !important;
    height: 7vw !important;
    margin-top: 0.5vh;
  }
  .header-cart-container sup {
    width: 4.2vw;
    height: 4.2vw;
    position: absolute;
    top: -0.1vh;
    left: 60%;
    color: white;
    font-size: 3vw;
    /* background: var(--color-gold); */
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-support-container {
    display: none;
  }
  .dummy-login-btn-click {
    display: none;
  }

  .header-user-profile {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2vw;
  }
  .header-user-profile img {
    width: 8.8vw;
    height: 8.8vw;
    border-radius: 50px;
    cursor: pointer;
  }
  .header-user-profile-option-container {
    display: none;
  }

  .header-not-login-container {
    width: auto;
    min-width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
  }
  .header-not-login-container span {
    background: var(--color-green);
    color: white;
    padding: 1.4vw 2vw;
    border-radius: 5px;
    font-size: 3.6vw;
  }

  .overlay-container {
    width: 100%;
    min-height: 100vh;
    height: auto;
    overflow-y: scroll;
    z-index: 1000;
  }
}

.headers-container {
  width: 100%;
  height: 80px;
  padding: 0 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-blue);
  box-shadow: 0 3px 4px 0px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 1000;
}
.headers-left-logo-container {
  width: 7%;
  height: auto;
  /* padding: 2.8vh 0; */
}

.headers-left-website-logo {
  width: auto;
  height: 50px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* .headers-left-website-logo:hover {
  transform: scale(1.02);
} */

.headers-middle-vertical-and-search-container {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right:20px;
  /* padding-left: 8vw; */
  position: relative;
}
.headers-middle-vertical-container {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5vw 0 0;
}
.headers-middle-each-vertical-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.4vh 0;
  cursor: pointer;
}
.headers-middle-vertical-open-container {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}
.headers-middle-each-vertical-img {
  width: 2.4vw;
  height: 2.4vw;
  border-radius: 50%;
  background: white;
  padding: 0.3vw;
  cursor: pointer;
  margin: 0.5vh 0;
}
.headers-middle-each-vertical-name {
  font-size: 13px;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  /* margin-left: 0.5vw; */
  cursor: pointer;
}
.headers-middle-parts-vartical-hr {
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  /* border: 3px solid var(--color-blue); */
  padding: 0;
  margin: 0;
  transition: width 0.5s;
}
.headers-middle-accessories-vartical-hr {
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  /* border: 3px solid var(--color-blue); */
  padding: 0;
  margin: 0;
  transition: width 0.5s;
}
.headers-middle-maintenance-vartical-hr {
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  /* border: 3px solid var(--color-blue); */
  padding: 0;
  margin: 0;
  transition: width 0.5s;
}

#parts-vertical-active {
  width: 100%;
  border: 3px solid rgb(175, 187, 212);
}
#accessories-vertical-active {
  width: 100%;
  border: 3px solid var(--color-green);
}
#maintenance-vertical-active {
  width: 100%;
  border: 3px solid var(--color-peach);
}

#bikes-vertical-active {
  width: 100%;
  border: 3px solid var(--color-bike);
}

.headers-middle-search-container {
  min-width: 35%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  border-radius: 25px;
  padding: 0.15vw;
  z-index: 1000;
  position: relative;
  box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.1);
}
.headers-middle-search-main-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 25px;
  padding: 1vh 0;
  position: relative;
  z-index: 999;
}
.headers-middle-search-input {
  font-family: Space Grotesk, sans-serif;
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 600;
}
.headers-middle-search-input::placeholder {
  font-family: Space Grotesk, sans-serif;
  font-weight: 500;
  font-size: 0.85vw;
}
.headers-middle-search-big-search-container {
  position: absolute;
  width: calc(100% - 20px);
  left: 0;
  top: 50%;
  right: 0;
  z-index: 999;
}
#search-remove-cross {
  background: white;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.3vw;
  margin-right: 0.55vw;
}
.headers-middle-search-animation-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1vw;
  cursor: pointer;
}
.headers-middle-search-animation-search-for {
  font-size: 1vw;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}
.headers-middle-search-animation-parts {
  font-size: 1vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 0.3vw;
}
.headers-middle-search-animation-parts b {
  font-size: 1vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.1vw;
}
.headers-middle-search-input {
  width: 100%;
  border-radius: 25px;
  outline: none;
  border: none;
  padding-left: 1vw;
  transition: width 0.3s ease;
}

#headers-middle-search-icon {
  font-size: 2.2vw;
  border-radius: 50%;
  padding: 0.3vw;
  color: white;
  background: var(--color-green);
  cursor: pointer;
  position: relative;
  z-index: 1000;
}
.headers-right-container {
  width: 13%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headers-user-container {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3.2vh 0;
}
.headers-user-icon {
  width: 1.7vw;
  height: 1.7vw;
  color: white;
  cursor: pointer;
}

.headers-user-container svg {
  font-size: 1.7vw;
  font-weight: 500;
  cursor: pointer;
}
.headers-user-img {
  width: 2.2vw;
  height: 2.2vw;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
.headers-wishlist-container {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.2vh 0;
}
.headers-wishlist-filled-img {
  width: 1.9vw;
  /* height: 1.9vw; */
  color: white;
  cursor: pointer;
  transition: width 0.1s;
}
.headers-wishlist-filled-img:hover {
  width: 2.1vw;
  /* height: 2vw; */
}
.headers-wishlist-container svg {
  font-size: 1.9vw;
  cursor: pointer;
}
.headers-cart-container {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3.2vh 0;
}
.headers-cart-container sup {
  width: 1.4vw;
  max-width: 1.4vw;
  max-height: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 0.72vw;
  color: var(--color-blue);
  top: 15%;
  right: -15%;
  background: white;
  border-radius: 50%;
  padding: 0.3vw;
  z-index: 1;
}
.headers-cart-filled-img {
  width: 1.9vw;
  height: 1.9vw;
  color: white;
  cursor: pointer;
}
/* .headers-cart-filled-img:hover{
  width: 2vw;
  height: 2vw;
} */

.headers-cart-container svg {
  font-size: 1.9vw;
  cursor: pointer;
}
.headers-join-us-container {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2.2vh 0;
  margin-right: 0.5vw;
}
.headers-join-us-btn {
  font-size: 0.9vw;
  background: var(--color-green);
  color: white;
  border-radius: 0.3vw;
  padding: 0.35vw 0.8vw;
  cursor: pointer;
  transition: font-size 0.3s ease;
  text-align: center;
}
.headers-join-us-btn:hover {
  font-size: 0.95vw;
  box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.35);
}

.headers-login-user-address-container {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
}
.headers-login-user-address-main-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
.headers-login-user-address-container:hover {
  border: 2px solid rgba(255, 255, 255, 0.8);
}
.headers-login-user-address-container svg {
  font-size: 1.6vw;
  color: white;
}
.headers-login-user-main-address-span-div {
  width: 80%;
  text-align: start;
}
.headers-user-name-address {
  font-size: 0.6vw;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}
.headers-user-location-address {
  font-size: 0.65vw;
  font-weight: 600;
  color:white;
}
.user-address-component {
  position: absolute;
  top: 100%;
  /* right: 0; */
  width: 16vw;
  height: auto;
  background: white;
  border-radius: 15px;
  padding: 0.8vw;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
}

/*Header profile component*/
.headers-user-profile-option-container {
  position: absolute;
  top: 100%;
  width: 11vw;
  height: auto;
  background: white;
  color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
}
.headers-user-login-profile-inner {
  font-size: 0.88vw;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 700;
  letter-spacing: 0.5px;
  cursor: pointer;
  margin-left: 0.8vw;
}
.headers-user-login-profile-inner:hover {
  text-decoration: underline;
}
.headers-user-profile-option-img-name-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55vw;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.headers-user-profile-option-name-tag {
  font-size: 0.9vw;
  color: black;
  margin-left: 0.55vw;
  text-transform: capitalize;
}
.headers-user-profile-option-img-tag {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  border: 1px solid gray;
}
.headers-user-profile-option-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.headers-user-profile-option-my-profile-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55vw;
  cursor: pointer;
}
.headers-user-profile-option-my-profile-div span {
  font-size: 0.9vw;
}
.headers-user-profile-option-my-profile-div:hover {
  background: rgba(0, 0, 0, 0.1);
}
.headers-user-profile-option-my-profile-div svg {
  font-size: 1.6vw;
  margin-right: 0.55vw;
}
.headers-user-profile-option-logout-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55vw;
  cursor: pointer;
  background: var(--color-peach);
  border-radius: 0 0 10px 10px;
  color: white;
}
.headers-user-profile-option-logout-div:hover {
  background: #ae3232;
}
.headers-user-profile-option-logout-div svg {
  font-size: 1.6vw;
  margin-right: 0.55vw;
}
.headers-user-profile-option-logout-div span {
  font-size: 0.95vw;
}

/* MINI CART*/
.mini-cart-container {
  width: 16vw;
  height: auto;
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 10px;
  padding: 0.55vw;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 6px 8px 2px !important;
}

.mini-cart-each-product-div {
  width: 100%;
  height: 14vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: rgb(246, 244, 244);
  margin: 0.3vw 0;
  border-radius: 10px;
  padding: 0.3vw;
}
.mini-cart-each-product-div:hover {
  background: rgb(248, 244, 244);
}
.mini-cart-each-product-main-img {
  width: 30%;
  height: 80%;
  border-radius: 10px;
}

.mini-cart-each-product-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 0.5vw;
}
.mini-cart-each-product-div svg {
  font-size: 1.2vw;
}
.remove-cross {
  position: absolute;
  top: 0.55vw;
  right: 0.55vw;
  cursor: pointer;
}
.mini-carts-product-brand-img {
  width: 5vw;
  height: 4vh;
  margin-top: -15px;
  margin-left: -3px;
  object-fit: contain;
}
.mini-carts-product-brand-name {
  font-size: 0.9vw;
  color: black;
  letter-spacing: 0.5px;
}
.mini-cart-each-product-name {
  font-size: 0.85vw;
  margin-bottom: 0.25vw;
}
.mini-cart-each-product-qty-price {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8vw;
}

.view-cart-btn {
  width: 100%;
  height: 5vh;
  border-radius: 10px;
  background: var(--color-blue);
  color: white;
  font-size: 1.1vw;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 0.26vw;
}

/* USER ADDRESS CHOOSE*/
.user-added-address-container {
  width: 100%;
  height: auto;
  min-height: 100px;
  border: 2px solid var(--color-green);
  background: #28a44733;
  border-radius: 10px;
  padding: 0.55vw;
  cursor: pointer;
  word-spacing: 3px;
  color: black;
}
.user-added-firstName {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 0.9vw;
}
.user-added-address-1 {
  text-transform: capitalize;
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.8);
}
.user-added-address-2 {
  text-transform: capitalize;
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.8);
}
.user-added-city {
  margin: 0;
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.8);
}
.user-added-district {
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.8);
}
.user-added-pin_code {
  font-size: 0.85vw;
  color: rgba(0, 0, 0, 0.8);
}
.user-address-add-more-div {
  width: 100%;
  font-size: 0.75vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #0773b0;
  margin-top: 0.55vw;
}
.user-address-add-more-div svg {
  font-size: 1.4vw;
  margin-right: 0.3vw;
}

.search-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
}
.navigations-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .headers-container {
    height: 80px;
    padding: 0 10px;
  }

  .headers-left-website-logo {
    height: 40px;
  }

  .headers-middle-vertical-and-search-container {
    padding-right: 20px;
  }
  .headers-middle-vertical-container {
    margin: 0 20px 0 0;
  }
  .headers-middle-each-vertical-container {
    padding: 17px 0;
  }

  .headers-middle-each-vertical-img {
    width: 25px;
    height: 25px;
    margin: 4px 0;
  }
  .headers-middle-each-vertical-name {
    font-size: 10px;
  }

  #parts-vertical-active {
    border-width: 1px;
  }
  #accessories-vertical-active {
    border-width: 1px;
  }
  #maintenance-vertical-active {
    border-width: 1px;
  }

  .headers-middle-search-container {
    padding: 2px;
  }
  .headers-middle-search-main-container {
    padding: 6px 0;
  }
  .headers-middle-search-input {
    font-size: 10px;
  }
  .headers-middle-search-input::placeholder {
    font-size: 10px;
  }
  .headers-middle-search-big-search-container {
    /* width: 64.5vw; */
  }
  #search-remove-cross {
    font-size: 15px;
  }
  .headers-middle-search-animation-container {
    padding-left: 5px;
  }
  .headers-middle-search-animation-search-for {
    font-size: 10px;
  }
  .headers-middle-search-animation-parts {
    font-size: 10px;
    margin-left: 4px;
  }
  .headers-middle-search-animation-parts b {
    font-size: 10px;
  }
  .headers-middle-search-input {
    padding-left: 8px;
  }

  #headers-middle-search-icon {
    font-size: 25px;
  }
  .headers-right-container {
    width: 15%;
  }

  .headers-user-icon {
    width: 22px;
    height: 22px;
  }

  .headers-user-container svg {
    font-size: 25px;
  }
  .headers-user-img {
    width: 25px;
    height: 25px;
  }

  .headers-wishlist-filled-img {
    width: 20px;
  }
  .headers-wishlist-filled-img:hover {
    width: 22px;
  }
  .headers-wishlist-container svg {
    font-size: 20px;
  }

  .headers-cart-container sup {
    width: 15px;
    max-width: 15px;
    max-height: 15px;
    top: 28%;
    font-size: 12px;
    right: -10%;
  }
  .headers-cart-filled-img {
    width: 20px;
    height: 20px;
  }

  .headers-cart-container svg {
    font-size: 20px;
  }
  .headers-join-us-container {
    display: none;
    padding: 20px 0;
    height: 80px;
  }
  .headers-join-us-btn {
    font-size: 12px;
    padding: 3px 8px;
  }
  .headers-join-us-btn:hover {
    font-size: 13px;
  }

  .headers-login-user-address-container {
    padding: 5px;
    display: none;
  }

  .headers-login-user-address-container svg {
    font-size: 18px;
  }

  .headers-user-name-address {
    font-size: 8px;
  }
  .headers-user-location-address {
    border-width: 1px;
  }

  /*Header profile component*/
  .headers-user-profile-option-container {
    width: 150px;
  }
  .headers-user-login-profile-inner {
    font-size: 12px;
    margin-left: 8px;
  }

  .headers-user-profile-option-img-name-div {
    padding: 4px;
  }
  .headers-user-profile-option-name-tag {
    font-size: 12px;
    margin-left: 4px;
  }
  .headers-user-profile-option-img-tag {
    width: 25px;
    height: 25px;
  }

  .headers-user-profile-option-my-profile-div {
    padding: 8px;
  }
  .headers-user-profile-option-my-profile-div span {
    font-size: 12px;
  }

  .headers-user-profile-option-my-profile-div svg {
    font-size: 18px;
    margin-right: 4px;
  }
  .headers-user-profile-option-logout-div {
    padding: 8px;
  }

  .headers-user-profile-option-logout-div svg {
    font-size: 18px;
    margin-right: 4px;
  }
  .headers-user-profile-option-logout-div span {
    font-size: 12px;
  }

  /* MINI CART*/
  .mini-cart-container {
    width: 200px;
    padding: 5px;
  }

  .mini-cart-each-product-div {
    height: 90px;
    margin: 4px 0;
    padding: 3px;
  }

  .mini-cart-each-product-details {
    margin-left: 4px;
  }
  .mini-cart-each-product-div svg {
    font-size: 15px;
  }
  .remove-cross {
    top: 5px;
    right: 5px;
  }
  .mini-carts-product-brand-img {
    width: 50px;
    height: 25px;
  }
  .mini-carts-product-brand-name {
    font-size: 12px;
  }
  .mini-cart-each-product-name {
    font-size: 12px;
    margin-bottom: 3px;
  }
  .mini-cart-each-product-qty-price {
    font-size: 13px;
  }

  .view-cart-btn {
    height: 35px;
    font-size: 15px;
    margin-top: 3px;
  }
}
