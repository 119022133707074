.granite-container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 10px;
  }
  
  .granite-container h2 {
    color: rgba(0,0,0,0.8);
    font-size: 16px;
    margin: 10px 0;
  }
  
  .granite-container h3 {
    color: #555;
    font-size: 16px;
    margin: 0;
  }
  
  .granite-container h4 {
    color: #555;
    font-size: 15px;
    margin: 0;
    margin: 10px 0;
    margin-left: 6px;
  }
  
  .granite-container p {
    color: #777;
    line-height: 1.3;
    font-size: 13.8px;
    margin: 10px 0;
    margin-left: 8px;
  }
  
  .granite-container ul {
    list-style-type: disc;
  }
  
  .product-lineup > div {
    margin-top: 10px;
  }
  
  .attributes > ul > li {
    color: #777;
    font-size: 13.8px;
    line-height: 1.3;
    text-transform: capitalize;
  }
  
  .partnership {
    margin-top: 20px;
  }
  
