.subCategories-container {
  width: 100%;
  height: auto;
  background: white;
  padding: 2vh 2vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4vh;
}
.brands-banner-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -40px;
}
.listingpages-store-heading-span {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  letter-spacing: 0.1px;
  white-space: nowrap;
}
.subCategories-main-container {
  width: auto;
  min-height: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.subCategories-each-subcategory-container {
  width: auto;
  height: auto;
  border-radius: 25px;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.2vw;
  cursor: pointer;
  margin: 0 1vw 0 1vw;
}
.subCategories-each-subcategory-container:hover {
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
}
.subCategories-each-subcategory-img {
  width: 25px;
  height: 25px;
  padding: 0.1vw;
  border-radius: 50%;
  background: white;
}

.subCategories-each-subcategory-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  padding: 0 1vw 0 0.5vw;
}

.selected-img {
  background: var(--color-blue);
  color: white;
}
.selected-img span {
  color: white;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .subCategories-container {
    margin-bottom: 0;
  }

  .subCategories-main-container {
    min-height: 30px;
  }
  .subCategories-each-subcategory-container {
    padding: 3px;
  }

  .subCategories-each-subcategory-name {
    padding: 0 10px 0 5px;
  }
}
