@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Orbitron:wght@400;500;600&family=Pacifico&family=Poppins&display=swap");
.product-details {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 1.8vw 2.5vw 0px 2.5vw;
  display: flex;
  align-items: start;
  gap: 30px;
  /* grid-template-columns: 1.1fr 0.9fr;
  gap: 30px; */
}
.product-details .product-page-img {
  position: sticky;
  width: 100%;
  /* padding: 5px; */
  overflow: hidden;
}

.product-page-img .big-images {
  width: 100%;
  height: 440px;
  overflow: hidden;
  position: relative;
  /* border: 2px solid var(--border-color); */
}

.product-page-img .prev,
.product-page-img .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3vw;
  height: 3vw;
  display: flex;
  font-size: 1.6vw;
  padding-bottom: 0.25vw;
  padding-right: 0.25vw;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--color-blue);
  user-select: none;
  transition: all ease 0.3s; /* Added transition here */
  z-index: 1;
}

.product-page-img .next {
  right: 0;
}

.product-page-img .prev:hover,
.product-page-img .next:hover {
  background: rgb(1, 47, 85);
  /* width: 3.1rem;
  height: 3.1rem;
  padding-right: 3.2px;
  padding-bottom: 3.2px; */
  zoom: 1.05;
  transition: all ease 0.3s;
}

.product-page-img .slider-img {
  width: 100%;
  height: auto;
  /* overflow-x: scroll; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  user-select: none;
  margin-top: 0.3vw;
  padding: 0 0.3vw;
}
.product-page-img .slider-img::-webkit-scrollbar {
  display: none;
}
.product-page-img .slider-img .slider-box {
  width: 8vw;
  height: 8vw;
  min-width: 50px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  margin-top: 1vw;
  margin-right: 1.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  /* border-radius: 12px; */
  padding: 0.55vw;
  opacity: 0.5;
}
.product-page-img .slider-img .slider-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: inline-block;
}

.product-page-img .slider-img .slider-box.active {
  border: 2px solid #003765;
  opacity: 1;
}
.product-page-img .slider-img .slider-box:hover {
  border: 2px solid #003765;
  opacity: 1;
}

.slick-dots li {
  margin: 0;
}

.servicePromices-container {
  width: 35vw;
  height: 150px;
  margin: 50px auto 0px auto;
}
.servicePromices {
  width: 33vw !important;
  height: 12vh;
  margin: 0.55vw;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0.55vw;
  background: #f5f8fe;
  border: 2px solid var(--color-blue);
  border-radius: 8px;
  color: black;
  text-transform: none;
  font-size: 0.75vw;
  margin: auto;
}
.servicePromices img {
  width: 3.5vw;
  height: 3.5vw;
  margin-left: 1vw;
}
.servicePromices-text {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 0.55vw;
}
.servicePromices-heading {
  font-size: 1.15vw;
  font-weight: 600;
  margin-bottom: 0.3vw;
  color: var(--color-blue);
}
.servicePromices-desc {
  width: 100%;
  font-size: 0.85vw;
  text-align: start;
  color: #474545;
  font-weight: 500;
}
.servicePromices .slick-dots li button:before {
  font-size: 0.6vw !important;
  color: var(--color-blue);
}
.servicePromices .slick-dots .slick-active button:before {
  font-size: 0.8vw !important;
  color: var(--color-blue);
}

/* Product-page-details */
.product-page-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding-bottom: 1.1vw;
  flex: 1;
  position: relative;
}
.product-page-details-scroll {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.product-slider {
  width: 60%;
  position: sticky;
  top: 19vh;
  height: 100%;
  /* width: 100%; */
  overflow-y: auto;
  z-index: 1 !important;
}
.product-slider::-webkit-scrollbar {
  display: none;
}
.product-page-details .product-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.product-container-detail {
  margin-bottom: 1.1vw;
  position: relative;
}
.product-container-comming-soon {
  width: auto;
  height: 20px;
  position: absolute;
  right: 0;
}
.product-tech-li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: black;
  font-size: 1.1vw;
}
.product-tech-li span {
  margin: 2px 3px;
}
.product-page-details .product-container .product-fav-share {
  width: 48%;
  /* height: 120px; */
  padding-left: 3.2vw;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1vh;
  right: 1.1vw;
  justify-content: space-between;
}
.product-page-details .product-container .product-fav-share .favourite {
  width: 50%;
  /* padding-right:15px; */
  display: flex;
  /* margin-bottom: 18px; */
  font-size: 1vw;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  color: #800080;
  font-weight: 550;
  white-space: nowrap;
}
.product-page-details .product-container .product-fav-share .favourite img {
  width: 1.5vw;
}

.product-page-details .product-container .product-fav-share .share {
  width: 40%;
  /* margin: 18px 35px 0 0; */
  display: flex;
  align-items: center;
  font-size: 1vw;
  justify-content: flex-start;
  text-transform: capitalize;
  color: #800080;
  font-weight: 550;
}

.product-page-details .product-container .product-fav-share .share svg {
  font-size: 1.6vw;
}

.product-page-details strong {
  color: var(--color-blue);
  font-size: 1.6vw;
  font-weight: 800;
  text-transform: none;
  /* font-style: italic; */
  /* font-family: "Orbitron", sans-serif; */
}
.product-container-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.product-container-detail-img {
  width: 100px;
  /* height: 5vh; */
  object-fit: contain;
}
.product-page-details .product-category {
  font-size: 1.4vw;
  color: black;
  /* font-weight: bold; */
  text-transform: capitalize;
  font-style: italic;
  /* font-family: "Orbitron", sans-serif; */
  margin: 0;
  margin-bottom: 0.55vw;
  font-weight: 400;
}
.product-page-details .product-rating {
  color: gold;
  display: flex;
  align-items: center;
  font-size: 0.9vw;
  margin-top: 1vh;
}
.product-page-details .product-rating p {
  color: gray;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-left: 0.3vw;
}
.product-page-details .mobile-product-rating {
  display: none;
}

.product-page-details .product-price-detail {
  width: 36vw;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.1vw;
}
.product-price-detail-share-pop {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.product-price {
  color: #0e0e0e;
  margin-top: 0.54vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0;
  margin-right: 0.95vw;
  font-size: 2.1vw;
}
.product-price-del {
  color: gray;
  font-weight: 500;
  font-size: 1vw;
  margin-bottom: 0.4vw;
}
.product-price-discount {
  padding: 4.5px 9px;
  border-radius: 5px;
  font-size: 0.88vw;
  /* margin-left: 30px; */
  color: white;
  background: #ba3737;
  font-weight: 500;
}
.product-select-tabs-container {
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: none;
  padding: 1vw 0;
  font-weight: 700;
}
.product-select-tabs-container .product-select-tab {
  border: 1px solid var(--border-color);
  color: #595757;
  height: 40px;
  font-size: 1.05vw;
  min-width: 78px;
  border-radius: 10px;
  padding: 2px 2px;
  text-align: center;
  margin-right: 0.5vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-tabs-value {
  padding: 8.8px 5px;
  border-radius: 8px;
  justify-content: flex-end;
  color: white;
  font-size: 0.8vw;
  margin-left: 0.5vw;
  background: #ba3737;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.discount-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.5vw;
}
.inclusive {
  color: black;
  font-size: 0.85vw;
  font-weight: 400;
  text-transform: none;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 1.1vw;
}
.product-page-details .product-size-variant-color {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  color: #4b4b4b;
  font-size: 0.88vw;
  font-weight: 400;
  padding: 0.55vw 0;
  text-transform: none;
}
.product-page-details .product-size-variant-color .product-size {
  height: 140px;
  display: flex;
  flex-direction: column;
}
.product-page-details .product-size-variant-color .product-size select {
  width: 100%;
  margin: 0 0.9vw;
  padding: 0.55vw;
  outline: none;
  background: white;
  font-size: 1.1vw;
  border-radius: 8px;
  font-weight: 550;
  color: #807e7e;
  border: 2px solid #595757;
}
.product-page-details .product-size-variant-color .product-size select option {
  padding: 0.5vw;
  font-weight: 600;
}
.product-page-details .product-size-variant-color .product-size label {
  font-size: 1vw;
  color: #1f1f1f;
  font-weight: 600;
  margin-right: 0.45vw;
  margin-bottom: 0.55vw;
}
.product-page-details .product-options {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.product-page-details .product-options .product-colors {
  height: 110.8px;
  display: flex;
  justify-content: flex-start;
}
.product-page-details .product-options .product-colors div {
  width: 38px;
  height: 38px;
  margin-right: 0.55vw;
}
.product-page-details .product-options .product-colors div.active {
  width: 36px;
  height: 36px;
  border: 2px solid #ba3737;
  border-radius: 50px;
  position: relative;
}
.product-page-details .product-options span {
  font-size: 1rem;
  color: #1f1f1f;
  font-weight: 600;
  margin-right: 7px;
  margin-bottom: 10px;
}

.product-page-details .product-options button {
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  margin-right: 10px;
  position: relative;
  left: 2.2px;
  top: 2px;
}
.product-page-details .product-options button.active {
  width: 29px;
  height: 29px;
  left: 2.2px;
  top: 2px;
}
.product-page-details .product-varients-container {
  width: 35vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-page-details .product-varient-container {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  width: 110px;
  height: 140px;
  padding: 0 2px;
  /* margin-right: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.product-page-details .product-varient-container img {
  width: 95px;
  height: 110px;
  padding: 20px 20px 0px 20px;
}
.product-page-details .product-varient-name {
  color: #595757;
  font-size: 0.9rem;
  padding: 3px 0 10px 0;
}
.color-active {
  border: 2px solid black;
}
.product-page-details .product-purchase {
  width: 41vw;
  min-height: auto;
  background-color: #f5f8fe;
  /* border: 2px solid var(--color-blue); */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  margin: auto;
  /* margin-top: 20px; */
}
.product-page-details .product-purchase .product-pricing-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: black;
  text-transform: none;
  font-size: 0.95rem;
  padding: 0 10px;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 1rem;
  @media screen and (min-width: 501px) and (max-width: 850px) {
    font-size: 13px;
  }
}
.shipping-mode-mobile {
  position: relative;
}

.product-pricing-div hr {
  position: absolute;
  left: 50%;
  width: 6px;
  border: 1.5px solid #595757;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  p {
  margin: 9.5px 0;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  svg {
  position: relative;
  right: 110px;
  width: 22px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #800080;
}
.info-shippingInfo-container {
  position: absolute;
  bottom: 200%;
  left: 22%;
}

.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  .shipping-select {
  width: 158px;
  padding: 7px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: white;
  padding-left: 9px;
  border-radius: 8px;
  font-weight: 550;
  font-size: 0.95rem;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  .shipping-select
  img {
  width: 17.5px;
  margin-right: 3px;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  .shipping-select
  svg {
  position: static;
  margin-left: 13px;
  color: black;
}

.User-login-page-product-page-container {
  width: 36vw;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: black;
  background: #f5f8fe;
  border-radius: 8px;
  padding: 4.2% 8%;
  text-transform: none;
  margin: auto;
  /* border: 2.5px solid var(--color-blue); */
}
.user-login-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.user-login-text-container p {
  font-size: 1.35rem;
  color: black;
  font-weight: 700;
  text-align: center;
  margin: 0;
  margin-top: 5px;
}
.user-login-text-container span {
  font-size: 1rem;
  color: rgb(70, 68, 68);
  text-align: center;
  margin: 10px 0 5px 0;
}
.user-login-tabs-container {
  width: 286px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 22px;
  padding: 4.5px;
  margin-bottom: 28px;
  box-shadow: 0px 0px 15px 0px rgb(153, 151, 151);
}
.user-login-tabs-container span {
  width: 134px;
  height: 40px;
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
}
.active-tab {
  width: 134px;
  height: 40px;
  background: #ba3737;
  color: white;
  text-align: center;
  border-radius: 20px;
}
.dealership-page-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.register-user-cyclecircle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.register-user-cyclecircle p {
  font-size: 0.88rem;
  padding: 5px 0;
  color: #595757;
  margin: 0;
}
.register-user-cyclecircle h3 {
  text-align: start;
  font-weight: 500;
}
.dealership-page-detail span {
  padding: 4.5px 0;
}
.customer-page-detail {
  height: 207px;
}
.customer-page-detail p {
  font-size: 1.05rem;
  color: #595757;
  padding: 4.5px 0;
}
.customer-page-detail span {
  padding: 4.5px 0;
  font-size: 1.05rem;
}
.dealership-login-now {
  background: var(--color-green);
  width: 100%;
  height: 45px;
  color: white;
  font-size: 1.1rem;
  font-weight: 550;
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
.dealership-register-now {
  background: var(--color-blue);
  width: 100%;
  height: 45px;
  font-size: 1.1rem;
  font-weight: 550;
  color: white;
  padding: 10px;
  outline: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
.customer-search-pincode {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.customer-search-pincode input {
  width: 62%;
  height: 45px;
  padding: 13px;
  outline: none;
  border: none;
  border-radius: 6px 0 0 6px;
  border: 2px solid gray;
  border-right: none;
  font-size: 1.1rem;
}
.customer-search-pincode input::placeholder {
  color: rgb(197, 195, 195);
}
.customer-search-pincode p {
  margin: 0;
}
.customer-search-pincode button {
  width: 38%;
  height: 45px;
  background: var(--color-green);
  padding: 13px 11px;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
}

.request-submitted-success {
  width: 260px;
  height: 60px;
  position: fixed;
  top: 100px;
  right: -260px; /* Start from the right */
  /* border-radius: 150px 0 40px 56px; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  background: var(--color-green);
  padding: 10px;
  color: white;
  overflow: hidden;
  transition: right 0.3s ease-in-out;
}

.request-submitted-success.open {
  right: 10px; /* Move to the right */
}

.request-submitted-success iframe {
  margin-right: 10px;
}

.product-pricing-div-container {
  position: relative;
  z-index: 9999;
}
.product-shipping-div {
  position: absolute;
  width: 160px;
  background: white;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 6px;
  right: 0;
  left: 104px;
  top: 2px;
}

input[type="radio"] {
  margin-right: 8px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* Adjust the width of the custom checkbox */
  height: 20px; /* Adjust the height of the custom checkbox */
  border-radius: 50%; /* Make it round */
  border: 2px solid #6e6c6c; /* Add a border for visibility */
  outline: none; /* Remove the default outline */
  cursor: pointer;
}

/* Style the custom checkbox when checked var(--color-blue)  */
input[type="radio"]:checked {
  background-color: var(--color-green);
  /* border: none; */
  box-shadow: inset 0px 0px 0px 2px white;
  border: 2px solid var(--color-green);
  /* margin-left: 3.5px; */
}
.product-shipping-div div {
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
/* .product-shipping-div div:hover{
  background: var(--color-green);
} */
.product-shipping-div div img {
  width: 18px;
  margin-right: 5px;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  select
  option {
  cursor: pointer;
}
.product-page-details
  .product-purchase
  .product-pricing-details
  .product-pricing-div
  .total-price-text {
  font-weight: bold;
  font-size: 1.28rem;
  color: var(--color-blue);
}
.product-page-details .product-purchase .product-carts {
  width: 98.2%;
  min-height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  border-radius: 8px;
  padding: 9px 5px 5px 5px;
  margin: 5px auto;
  text-transform: none;
  border: 2px solid var(--color-blue);
}

.product-inStock {
  display: flex;
  align-items: center;
  color: var(--color-green);
  margin-top: 5px;
}
.product-inStock span {
  margin-left: 10px;
}

.max-inventery-count-request {
  width: auto;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
.max-inventery-count {
  color: black;
  font-size: 0.75rem;
  text-transform: none;
  margin-bottom: 3px;
  /* margin-top: -3px; */
}
.product-request-more {
  margin: auto;
  padding: 3.5px 8px;
  border-radius: 25px;
  font-weight: lighter;
  font-size: 10px;
  color: white;
  text-transform: none;
  background: #bd001c;
  cursor: pointer;
  margin: 4px 8px;
}
.product-request-more:hover {
  background: rgb(205, 9, 48);
}
.product-page-details .product-purchase .product-buy {
  width: 50%;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding-right: 5px;
}
.product-page-details .product-purchase .product-buy .purchase-text p {
  color: red;
  text-transform: none;
  font-size: 0.7rem;
  margin: 0;
  padding: 7px 0;
}
.product-page-details .product-purchase .buy-now {
  background: var(--color-blue);
  color: #eee;
  width: 220px;
  height: 46px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 6px 45px 6px 5px;
  margin: 0 10px;
  cursor: pointer;
  /* box-shadow: 1px 1px 8px 0px rgb(100, 100, 100); */
}
.product-page-details .product-purchase .buy-now svg {
  width: 38px;
  height: 38px;
}
.product-page-details .cart-btns .add-cart {
  background: #333;
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  padding: 5px;
}
.product-all-info {
  /* margin-top: 100px;
  background: white; */
  width: 100%;
  background: white;
}
.product-all-info .product-info-menu {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  height: auto;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding-top: 5vh;
  /* padding: 60px 0 0 0; */
  /* padding-left: 2rem; */
  margin-left: 0;
  padding-left: 20px;
  /* gap: 5px; */
  z-index: 99;
  position: sticky;
  top: 75px;
}

.product-all-info .product-info-menu li {
  text-align: center;
  text-transform: uppercase;
  /* font-weight: 600; */
  height: auto;
  width: auto;
  color: #252525;
  letter-spacing: 1px;
  padding-right: 5px;
  margin-right: 50px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
  position: relative;
  padding: 1vh 0;
}
.info-img-compatibility {
  width: 38px;
  height: 38px;
  margin-right: 5px;
  /* background: white; */
  border-radius: 50px;
  padding: 0 7px;
  /* border: 1px solid rgb(222, 216, 216); */
}
.info-img-insights {
  width: 36px;
  height: 36px;
  margin-right: 5px;
  /* background: white; */
  padding: 0 7px;
  /* border: 1px solid rgb(222, 216, 216); */
}
.info-img {
  width: 38px;
  height: 38px;
  margin-right: 15px;
  /* background: white; */
  border-radius: 50px;
  padding: 5px;
  /* border: 1px solid rgb(222, 216, 216); */
}

.product-all-info .product-info-menu li.active {
  color: #f9f6f6;
  background-color: #003765;
  border-radius: 8px;
  /* text-decoration: underline; */
}

.product-all-info .info-container {
  opacity: 0;
  background: #fff;
  width: 100%;
  height: 0px;
  /* overflow: hidden; */
  transition: 0.5s;
}
.product-all-info .info-container.active {
  opacity: 1;
  /* margin-top: 5px; */
  /* border: 1px solid #eee; */
  padding: 20px;
  height: auto;
}
.product-all-info .info-container p {
  /* color: #333; */
  font-size: 0.9rem;
}

.overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

/*TYPE SELECT MOBILE VIEW*/
.mobile-select-tabs-container {
  display: none;
}
.mobile-size-select-tabs-container {
  display: none;
}
.mobile-selected-size-show-header-div {
  display: none;
}

.mobile-show-size-option-div {
  display: none;
}

.mobile-color-select-tabs-container {
  display: none;
}
.mobile-selected-color-show-header-div {
  display: none;
}

.mobile-variant-select-tabs-container {
  display: none;
}
.mobile-selected-variant-show-header-div {
  display: none;
}

@media screen and (min-width: 501px) and (max-width: 1200px) {
  .product-slider {
    width: 45vw;
  }
  .product-details .product-page-img {
    width: 45vw;
  }
}
@media screen and (min-width: 501px) and (max-width: 1000px) {
  .product-page-img .big-images {
    width: 100%;
    height: 440px;
    overflow: hidden;
    position: relative;
  }

  .servicePromices-container {
    /* width: 100%; */
  }
  .servicePromices {
    height: 80px;
    width: 35vw !important;
  }

  .servicePromices-heading {
    font-size: 15px;
  }
  .servicePromices-desc {
    font-size: 10px;
  }

  .product-tech-li {
    font-size: 12px;
  }

  .product-page-details .product-container .product-fav-share {
    padding-left: 0;
  }
  .product-page-details .product-container .product-fav-share .favourite {
    font-size: 12px;
  }
  .product-page-details .product-container .product-fav-share .favourite img {
    width: 18px;
  }

  .product-page-details .product-container .product-fav-share .share {
    font-size: 12px;
    width: 35%;
  }

  .product-page-details .product-container .product-fav-share .share svg {
    font-size: 18px;
  }

  .product-page-details strong {
    font-size: 18px;
  }

  .product-container-detail-img {
    width: 80px;
  }

  .product-page-details .product-rating p {
    font-size: 12px;
  }

  .product-page-details
    .product-purchase
    .product-pricing-details
    .product-pricing-div {
    font-size: 13px;
  }

  .product-all-info .product-info-menu {
    padding: 0 5px;
    overflow-x: scroll;
  }

  .product-all-info .product-info-menu li {
    font-size: 14px;
    margin-right: 35px;
    white-space: nowrap;
  }
  .info-img-compatibility {
    margin-right: 0;
    width: 33px;
    height: 33px;
  }
  .info-img-insights {
    margin-right: 0;
    width: 32px;
    height: 32px;
  }
  .info-img {
    margin-right: 0;
    width: 33px;
    height: 33px;
  }
}
@media screen and (min-width: 501px) and (max-width: 900px) {
  .product-page-img .prev,
  .product-page-img .next {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
@media screen and (min-width: 501px) and (max-width: 800px) {
  .product-page-img .big-images {
    height: 200px;
  }
}
@media screen and (min-width: 801px) and (max-width: 1000px) {
  .product-page-img .big-images {
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .product-details {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0;
    gap: 0;
    margin: 0;
    margin-top: 15px;
  }
  .product-details .product-page-img {
    width: 100%;
    position: relative;
    top: 0;
    height: auto;
  }

  .product-page-img .big-images {
    height: 300px;
    /* padding: 1rem; */
  }

  .product-page-img .prev,
  .product-page-img .next {
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.1rem;
    z-index: 9;
  }

  .product-page-img .slider-img {
    width: 100%;
    overflow-x: scroll;
    padding: 1vh 3vw;
  }

  .product-page-img .slider-img .slider-box {
    width: 22%;
    height: auto;
    object-fit: contain;
    overflow-x: scroll;
    margin-right: 10px;
    padding: 0 !important;
    border-radius: 5px;
  }

  .servicePromices-container {
    display: none;
    width: 90%;
    height: auto;
    margin-bottom: 30px;
  }
  .servicePromices {
    width: 90% !important;
    height: 70px;
  }
  .servicePromices img {
    width: 40px;
    height: 40px;
    margin-left: 3px;
  }
  .servicePromices-text {
    margin-left: 8px;
  }
  .servicePromices-heading {
    font-size: 1rem;
    margin-bottom: 3px;
  }
  .servicePromices-desc {
    font-size: 0.75rem;
  }

  /* Product-page-details */
  .product-page-details {
    width: 100%;
  }
  .product-page-details-scroll {
    height: auto;
    overflow: auto;
  }
  .product-slider {
    width: 100%;
    height: auto;
    overflow-y: auto;
    position: relative;
    top: 0;
  }
  .product-page-details .product-container {
    align-items: flex-start;
    padding: 0 20px;
  }
  .product-container-detail {
    margin-bottom: 5px;
    position: relative;
  }
  .product-tech-li {
    font-size: 0.95rem;
  }

  .product-page-details .product-container .product-fav-share {
    position: relative;
    top: 30px;
    width: 60%;
    right: 10px;
    /* padding: 0; */
  }
  .product-page-details .product-container .product-fav-share .favourite {
    font-size: 0.9rem;
  }
  .product-page-details .product-container .product-fav-share .favourite svg {
    font-size: 1.8rem;
  }
  .product-page-details .product-container .product-fav-share .favourite span {
    display: none;
  }
  .product-page-details .product-container .product-fav-share .share {
    font-size: 0.9rem;
  }
  .product-page-details .product-container .product-fav-share .share span {
    display: none;
  }
  .product-page-details .product-container .product-fav-share .share svg {
    font-size: 2rem;
  }

  .product-page-details strong {
    font-size: 1.1rem;
    margin-left: 4px;
  }
  .product-container-detail {
    position: relative;
  }

  .product-page-details .product-category {
    font-size: 1.2rem;
    margin: 5px 0 0 0;
  }
  .product-page-details .product-rating {
    display: none;
  }

  .product-page-details .mobile-product-rating {
    position: absolute;
    right: -45%;
    bottom: 0;
    display: flex;
    align-items: center;
    background: var(--color-green);
    border-radius: 4px;
    color: white;
    height: 22px;
    padding: 2px;
  }
  .product-page-details .mobile-product-rating p {
    font-size: 0.85rem;
    font-weight: 600;
    margin-left: 5px;
    margin-top: 16px;
  }
  .product-page-details .mobile-product-rating svg {
    font-size: 1.1rem;
    margin-left: 1px;
  }
  .product-page-details .product-price-detail {
    width: 80%;
    margin-bottom: 0;
    margin-top: 10px;
    padding: 0 20px;
  }

  .product-price {
    font-size: 1.8rem;
  }
  .product-price-del {
    font-size: 0.8rem;
  }

  .product-select-tabs-container {
    display: none;
  }

  .product-page-details .product-size-variant-color {
    display: none;
    width: 100%;
    overflow-x: scroll;
  }

  .product-page-details .product-varient-container {
    margin-right: 8px;
    height: 120px;
    width: 100px;
  }
  .product-page-details .product-varient-container img {
    height: 80px;
  }

  .product-page-details .product-purchase {
    min-height: auto;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-radius: 0;
  }
  .product-page-details .product-purchase .product-pricing-details {
    width: 100%;
    font-size: 0.8rem;
  }
  .product-page-details
    .product-purchase
    .product-pricing-details
    .product-pricing-div {
    width: 100%;
    font-size: 0.88rem;
  }
  .shipping-mode-mobile {
    display: none !important;
  }

  .product-page-details
    .product-purchase
    .product-pricing-details
    .product-pricing-div
    svg {
    right: 18px;
  }
  .info-shippingInfo-container {
    display: none;
  }

  .User-login-page-product-page-container {
    width: 100%;
  }

  .product-shipping-div {
    left: 15px;
  }

  .product-page-details .product-purchase .product-carts {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    background: transparent;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .product-page-details
    .product-purchase
    .product-carts
    .product-carts-counter-div {
    align-items: center;
    width: 75%;
    background: transparent;
  }
  .product-page-details
    .product-purchase
    .product-carts
    .product-carts-counter-div
    p {
    width: 100%;
    margin-left: 0;
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }
  .product-counter-invertory {
    width: 100%;
  }
  .product-page-details
    .product-purchase
    .product-carts
    .product-carts-counter-div
    .product-counter-invertory
    .product-counter {
    width: 100%;
    margin-left: 5px;
  }

  .product-inStock {
    position: absolute;
    top: -10px;
    left: -20px;
  }

  .product-page-details .product-purchase .product-carts .add-cart {
    width: 48%;
    height: 60px;
    font-size: 0.85rem;
    margin: 0;
    padding: 6px 5px 6px 5px;
    border-radius: 10px;
    justify-content: center;
    padding: 0;
  }
  /* .product-page-details .product-purchase .product-carts .add-cart svg {
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    margin-right: 8px;
  } */
  .max-inventery-count-request {
    width: 100%;
    margin-top: 2px;
  }

  .product-page-details .product-purchase .product-buy {
    box-shadow: 0 -5px 8px 1px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
    background: white;
    flex-direction: row;
    padding: 0;
    left: 0;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    z-index: 99;
  }

  .product-page-details .product-purchase .buy-now {
    width: 48%;
    height: 60px;
    font-size: 0.85rem;
    margin: 0;
    padding: 6px 5px 6px 5px;
    border-radius: 10px;
    justify-content: center;
    padding: 0;
  }
  .product-page-details .product-purchase .buy-now svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  .product-page-details
    .product-purchase
    .product-carts
    .add-cart
    .add-to-cart-svg {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  .product-page-details .product-purchase .product-carts .add-cart .added-svg {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  .product-all-info {
    display: none;
  }
  .product-all-info .product-info-menu {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    transition: 0.7s all ease;
    padding: 0;
    margin: 20px;
    padding-top: 30px;
  }
  .product-all-info .product-info-menu li {
    transition: 0.7s all ease;
    width: 100%;
    margin: 5px auto;
  }

  .product-all-info .product-info-menu li.active {
    text-decoration: none;
    background: linear-gradient(#003765, #04508f);
    color: white;
  }

  /*TYPE SELECT MOBILE VIEW*/
  .mobile-select-tabs-container {
    margin: 40px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: black;
  }
  .mobile-size-select-tabs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12.5px 0;
    padding-bottom: 12.5px;
    margin-bottom: 12.5px;
    border-bottom: 2px solid var(--border-color);
  }
  .mobile-selected-size-show-header-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 8px;
    cursor: pointer;
  }
  .mobile-selected-size-name {
    font-size: 0.9rem;
  }
  .mobile-show-size-option-div {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fce9e9;
    padding: 0 20px;
  }
  .mobile-show-each-size {
    font-size: 0.9rem;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    padding: 5px 10px;
    margin: 0 5px;
  }
  .mobile-color-select-tabs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12.5px 0;
    padding-bottom: 12.5px;
    margin-bottom: 12.5px;
    border-bottom: 2px solid var(--border-color);
  }
  .mobile-selected-color-show-header-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 8px;
  }
  .mobile-selected-color-name {
    font-size: 0.9rem;
  }
  .mobile-show-color-option-div {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fce9e9;
    padding: 0 20px;
  }
  .mobile-show-each-color {
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }
  .mobile-show-each-color.active {
    width: 36px;
    height: 36px;
    border: 2px solid #ba3737;
    border-radius: 50px;
    position: relative;
  }
  .mobile-show-each-color button {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    overflow: hidden;
    margin-right: 10px;
    position: relative;
    left: 2.2px;
    top: 2px;
  }
  .mobile-show-each-color button.active {
    width: 29px;
    height: 29px;
    left: 2.2px;
    top: 2px;
  }

  .mobile-variant-select-tabs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12.5px 0;
    padding-bottom: 12.5px;
    margin-bottom: 12.5px;
    border-bottom: 2px solid var(--border-color);
  }
  .mobile-selected-variant-show-header-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 8px;
  }
  .mobile-selected-variant-name {
    font-size: 0.9rem;
  }

  .mobile-show-variant-option-div {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fce9e9;
    padding: 0 20px;
    overflow-x: scroll;
  }
  .mobile-show-each-variant {
    width: 70px;
    height: 90px;
    border: 2px solid var(--border-color);
    border-radius: 10px;
    margin: 0 5px;
  }
}
