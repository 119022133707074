.description-containers {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
  color: black;
  text-transform: none;
  margin: 0;
}
.description-combine {
  width: 65%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.description-container-desc-points {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 10px;
}
.description-container-desc-points ul {
  list-style-type: none;
  margin: 6px 0;
  margin-left: -1.5vw;
}
.description-container-desc-each-point {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.6px;
  padding: 0;
  font-weight: 500;
  line-height: 1.3;
}
.description-container-img-gallery {
  width: 35%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
}
.descriptions-each-image {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
.descriptions-each-image img {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
.description-container-features-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.features-heading {
  font-size: 18px;
  margin: 20px 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}
.description-container-features-div ul {
  list-style: disc;
  margin: 10px 0;
}
.description-container-features-div ul li {
  text-align: start;
  margin: 7px 0;
  font-size: 12.8px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .description-containers {
    padding: 0 10px;
  }

  .features-heading {
    margin: 10px 0;
  }
}
@media screen and (min-width: 501px) and (max-width: 900px) {
  .description-container-desc-each-point {
    font-size: 12px;
  }
  .description-container-features-div ul li {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .description-containers {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 3vw;
    color: black;
    text-transform: none;
    margin: 0;
  }
  .description-combine {
    width: 100%;
  }
  .description-container-desc-points {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 5px 0;
    padding-left: 0;
  }
  .description-container-desc-points ul {
    list-style: square;
  }
  .description-container-desc-each-point {
    margin: 5px 0;
    font-size: 3.5vw;
    margin-left: -2vw;
  }
  .description-container-img-gallery {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-right: 2rem;
  }
  .descriptions-each-image {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .descriptions-each-image img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .description-container-features-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .features-heading {
    font-size: 4vw;
    margin: 1vh 0;
    margin-bottom: 5px;
  }
  .description-container-features-div ul {
    list-style: square;
    margin: 1vh 0;
  }
  .description-container-features-div ul li {
    text-align: start;
    margin: 0.5vh 0;
    font-size: 3.2vw;
    margin-left: -2vw;
  }
}
