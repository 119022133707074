.business-owner-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2vw;
}
.business-owner-heading {
  font-size: 1.2vw;
  color: black;
  font-weight: 600;
  margin-bottom: 1.5vh;
}
.business-owner-desc {
  font-size: 0.8vw;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-bottom: 1.5vh;
}

.receive-update-whatsapp-span a {
  color: var(--color-purple);
  text-decoration: underline;
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .business-owner-container {
    padding: 15px;
  }
  .business-owner-heading {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .business-owner-desc {
    font-size: 13px;
    margin-bottom: 15px;
  }
}
