.proride-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 10px;
}

.proride-container h1 {
  text-align: center;
  color: #333;
}

.proride-brand-info p {
  margin-bottom: 15px;
  font-size: 15px;
}

.proride-core-values {
  margin-bottom: 15px;
}

.proride-core-values h2 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  margin: 0;
}

.proride-core-values ul {
  list-style: disc;
}

.proride-core-values li {
  color: #777;
  font-size: 13.8px;
  line-height: 1.3;
}

.proride-core-values li strong {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}
